// React 
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';


// Styles
import classes from "./Pages_styles/PriceList.module.scss"
import clientsClasses from "./Pages_styles/Clients.module.scss"

// Wrappers
import { DashboardColorPicker, DataBox, MySelect, TitleSectionIconWrapper, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../components/Elements/Wrappers';
import { ButtonNeutralToDanger, ButtonPrimaryFill } from '../../../components/Elements/Buttons';
import { CurrencyInput, SearchInput, SingleInput, TextFieldInput } from '../../../components/Elements/FormInputs';
import { ButtonSave } from '../../../components/Elements/FormButtons';
import { formatDate } from '../../../helper/dateFunctions';
import { TwoActionButtonsDangerWrapper } from '../../../components/Elements/Wrappers'
import { DangerousWarrningContainer, ErrorContainer, SuccessContainer } from '../../../components/Elements/FormMessageBoxes'
import { LabelLeftIconTwoBoldsTexts, LabelLeftSingleIcon } from '../../../components/Elements/FormLabels';


// Apis 
import { API_CreatePriceList, API_DeletePriceList, API_ToggleFav, API_UpdatePriceList } from '../../../api/API_price_lists';

// Context
import { useDashboardContext } from '../../../context/DashboardContext';
import { useUserContext } from '../../../context/UserAuth';
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext';


// Icons 
import { ReactComponent as ClearInputIcon } from "../../../static/n_icons/ClearInput.svg"
import { ReactComponent as DeleteIcon } from "../../../static/n_icons/Delete.svg"
import { ReactComponent as StarIcon } from "../../../static/n_icons/Heart.svg"
import { ReactComponent as TagIcon } from "../../../static/n_icons/Tag.svg"
import { ReactComponent as EditIcon } from "../../../static/n_icons/Pen.svg"
import { ReactComponent as VisitAmountIcon } from "../../../static/n_icons/VisitAmount.svg"
import { ReactComponent as DBErrorIcon } from "../../../static/n_icons/Neutral.svg"
import { ReactComponent as ArrowDown } from "../../../static/n_icons/Arrow_down_filtr_2.svg"
import { ReactComponent as LockIcon } from "../../../static/n_icons/Lock.svg"
import { ReactComponent as NotesIcon } from "../../../static/n_icons/Settings_Description.svg"

// Helpers
import visit_colors from "../../../data/visit_colors.json";
import { getVisitTimeIntervals } from '../../../helper/_calendar';
import { getObjectFromLocalStorage, setObjectInLocalStorage } from '../../../helper/local_storage';
import { getContrastColor } from '../../../helper/funcs';
import { getSearchCategories_priceList } from '../../../helper/_pricelist';
import { isDashboardNameValid } from '../../../utils/data_validators/dashboard_create_new';



// Layouts
import PopupLayout from '../Layout/PopupLayout'






function PriceList() {

    const { t } = useTranslation();
    const token = localStorage.getItem("access_token")

    const categories = getSearchCategories_priceList(t);

    const [searchParams, setSearchParams] = useSearchParams();

    // This helps to match priceList element property with current search by 
    const propertyMap = {
        search_by_name: 'name',
        search_by_tag: 'tag',
        search_by_length: 'length',
        search_by_price: 'price',
        search_by_description: 'description',
    };

    const [search, setSearch] = useState(searchParams.get('query') || '');

    const [category, setCategory] = useState(searchParams.get('category') || 'search_by_name')

    const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || 'name');
    const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 'asc');


    const { sharedDashboardPriceLists, setSharedDashboardPriceLists, priceListById } = useSharedDashboardContext()

    const [showAddEditPriceListPopup, setShowAddEditPriceListPopup] = useState(false)
    const [addEditPopupType, setAddEditPopupType] = useState("Add")
    const [priceListToEdit, setPriceListToEdit] = useState({})


    function setSortByAndUpdateOrder(sortKey) {

        if (sortKey === sortBy) {
            // If clicking on the same column, toggle sortOrder
            const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
            setSortOrder(newSortOrder);
            setSearchParams({ category: category, query: search, sortBy: sortKey, sortOrder: newSortOrder }, { replace: true });
        } else {
            // If clicking on a different column, set sortOrder to "asc"
            setSortOrder("asc");
            setSearchParams({ category: category, query: search, sortBy: sortKey, sortOrder: "asc" }, { replace: true });
        }

        setSortBy(sortKey);
    }

    const handleCategoryChange = (value) => {
        setCategory(value)
        setSearchParams({ ...searchParams, query: search, category: value }, { replace: true });
    }

    function handleClearSearch() {
        setSearch("")
        setSearchParams({ category: "search_by_name", sortBy: "name", sortOrder: "asc" }, { replace: true });
    }

    function handleOpenPopup(type) {
        setAddEditPopupType(type)
        setShowAddEditPriceListPopup(true)
    }

    const getCategoryLabel = (categoryValue) => {
        const category = categories.find((cat) => cat.value === categoryValue);
        return category ? category.label : "";
    };

    const sortPriceLists = () => {
        const compareFunction = (a, b) => {
            switch (sortBy) {
                case "name":
                    return a.name.localeCompare(b.name);
                case "tag":
                    return a.tag.localeCompare(b.tag);
                case "length":
                    return (a.length || 0) - (b.length || 0);
                case "price":
                    return (parseFloat(a.price) || 0) - (parseFloat(b.price) || 0);
                case "description":
                    return a.description.localeCompare(b.description);
                case "date":
                    return new Date(a.creation_date) - new Date(b.creation_date);
                default:
                    // Default sorting (by name) when sortBy is not recognized
                    return a.name.localeCompare(b.name);
            }
        };

        // Apply sortOrder logic
        return sortOrder === "asc"
            ? compareFunction
            : (a, b) => compareFunction(b, a); // Reverse the order for "desc"
    };

    // Function to filter price lists based on the selected category
    const filterPriceLists = () => {
        const sortingFunction = sortPriceLists();

        if (search === "") {
            return sharedDashboardPriceLists.sort(sortingFunction);
        }

        const filteredList = sharedDashboardPriceLists.filter(priceList => {
            const propertyName = propertyMap[category];
            const categoryValue = priceList[propertyName];

            if (propertyName === 'length') {
                return categoryValue !== undefined && categoryValue !== null && categoryValue.toString().includes(search);
            } else if (propertyName === 'price') {
                return categoryValue !== undefined && categoryValue !== null && categoryValue.toString().includes(search);
            } else {
                return categoryValue !== undefined && categoryValue !== null && categoryValue.toLowerCase().includes(search.toLowerCase());
            }
        });

        return filteredList.sort(sortingFunction);
    };

    const filteredPriceLists = filterPriceLists();

    return (
        <div className='container is-widescreen'>

            {(showAddEditPriceListPopup) && <AddEditPriceList priceListToEdit={priceListToEdit} setPriceListToEdit={setPriceListToEdit} type={addEditPopupType} token={token} onClose={() => setShowAddEditPriceListPopup(false)}></AddEditPriceList>}



            {/* Section title */}
            <WrapperFlexRow className={"WrapperTitle"}>

                <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                    <h2 className="SectionTitleText"> {t("Sidebar.price_list", "Price list")} </h2>
                    <p className="DefaultText text_gray_on_layout"> {t("PriceList.manage_pricelist", "Manage your company's services")} </p>

                </WrapperFlexCol>

                <ButtonPrimaryFill onClick={() => { handleOpenPopup("Add") }} text={t("PriceList.add_service", "Add a service")} />

            </WrapperFlexRow>




            <WrapperFlexRow className={"FlexRowToColumnOnBigTablet"} style={{ padding: "0", alignItems: "flex-start", gap: "2rem", flexWrap: "wrap" }}>

                <WrapperFlexCol style={{ padding: "0", flex: "4" }}>


                    {/* Serach Input and category  */}
                    <WrapperFlexRow className={clientsClasses.SearchBarStickyTop} style={{ justifyContent: "flex-end", padding: "1rem 0" }}>

                        <MySelect
                            className='SelectBox CategorySelect'
                            name={category}
                            value={category}
                            onChange={(e) => { handleCategoryChange(e.target.value); }}
                            options={categories}
                            disabled={false}
                        />

                        {/* Search Input  */}
                        <SearchInput style={{ minWidth: "unset", width: "100%", maxWidth: "unset" }} value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder={t("PriceList.search_service_placeholder", 'Search service..')} />

                        {/* Clear Input  */}
                        <ButtonNeutralToDanger className={"default_input_height withBorder"} stroke_fill={false} text={<ClearInputIcon />} onClick={handleClearSearch} />


                    </WrapperFlexRow>



                    {(filteredPriceLists.length > 0 && sharedDashboardPriceLists.length > 0) ?


                        // Display data properly 
                        <DataBox className={"onLayout"} style={{ overflowX: "auto", padding: "1rem" }}>
                            <RenderPriceListsTable priceListById={priceListById} sortOrder={sortOrder} setSortByAndUpdateOrder={setSortByAndUpdateOrder} sortBy={sortBy} setSortBy={setSortBy} sharedDashboardPriceLists={filteredPriceLists} setSharedDashboardPriceLists={setSharedDashboardPriceLists} setPriceListToEdit={setPriceListToEdit} setShowAddEditPriceListPopup={setShowAddEditPriceListPopup} setAddEditPopupType={setAddEditPopupType} />
                        </DataBox>


                        :


                        // Display info box when we didnt find anything by search / category 
                        (filteredPriceLists.length === 0 && sharedDashboardPriceLists.length > 0)
                            ?
                            <WrapperFlexCol className={'DashboardErrorContainer'} style={{ alignItems: "center", height: "100%" }}>
                                <TitleSectionIconWrapper stroke_fill={false} icon={<DBErrorIcon />} />

                                <p style={{ textAlign: "center" }} className='DefaultText text-gray'>
                                    {t("PriceList.not_found", "Oops, we could not find anything with that cryteria.")}
                                    <br /><br />
                                    {t("PriceList.category_label", "Category")}: <b>{getCategoryLabel(category).toLowerCase()}</b>
                                    <br />
                                    {t("PriceList.search_label", "Search")}: <b>&apos;{search}&apos;</b>
                                    <br /><br />
                                    <span className='SmallText text-gray'>{t("PriceList.try_different_category", "Maybe you could try a different category or check for any typos")}</span>
                                </p>


                                <button onClick={() => {
                                    setSearch("")
                                    setCategory("search_by_name")
                                }} className="ButtonPrimaryFill"> {t("PriceList.clear_search", "Reset search")} </button>
                            </WrapperFlexCol>
                            :
                            // Display info when no price list exists
                            <WrapperFlexCol className={'DashboardErrorContainer'} style={{ alignItems: "center", height: "100%" }}>
                                <TitleSectionIconWrapper stroke_fill={false} icon={<DBErrorIcon />} />
                                <p style={{ textAlign: "center" }} className='DefaultText text-gray '> {t("PriceList.no_service_added", "Your dashboard does not have any services added yet.")} </p>
                                <p style={{ textAlign: "center" }} className='SmallText text-gray'> {t("PriceList.no_service_add_one", "Add one and see how little time it will take you to add a visit with details!")} </p>
                                <button onClick={() => { handleOpenPopup("Add") }} className="ButtonPrimaryFill"> {t("PriceList.add_service", "Add a service")} </button>
                            </WrapperFlexCol>


                    }
                </WrapperFlexCol>




                <WrapperFlexCol className={classes.PriceListInfo} style={{ padding: "0", width: "unset", minWidth: "300px", flex: "1" }}>
                    <RenderPriceListInformation />
                </WrapperFlexCol>

            </WrapperFlexRow>

        </div>
    )
}

function RenderPriceListInformation() {

    const { t } = useTranslation();

    return (
        <DataBox className={"onLayout"}>
            <h1 className='DefaultText text-bold'>
                {t("PriceList.manual.page_description", "This page is designed to make your visit management a breeze. Here's what you can do:")}
            </h1>

            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem" }}>
                <h2 className='DefaultText text-bold'>
                    {t("PriceList.manual.add_your_services", "1. Add Your Services:")}
                </h2>
                <hr className='myHr' />
                <p className='SmallText'>
                    {t("PriceList.manual.service_list", "Create a list of your services, such as massage, haircut, or any offerings you provide.")} <br />
                    {t("PriceList.manual.service_details", "Specify service details like duration and pricing.")}
                </p>

            </WrapperFlexCol>

            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem" }}>
                <h2 className='DefaultText text-bold'>
                    {t("PriceList.manual.attach_services_title", "2. Attach Services to Visits:")}
                </h2>

                <hr className='myHr' />
                <p className='SmallText'>
                    {t("PriceList.manual.associate_services_description", "Associate your services directly with visits.")} <br />
                    {t("PriceList.manual.save_time_description", "Save time by avoiding repetitive service entry for each visit.")}
                </p>

            </WrapperFlexCol>

            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem" }}>
                <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <h2 className='DefaultText text-bold'>
                        {t("PriceList.manual.favorite_services_title", "3. Favorite Services:")}
                    </h2>
                    <div>
                        <StarIcon className={"svg_medium SVG_STROKE"} />
                    </div>
                </WrapperFlexRow>
                <hr className='myHr' />
                <p className='SmallText'>
                    {t("PriceList.manual.mark_favorite_description", "Mark any service as a favorite for quick access.")} <br />
                    {t("PriceList.manual.display_top_description", "Your favorite services will be displayed at the top when adding a visit.")}
                </p>

            </WrapperFlexCol>

            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem" }}>
                <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <h2 className='DefaultText text-bold'>
                        {t("PriceList.manual.lock_one_service_title", "4. Lock One Service (Optional):")}
                    </h2>

                    <div>
                        <LockIcon className={"svg_medium SVG_STROKE"} />
                    </div>
                </WrapperFlexRow>
                <hr className='myHr' />
                <p className='SmallText'>
                    {t("PriceList.manual.choose_service_description", "Choose one service to be pre-selected automatically when adding a visit.")}
                </p>

            </WrapperFlexCol>

            <WrapperFlexCol style={{ padding: "0", gap: "0.25rem" }}>
                <h2 className='DefaultText text-bold'>
                    {t("PriceList.manual.better_readability_title", "5. Better Readability and Management:")}
                </h2>
                <hr className='myHr' />
                <p className='SmallText'>
                    {t("PriceList.manual.clear_overview_description", "Enjoy a clear overview of visits with attached services.")} <br />
                    {t("PriceList.manual.manage_appointments_description", "Easily manage appointments with improved organization.")}
                </p>

            </WrapperFlexCol>

        </DataBox >
    )
}

function RenderPriceListsTable({ priceListById, sortOrder, setSortByAndUpdateOrder, sortBy, setSortBy, sharedDashboardPriceLists, setSharedDashboardPriceLists, setPriceListToEdit, setAddEditPopupType, setShowAddEditPriceListPopup }) {

    const { t } = useTranslation();

    const { currentUser } = useUserContext()
    const { dashboardCurrency } = useSharedDashboardContext()

    const token = localStorage.getItem("access_token")

    // This holds the current price list (default) in localStorage
    // so every user can have its own default price list
    // We delete default_price_list if we have a mismatch (if localStorage holds id: 100 and it is not found in our price list at all )
    const [defaultPriceList, setDefaultPriceList] = useState(() => {
        const storedDefaultPriceListId = getObjectFromLocalStorage('default_price_list');

        if (storedDefaultPriceListId && priceListById[parseInt(storedDefaultPriceListId.id)]) {
            return priceListById[parseInt(storedDefaultPriceListId.id)];
        } else {
            // Clear the stored default_price_list_id if it doesn't exist in priceListById
            localStorage.removeItem('default_price_list');
            return null;
        }
    });





    // We use this variable so we can know when toggling was done
    // When we click toggle, we lock others, so firstly we will wait for first toggle to finish
    // Then we will unlock all for further toggling
    const [isFavToggleDone, setIsFavToggleDone] = useState(true)

    const [showDeletePriceListPopup, setShowDeletePriceListPopup] = useState(false)
    // First time we use this instead of two useStates (for delete item and edit item)
    const [itemToModify, setItemToModify] = useState({})

    const thClasses = 'SmallerText text-bold text-gray text_no_wrap';

    // Add arrow depending of current sort to the th (header) element 
    function CurrentFilter({ sort }) {
        const isSortingDesc = sortOrder === 'desc';
        const rotationStyle = isSortingDesc ? { transform: 'rotate(180deg)' } : {};

        if (sort === sortBy) {
            return (
                <ArrowDown className={classes.sortArrow} style={rotationStyle} />
            );
        } else {
            return (
                <ArrowDown className={classes.sortArrow} style={{ visibility: "hidden" }} />
            );
        }
    }

    const columns = [
        { key: 'empty', label: '', translationKey: 'placeholder', className: thClasses },
        { key: 'number', label: t('PriceList.p_num', 'No.'), translationKey: 'PriceList.p_num', className: `${thClasses}` },
        { key: 'name', label: t('PriceList.p_name', 'Name'), translationKey: 'PriceList.p_name', className: `${thClasses} ${classes.canBeSorted}`, canBeSorted: true, sortKey: 'name' },
        { key: 'tag', label: t('PriceList.p_tag', 'Tag'), translationKey: 'PriceList.p_tag', className: `${thClasses} ${classes.canBeSorted}`, canBeSorted: true, sortKey: 'tag' },
        // Add other columns similarly
        { key: 'length', label: t('PriceList.p_length', 'Length'), translationKey: 'PriceList.p_length', className: `${thClasses} ${classes.canBeSorted}`, canBeSorted: true, sortKey: 'length' },
        { key: 'price', label: t('PriceList.p_price', 'Price'), translationKey: 'PriceList.p_price', className: `${thClasses} ${classes.canBeSorted}`, canBeSorted: true, sortKey: 'price' },
        { key: 'description', label: t('PriceList.p_description', 'Description'), translationKey: 'PriceList.p_description', className: `${thClasses} ${classes.canBeSorted}`, canBeSorted: true, sortKey: 'description' },
        { key: 'date', label: t('PriceList.p_creation', 'Creation date'), translationKey: 'PriceList.p_creation', className: `${thClasses} ${classes.canBeSorted}`, canBeSorted: true, sortKey: 'date' },
        { key: 'action', label: t('PriceList.action', 'Action'), translationKey: 'PriceList.action', className: `${thClasses}` }
    ];


    const TableHeader = ({ sortBy, columns }) => {
        const { t } = useTranslation();

        return (
            <thead>
                <tr>
                    {columns.map((column) => (
                        <th
                            style={{ userSelect: "none" }}
                            key={column.key}
                            className={`${column.className} ${column.canBeSorted ? 'canBeSorted' : ''} ${column.sortKey === sortBy ? classes.currentSortingHeader : ''}`}
                            onClick={() => column.canBeSorted && setSortByAndUpdateOrder(column.sortKey)} // Only run onClick if canBeSorted class is included
                        >
                            <div>
                                {t(column.translationKey, column.label)}
                                {column.sortKey && <CurrentFilter sort={column.sortKey} />}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
        );
    };

    return (
        <>

            {(showDeletePriceListPopup) && <DeletePriceList token={token} item={itemToModify} closePopup={() => setShowDeletePriceListPopup(false)} />}

            <table className={classes.PriceListTable}>
                <TableHeader sortBy={sortBy} setSortBy={setSortBy} columns={columns} />
                <tbody>
                    {(sharedDashboardPriceLists && Object.keys(sharedDashboardPriceLists).length > 0)
                        &&

                        sharedDashboardPriceLists
                            .filter((item) => item.id !== -1)
                            .map((item, idx) => (
                                <SinglePriceList
                                    dashboardCurrency={dashboardCurrency}
                                    setDefaultPriceList={setDefaultPriceList}
                                    defaultPriceList={defaultPriceList}
                                    setPriceListToEdit={setPriceListToEdit}
                                    setShowAddEditPriceListPopup={setShowAddEditPriceListPopup}
                                    setAddEditPopupType={setAddEditPopupType}
                                    setShowDeletePriceListPopup={setShowDeletePriceListPopup}
                                    setItemToModify={setItemToModify}
                                    isFavToggleDone={isFavToggleDone}
                                    setIsFavToggleDone={setIsFavToggleDone}
                                    setSharedDashboardPriceLists={setSharedDashboardPriceLists}
                                    currentUser={currentUser}
                                    token={token}
                                    idx={idx + 1}
                                    item={item}
                                    key={`price_list-${idx}`}
                                />
                            ))
                    }

                </tbody>
            </table>

        </>
    )
}

function SinglePriceList({ dashboardCurrency, setDefaultPriceList, defaultPriceList, setPriceListToEdit, setShowAddEditPriceListPopup, setAddEditPopupType, item, idx, token, currentUser, setSharedDashboardPriceLists, isFavToggleDone, setIsFavToggleDone, setShowDeletePriceListPopup, setItemToModify }) {

    const { t } = useTranslation();


    async function toggleHeart(single_item) {

        if (token && currentUser && currentUser.id && isFavToggleDone) {
            setIsFavToggleDone(false)

            const response = await API_ToggleFav(token, single_item.id)

            if (response.isOk) {
                // Toggle success
                let updated_item = { ...single_item }
                updated_item.isFavourite = !single_item.isFavourite

                setSharedDashboardPriceLists(prevPriceLists =>
                    prevPriceLists.map(item =>
                        item.id === updated_item.id ? updated_item : item
                    )
                );
            }
            else {
                // Toggle unsuccessful 
                console.log(response.error_message)

            }

            setIsFavToggleDone(true)
        }

    }

    function handleDeleteItem(single_item) {
        setItemToModify({ ...single_item })
        setShowDeletePriceListPopup(true)
    }

    // Check if data exists and if so we return it or '-'
    function item_data_exists(item, prop) {
        if (item && item[prop]) return item[prop]
        return "-"
    }

    // Set default price list to localstorage
    // if it is the same, then we remove it from local because we allow user to not select any default price list 
    function setPriceListAsDefault(item) {

        // Check if defaultPriceList exists and has the same id as the new item
        if (defaultPriceList && defaultPriceList.id === item.id) {
            // If the ids match, remove the item from localStorage and set defaultPriceList to null
            localStorage.removeItem('default_price_list');
            setDefaultPriceList(null);
        } else {

            console.log("Setting def: ", item);
            // If the ids don't match, set the new item as the defaultPriceList
            const isDefaultPriceAdded = setObjectInLocalStorage('default_price_list', item);

            if (isDefaultPriceAdded) setDefaultPriceList(item);
        }
    }


    let description_sliced = item_data_exists(item, 'description')
    description_sliced = (description_sliced.length > 128) ? description_sliced.slice(0, 100) + ".." : description_sliced

    const tdClasses = 'SmallText';

    return (
        <tr>
            <td className={`${tdClasses}`} style={{ textAlign: "center", padding: "0.5rem" }}>
                <StarIcon onClick={() => toggleHeart(item)} style={{ padding: "0.15rem" }} className={`StarIcon ${isFavToggleDone ? 'StarIconHover' : ''} ${(item.isFavourite ? "StarIconFilled" : "")}`} />
            </td>
            <td className={`${tdClasses} text-bold`}> {idx}. </td>
            <td className={`${tdClasses} text_light_bold`}> {item_data_exists(item, 'name')} </td>
            <td style={{ textAlign: "center" }}>
                <PriceListTag bg={item.color} text={item.tag} />
            </td>
            {/* <td className={`${tdClasses} text_no_wrap`}> {item_data_exists(item, 'color')} </td> */}
            <td className={`${tdClasses} `}> {item_data_exists(item, 'length')} {t("PriceList.p_min", "min")}</td>
            <td className={`${tdClasses} text_no_wrap`}> {item_data_exists(item, 'price')} {(dashboardCurrency.Symbol) || ""} </td>
            <td className={`${tdClasses}`} style={{ minWidth: "150px" }}> {description_sliced} </td>
            <td className={`${tdClasses} text-gray`}> {formatDate(item_data_exists(item, 'creation_date'))} </td>


            <td className={`${tdClasses} `}>
                <WrapperFlexRow style={{ padding: "0", gap: "0.25rem" }}>
                    <ButtonSave style={{ border: 0 }} label={<EditIcon className={"SVG_STROKE"} />} onClick={() => {
                        setAddEditPopupType("Edit")
                        setShowAddEditPriceListPopup(true)
                        setPriceListToEdit({ ...item })
                    }} />

                    <ButtonNeutralToDanger style={{ border: 0 }} text={<DeleteIcon />} onClick={() => handleDeleteItem(item)} />

                    <ButtonSave className={`${classes.LockIcon} ${(defaultPriceList) && parseInt(defaultPriceList.id) === parseInt(item.id) ? classes.IconLocked : ""}`} style={{ border: 0 }} label={<LockIcon />} onClick={() => {
                        setPriceListAsDefault(item)
                    }} />
                </WrapperFlexRow>
            </td>

        </tr>
    )
}

// We want to separate this because we will use it in other parts of website later (like in calendar or client visits) 
export function PriceListTag({ bg, text, size = "" }) {

    // bg - background color in hex 
    // text - tag text 

    // size can be:
    // small - used in calendar month / day


    const textColor = getContrastColor(bg);

    return (
        <p className={`text_no_wrap MicroText text-bold ${classes.tag} ${(size === "small") ? classes.tag_small : ""}`} style={{ backgroundColor: bg, color: textColor }}>
            {text}
        </p>
    );
}

function AddEditPriceList({ onClose, token, type = "Add", priceListToEdit }) {

    // Type can either be "Add" or "Edit" and based on that, we will decide if popup will edit or add values 

    const { t } = useTranslation();
    const { setSharedDashboardPriceLists } = useSharedDashboardContext()
    const { currentDashboard } = useDashboardContext()

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    const timeIntervals = getVisitTimeIntervals(t)
    timeIntervals.unshift({ value: null, label: "-" })

    const [priceList, setPriceList] = useState({
        color: (type === "Add") ? visit_colors[0].color : priceListToEdit.color,
        length: (type === "Add") ? null : priceListToEdit.length,
        price: (type === "Add") ? null : priceListToEdit.price,
        description: (type === "Add") ? "" : priceListToEdit.description,
        name: (type === "Add") ? "" : priceListToEdit.name,
        tag: (type === "Add") ? "" : priceListToEdit.tag,
        dashboard: (currentDashboard && currentDashboard.id) ? currentDashboard.id : -1
    })

    function isTagValid(tag) {
        // Regular expression to match tags with 1 to 16 characters
        const tagRegex = /^[a-zA-Z0-9_ ]{1,16}$/;

        // Test if the tag matches the regular expression
        if (!tagRegex.test(tag)) {
            return [t("PriceList.tag_validation", "Tag can only consist of alphanumeric characters, spaces and underscores. It has to be at least 1 characters long (max 16).")]
        }

        return true;
    }

    async function priceListController(e) {
        e.preventDefault()

        setIsSuccess(false);

        let errors = []

        // Yes, we are using validation from dashboard because rules are the same 
        const nameValidation = isDashboardNameValid(priceList.name, t)
        const tagValidation = isTagValid(priceList.tag, t)


        if (nameValidation.length > 0) errors = errors.concat(nameValidation)
        if (tagValidation.length > 0) errors = errors.concat(tagValidation)


        if (token && currentDashboard && errors.length === 0) {
            let response;

            if (type === "Edit") response = await API_UpdatePriceList(token, priceList, priceListToEdit.id)
            else response = await API_CreatePriceList(token, priceList)


            if (response && response.isOk === false && response.error_message) {
                errors.push(response.error_message)

                if (response.response_json && response.response_json.description) {
                    errors.push(t("validationErrors.invalid_description", "Description can only contain numbers, letters and symbols !@#$%&") + " (cv_cd01)")
                }
                if (response.response_json && response.response_json.detail.includes("Limit_Error")) {
                    errors.push(t("validationErrors.limit_error.for_services", "Another service cannot be added because the limit of services has been reached. Go to Settings to check your dashboard limits.") + " (Limit_Error)")
                }
            }
            else if (response && response.isOk) {


                if (type === "Edit") {

                    // Change price list element after proper update
                    setSharedDashboardPriceLists(prevLists => {
                        // Find the index of the priceList to be updated
                        const indexToUpdate = prevLists.findIndex(item => item.id === priceListToEdit.id);

                        if (indexToUpdate !== -1) {
                            // Create a new array with the updated item at the found index
                            const updatedLists = [...prevLists];
                            updatedLists[indexToUpdate] = response.updated_item

                            return updatedLists;
                        }

                        return prevLists; // If not found, return the unchanged array
                    });
                }
                else {
                    let newPriceList = response.new_item;

                    setSharedDashboardPriceLists(prevLists => [...prevLists, newPriceList]);
                    resetPriceListState()
                }


                setIsSuccess(true)



                // Remove Success box after 2 seconds 
                setTimeout(() => {
                    setIsSuccess(false);
                }, 2000); // 2 sec
            }
            else if (response && response.isOk === false && response.other_errors) {
                console.log("\n[@PriceList > priceListController] Found some server side errors!")
                console.log(response.other_errors)

                errors.push(...response.other_errors)
            }
        }
        else {
            console.log("\n[@PriceList > priceListController] Something is invalid:\n")
            console.log("priceList: ", priceList, "\n")
        }

        setErrors(errors)
    }

    function resetPriceListState() {
        setPriceList({
            color: visit_colors[0].color,
            length: null,
            price: null,
            description: "",
            name: "",
            tag: "",
            dashboard: (currentDashboard && currentDashboard.id) ? currentDashboard.id : -1
        })
    }

    function handleClose() {
        onClose()
    }

    function handlePriceChange(newPrice) {

        if (parseFloat(newPrice) > 10000) {
            setPriceList({ ...priceList, price: 10000 })
        }
        else {
            setPriceList({ ...priceList, price: newPrice })
        }
    }

    return (
        <PopupLayout closePopup={handleClose} popupTitle={(type === "Edit") ? t("PriceList.edit_service", "Edit service") : t("PriceList.add_service", "Add service")}>
            <WrapperFlexCol as_form={true} style={{ gap: "0.25rem", padding: "0" }} on_submit={() => { priceListController() }} >

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={(type === "Edit") ? t("PriceList.price_list_updated", "Service updated") : t("PriceList.price_list_added", "New service added") + "!"} />
                }


                {/* Select color  */}
                <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", alignItems: "center", justifyContent: "space-between" }}>
                    {/* <LabelLeftSingleIcon style={{ marginTop: "0", flex: "1" }} LeftIcon={ColorBucketIcon} LabelText={(type === 'Add') ? t("Common.select", "Select visit") : t("Common.edit", "Edit visit")} LabelBold={t("Calendar.label.visit_color", "color")} /> */}
                    <DashboardColorPicker
                        colors={visit_colors}
                        selectedColor={priceList.color}
                        onColorChange={(color) => setPriceList({ ...priceList, color: color })}
                    />

                    {(priceList.tag.length > 0) && <PriceListTag bg={priceList.color} text={priceList.tag} />}

                </WrapperFlexRow>

                <LabelLeftIconTwoBoldsTexts LeftIcon={TagIcon} LabelText={(type === "Edit") ? t("Common.edit", "Edit") : t("Common.enter", "Enter")} LabelBold1={t("PriceList.p_namee", "name")} LabelBold2={t("PriceList.p_tag_placeholder", "tag")} LabelAfterBold={t("Common.servicey", "")} />
                <WrapperFlexRow style={{ padding: "0", gap: "0.5rem" }}>
                    <SingleInput
                        style={{ flex: "2" }}
                        value={priceList.name}
                        onChange={(e) => setPriceList({ ...priceList, name: e.target.value })}
                        label="service_name"
                        placeholder={t("PriceList.p_name", "Name")}
                    />

                    <SingleInput
                        style={{ flex: "1" }}
                        value={priceList.tag}
                        onChange={(e) => setPriceList({ ...priceList, tag: e.target.value })}
                        label="tag"
                        placeholder={t("PriceList.p_tag", "Tag")}
                        maxLength={"16"}
                    />
                </WrapperFlexRow>

                {/* Select visit length and price */}
                <LabelLeftIconTwoBoldsTexts LeftIcon={VisitAmountIcon} LabelText={(type === "Edit") ? t("Common.edit", "Edit") : t("Common.select", "Select")} LabelBold1={t("Calendar.label.visit_length", "visit length")} LabelBold2={t("Calendar.label.visit_price", "price")} LabelAfterBold={t("Common.servicey", "")} />
                <WrapperFlexRow style={{ padding: "0" }}>
                    <MySelect
                        className="SelectBox"
                        name="visit-length"
                        value={(priceList.length) ? priceList.length : ""}
                        onChange={(e) => setPriceList({ ...priceList, length: parseInt(e.target.value) })}
                        options={timeIntervals}
                        style={{ flex: "1" }}
                    />

                    <CurrencyInput
                        className={``}
                        value={(priceList.price) ? priceList.price : ""}
                        onChange={(e) => handlePriceChange(e.target.value)}
                        label={t("Calendar.label_visit_price", "Price")}
                        style={{ flex: "1", maxWidth: "max-content" }}
                    />
                </WrapperFlexRow>


                <LabelLeftSingleIcon LeftIcon={NotesIcon} LabelText={(type === "Edit") ? t("Common.edit", "Edit service") : t("Common.add", "Add service")} LabelBold={t("Calendar.label_visit_description", "description")} LabelAfterBold={t("Common.servicey", "")} />
                <TextFieldInput
                    label={t("PriceList.service_description", "Description")}
                    rows={4}
                    value={priceList.description}
                    onChange={(e) => setPriceList({ ...priceList, description: e.target.value })}
                />



                <TwoActionButtonsWrapper
                    onCancel={handleClose}
                    onSave={(e) => priceListController(e)}
                    isSaveDisabled={false}
                    cancelLabel={t("Common.cancel", "Cancel")}
                    saveLabel={(type === "Edit") ? t("Common.button.saveChanges", "Save changes") : t("Common.add", "Add")}
                />

            </WrapperFlexCol>
        </PopupLayout>
    )
}

function DeletePriceList({ closePopup, token, item }) {

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false);

    const { t } = useTranslation();
    const { currentUser } = useUserContext()
    const { setSharedDashboardPriceLists } = useSharedDashboardContext()


    // When this is triggered, that means user wants to delete price list
    async function handleConfirmation() {
        let errors = [];
        if (token && currentUser && currentUser.id) {
            const response = await API_DeletePriceList(token, item.id);
            console.log(response);
            if (response && response.isOk) {
                // Price list deleted successfully 
                setIsSuccess(true);

                setSharedDashboardPriceLists((prevPriceLists) =>
                    prevPriceLists.filter((priceList) => priceList.id !== item.id)
                );
            } else if (response && response.isOk === false && response.error_message) {
                // Error while deleting price list
                errors.push(response.error_message);
            }
        }

        if (!isSuccess) {
            setErrors(errors);
        }
    }

    return (
        <PopupLayout closePopup={closePopup} popupTitle={t("PriceList.delete_price_list", "Delete service")}>
            <WrapperFlexCol style={{ padding: "0" }} as_form={true} on_submit={handleConfirmation}>

                {(isSuccess === false && errors.length === 0) &&
                    <DangerousWarrningContainer message={t("PriceList.irreversible_process_message", "This process is irreversible, all data connected to that price list will be deleted! Visits that were using this label will stay, but they wont be connected to this no more")} />
                }

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("PriceList.price_list_deleted_success", "Service deleted successfully!")} />
                }

                {(!isSuccess) &&
                    <p className="DefaultText">
                        {t("PriceList.confirm_deletion", "Are you sure you want to delete service:")} <b>{item.name} </b>
                    </p>
                }

                {/* Actions like save and cancel  */}
                <TwoActionButtonsDangerWrapper
                    onCancel={closePopup}
                    onSave={handleConfirmation}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete", "Delete")}
                    isSuccess={isSuccess}
                />


            </WrapperFlexCol>
        </PopupLayout>
    )
}

export default PriceList