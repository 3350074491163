import React from 'react'
import { ReactComponent as IconDanger } from "../../static/icons/Danger.svg"
import { ReactComponent as IconSuccess } from "../../static/icons/Success.svg"
import { WrapperFlexRow } from './Wrappers'
import { useTranslation } from 'react-i18next';

export function DangerousWarrningContainer({ style = {}, message = "" }) {

    const { t } = useTranslation();

    return (
        <div className="ErrorContainer" style={{ animation: "unset", style }}>
            <h2> {t("FormMessageBoxes.warrning", "Warrning")}! <IconDanger /> </h2>
            <p> {message} </p>
        </div>
    )
}

export function LoadingMessage({ className, message, boldText = "" }) {
    return (
        <WrapperFlexRow style={{ justifyContent: "space-between" }} className={`${className} NeutralContainer`}>
            <p>
                {message} <b>{boldText}</b>
            </p>
            <span className='loading_animation_circles'></span>
        </WrapperFlexRow>
    );
}

export function ErrorContainer({ style = {}, errors }) {

    const { t } = useTranslation();

    return (
        <div className="ErrorContainer" style={style}>
            <h2> {t("FormMessageBoxes.error", "Error")}! <IconDanger /> </h2>
            {(errors) && errors.map((error, idx) => {
                // Check if ele is an array and has at least one element
                const capitalizedFirstElement = error.charAt(0).toUpperCase() + error.slice(1);
                return <p key={`error-${idx}`}> {capitalizedFirstElement} </p>;

            })}
        </div>
    )
}

export function SuccessContainer({ style = {}, message = "" }) {

    const { t } = useTranslation();

    return (
        <div className="SuccessContainer" style={style}>
            <h2> {t("FormMessageBoxes.success", "Success")}! <IconSuccess /> </h2>
            <p> {message} </p>
        </div>
    )
}
