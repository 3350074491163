

// This is our source of truth 
// Only those permissions can exists

export const possiblePermissions = {
    "Manager": "Manager",
    "Employee": "Employee",
    "Guest": "Guest",
    "Owner": "Owner"
}


// Whenever u assing a permission it is nice to run this funciton 
// We will make sure that this permission exists and is valid 
export function isPermissionValid(permission) {

    if (possiblePermissions[permission]) return true

    return false

}