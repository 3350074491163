import React, { createContext, useContext, useMemo, useState } from 'react'

import { useDashboardContext } from './DashboardContext';

import currencies from "../data/currency_data.json"

const SharedDashboardDataContext = createContext();

/*
Description: 

Here, we are getting all data that was fetched in Parent context (DashboardContext) 
So we can share it across the app and change its state whenever we want and it wont rerender our page! 

I mean it will but not like fully rerender, so awesome!

One Issue is that we are actually making copy of data from parent so we basically have two data variables that hold something 
Its okay for smaller variables because we dont need to fetch stuff many times but it can be costly for client ram if we go to big with it 

! To Analyze and Change ! 
We can actually set any copied data like dashboardPriceList to empty, when we correctly copy it? 
*/

export function SharedDashboardDataContextProvider({ children }) {


    // Share Price list (between Calendar and PriceList)
    const { dashboardPriceLists, clients, dashboardGuests, currentDashboard, activities } = useDashboardContext()
    const [sharedDashboardPriceLists, setSharedDashboardPriceLists] = useState([...dashboardPriceLists])

    const [sharedCurrentDashboard, setSharedCurrentDashboard] = useState({ ...currentDashboard })

    const [sharedCurrentDashboardLimits, setSharedCurrentDashboardLimits] = useState({})

    const [dashboardCurrency, setDashboardCurrency] = useState(() => {
        // Find proper currency so we can display it in the app
        if (sharedCurrentDashboard && sharedCurrentDashboard.currency_code) {
            const dash_currency = currencies.find(currency => currency.Code === sharedCurrentDashboard.currency_code);
            if (dash_currency) {
                return dash_currency;
            }
        }
        // Return null or another default value if the currency is not found
        return null;
    });


    // Create a new object with keys as id and values as the price list object
    const priceListById = useMemo(() => {
        return sharedDashboardPriceLists.reduce((acc, priceList) => {
            acc[priceList.id] = priceList;
            return acc;
        }, {});
    }, [sharedDashboardPriceLists]);


    // Shared Clients (between Calendar (+ Calendar popups), Clients)
    const [sharedClients, setSharedClients] = useState([...clients])


    // Shared visits (we actually want to hold visits here, so we wont rerender them when user switch something, or add new / edit new..)
    const [sharedVisits, setSharedVisits] = useState([])

    // ## we are storing latest calendar date so we can decide if we want to fetch data again or not 
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const currentYear = currentDate.getFullYear();

    const [calendarDate, setCalendarDate] = useState({
        day: currentDay,
        month: currentMonth, // Months are zero-based
        year: currentYear,
    });

    // Aditional boolean to store, so we can properly fetch calendar visits when user first time openned calendar in his session
    const [initialCalendarFetch, setInitialCalendarFetch] = useState(false)



    // Shared Activities
    const [sharedActivities, setSharedActivities] = useState(activities)


    // Shared state for ControlPanel
    const [initialControlPanelFetch, setInitialControlPanelFetch] = useState(false)
    const [dashboardUsers, setDashboardUsers] = useState([...dashboardGuests])
    const [dashboardInvites, setDashboardInvites] = useState([])



    // Shared state for Analytics 
    const [chartA1Data, setChartA1Data] = useState([])
    const [chartA2Data, setChartA2Data] = useState([])

    // Those variables filterBy and filterOption has to have the same values as in filters and filters_options in analytics.js
    const [filterBy, setFilterBy] = useState("Week")
    const [filterOption, setFilterOption] = useState("Current Week")

    const [analyticsData, setAnalyticsData] = useState({
        visits_amount: 0,
        visits_revenue: 0,
        visits_avg: 0,
        visits_count_by_tag: {},
        visits_count_by_user: {},
        visits_count_price_by_user: {}
    })



    // Shared homepage 
    const [todayVisits, setTodayVisits] = useState([])


    return (
        <SharedDashboardDataContext.Provider value={{ sharedCurrentDashboardLimits, setSharedCurrentDashboardLimits, todayVisits, setTodayVisits, setSharedActivities, sharedActivities, sharedCurrentDashboard, setSharedCurrentDashboard, setDashboardCurrency, dashboardCurrency, analyticsData, setAnalyticsData, filterBy, setFilterBy, filterOption, setFilterOption, chartA1Data, chartA2Data, setChartA2Data, setChartA1Data, dashboardUsers, dashboardInvites, setDashboardUsers, setDashboardInvites, initialControlPanelFetch, setInitialControlPanelFetch, initialCalendarFetch, setInitialCalendarFetch, calendarDate, setCalendarDate, sharedVisits, setSharedVisits, priceListById, sharedClients, setSharedClients, sharedDashboardPriceLists, setSharedDashboardPriceLists }}>
            {children}
        </SharedDashboardDataContext.Provider>
    )
}

export const useSharedDashboardContext = () => {
    return useContext(SharedDashboardDataContext);
};