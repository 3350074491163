import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";


export async function API_GetActivities(token, dashboard_id, cursor) {

    let URL = `${CURRENT_USING_URL}/activity/get-activities/${String(dashboard_id)}`;

    if (cursor) {
        URL = `${CURRENT_USING_URL}/activity/get-activities/${String(dashboard_id)}?cursor=${cursor}`;
    }

    console.log("\n[API_GetActivities]\nTrying to get activities .. \nCalling: ", URL)
    console.log("Dashboard id:", dashboard_id)
    console.log("next cursor:", cursor)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetActivities]\nActivities found!")

            const response_json = await response.json();

            console.log("\n[API_GetActivities]\nFetched activities array: \n", response_json)

            return { isOk: true, response: response, items: (response_json.results) ? response_json.results : [], next: response_json.next }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetActivities]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetActivities]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    } catch (error) {
        console.log("\n[API_GetActivities]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}