
// React
import React from 'react'
import { useNavigate } from 'react-router-dom'

// Styles 
import classes from "../styles/styles_pages/PageNotFound.module.scss"

// Layout
import LayoutHero from './Entrance/LayoutHero/LayoutHero'

// Icons / SVG
import errorImage from "../static/404image.png"

// Small element wrappers
import { WrapperFlexRow } from '../components/Elements/Wrappers'
import { ButtonNeutralToDanger, ButtonPrimaryFill } from '../components/Elements/Buttons'

import { useTranslation } from 'react-i18next'


function PageNotFound() {

    const navigate = useNavigate();

    const { t } = useTranslation();

    function handleGoToHome() {
        // Here, we dont update currentPath because we go back to /dashboards
        navigate(`/`)

    }

    return (
        <LayoutHero centerContent={true}>

            <div className={classes.PageNotFound}>

                <div className="container">

                    <div className={classes.PageNotFoundWrapper}>

                        <img className={classes.ErrorImage} src={errorImage} alt="Error graphic" />
                        <h2 className="SectionTitleText"> {t("Common.Oops", "Oops")}! </h2>
                        <h3 className='DefaultText'> {t("PageNotFound.page_not_found", "Page not found")}! </h3>

                        <WrapperFlexRow style={{ alingItems: "center", justifyContent: "center", height: "unset" }}>
                            <ButtonPrimaryFill text={t("Common.go_back_to_home", "Go back to Home")} onClick={handleGoToHome} />
                            <ButtonNeutralToDanger text={t("Common.report_bug", "Report a bug")} />
                        </WrapperFlexRow>

                    </div>
                </div>

            </div>

        </LayoutHero>
    )
}

export default PageNotFound