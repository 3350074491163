import React from 'react'
import { ButtonCancel, ButtonDelete, ButtonSave } from './FormButtons';
import { useTranslation } from 'react-i18next';
import { useSharedDashboardContext } from '../../context/SharedDashboardDataContext';
import { PriceListTag } from '../../pages/Dashboard/Pages/PriceList';
import { useNavigate } from 'react-router-dom';
import { useDashboardContext } from '../../context/DashboardContext';
import { monthNames } from '../../helper/_calendar';
import { translateMonth } from '../../translations/translation_helpers';
import { ButtonNeutralToLightPrimary } from './Buttons';
import { formatTimeRange } from '../../helper/funcs';

// User as ClientBox
export function DataBoxWrapper({ style = {}, children, className }) {

    return (
        <div style={style} className={`DataBoxWrapper ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}


// Neutral box that we put stuff into it
export function NormalBox({ style = {}, children, className }) {

    return (
        <div style={style} className={`NormalBox ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}

// Updated version of DataBoxWrapper
export function DataBox({ style = {}, children, className }) {

    return (
        <div style={style} className={`DataBox ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}

export function VisitBox({ visit }) {

    const { priceListById } = useSharedDashboardContext()

    let tag_bg = (priceListById[visit.visit_assigned_to_price_list_id]) ? (priceListById[visit.visit_assigned_to_price_list_id]).color : ""
    let tag_text = (priceListById[visit.visit_assigned_to_price_list_id]) ? (priceListById[visit.visit_assigned_to_price_list_id]).tag : ""
    let timeRange = formatTimeRange(visit.start_hour, visit.start_minute, visit.length)

    return (
        <div className='VisitBox HideScrollbar' style={{ borderColor: `${visit.visit_accent_color}` }}>


            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>

                {/* Client name + tag  */}
                <WrapperFlexRow style={{ padding: "0" }}>
                    <p className="SmallText text-bold"> {visit.visit_client.name} {visit.visit_client.lastname} </p>

                    {/* Tag  */}
                    <PriceListTag bg={tag_bg} text={tag_text} />
                </WrapperFlexRow>


                {/* Visit Time  */}
                <p className="SmallText text-light text_no_wrap"> {timeRange} </p>

            </WrapperFlexRow>


            <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>

                {/* Visit Description  */}
                <p className="SmallText text-light"> {visit.description} </p>

                {/* Visit Price  */}
                <p className="SmallText text-light text_no_wrap"> {visit.price} zł </p>

            </WrapperFlexRow>
        </div>
    )
}

// Activity text that can print stuff depending on activity 
export function ActivityText({ activity }) {

    function matchActivityType(activity_type) {

        switch (activity_type) {
            case "add":
                return "dodał"
            case "delete":
                return "usunął"
            default:
                return "--"
        }
    }

    const activity_type = matchActivityType(activity.type)


    return (
        <WrapperFlexRow style={{ padding: "0", height: "unset", alignItems: "flex-start", gap: "1rem" }}>

            {/* Activity date  */}
            <p className="SmallText text-light text-gray"> 05.02 </p>

            {/* Activity content  */}
            <p className="SmallText text-light">
                <span className='text-bold'> {activity.owner} </span>
                {activity_type} nową wizytę dla
                <span className='text-bold'> {activity.client} </span>
                na dzień
                <span className='text-bold'> {activity.date} </span>
                <span className='text-bold'> {activity.time} </span>
            </p>

        </WrapperFlexRow>
    )
}

// Link that has svg on the right side
// Mainly used as navigate to link (in home page)
export function LinkWithSVG({ style = {}, className, SVG, text, navigateTo = "", navigateOutsideTo = "" }) {

    // OPTIONAL navigateTo - name of proper dashboard site like 'price-list' or 'calendar'
    // OPTIONAL navigateOutsideTo - full link to go to 

    const { moveToPathInsideDashboard, dashboardHash } = useDashboardContext()
    const navigateToFullPath = `/dashboard/${dashboardHash}/` + navigateTo

    return (
        (navigateTo) ?
            <p style={{ style }} onClick={() => moveToPathInsideDashboard(navigateToFullPath)} className={`LinkWithSVG SmallText text_no_wrap text-gray ${(className) ? className : ""}`}> {text} {SVG && SVG}</p>
            : (navigateOutsideTo) ?
                <a style={{ style }} target={"_blank"} rel={"noreferrer"} href={(navigateOutsideTo)} className={`LinkWithSVG SmallText text_no_wrap text-gray ${(className) ? className : ""}`}> {text} {SVG && SVG}</a>
                :
                <p style={{ style }} className={`LinkWithSVG SmallText text_no_wrap text-gray ${(className) ? className : ""}`}> {text} {SVG && SVG}</p>
    );
}

// Pretty print date (used firstly in blog dates )
export function PrettyDate({ date, style, className = "" }) {

    // date is in format YYYY-MM-DD 
    const { t } = useTranslation()

    // Parse the date string to get the year, month, and day
    const [year, month, day] = date.split('-');
    // Convert month to its corresponding name
    const monthName = monthNames[parseInt(month, 10) - 1].toLowerCase();; // Subtract 1 because month index starts from 0
    const translatedMonth = translateMonth(monthName, t) // It will hold translated month name like "Luty" or "February"

    return (
        <WrapperFlexRow
            style={{
                padding: className ? "" : "0", // Apply padding: 0 only if className is provided
                alignItems: "center",
                width: "unset",
                ...style
            }}
            className={className}
        >

            <p className='BigText text_light_bold'> {day} </p>

            <WrapperFlexCol style={{ padding: "0", gap: "0", justifyContent: "center", minWidth: "max-content", width: "60px" }}>
                <p className='SmallText text_light_bold'> {translatedMonth} </p>
                <p className='SmallerText'> {year} </p>
            </WrapperFlexCol>
        </WrapperFlexRow>
    );
}

export function IconBox({ style = {}, className, SVG, navigateTo, navitageToWindow = "" }) {

    // Just a component that will display icon with some styles in center 
    // Also, if we pass navigate To, we can make it as a link so we can click it 

    // If navigateToWindow is passed (name of location) then we go there, if not we go to navigateTo (Within dashboard)

    const { moveToPathInsideDashboard, dashboardHash } = useDashboardContext()
    const navigate = useNavigate()

    const isClickable = (navigateTo) ? true : false
    const navigateToFullPath = (isClickable) ? `/dashboard/${dashboardHash}/` + navigateTo : ""

    return (
        <div style={{ style }} className={`IconBox ${(className) ? className : ""}`} onClick={() => {

            if (!navitageToWindow) {
                moveToPathInsideDashboard(navigateToFullPath)
            }
            else {
                navigate(navitageToWindow)
            }


        }}>
            {SVG && SVG}
        </div>
    );
}


// Wrapper for every icon we are using in page
export function ClickableIconWrapper({ style = {}, children, className, onClick }) {

    return (
        <div style={style} onClick={onClick} className={`ClickableIconWrapper ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}

// Tag and Tag container (for user box)
export function Tag({ isNew, type = "Normal", text = "" }) {

    const { t } = useTranslation();

    if (type === "Normal") {
        return <p className={"Tag"}>{text}</p>
    }
    else if (type === "New") {
        return isNew ? <p className={"Tag"}>{t("Common.new", "New")}</p> : null;
    }
    else if (type === "text_only") {
        return <p className={"SmallText text-gray"}> {text} </p>
    }

    return <p className={"Tag"}>{text}</p>
};

export function BoxTagsContainer({ children, tagClassName }) {
    return (
        <div className="BoxTagsContainer">
            {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, { className: tagClassName, key: index });
            })}
        </div>
    );
}

// This is wrapper that has 1rem gap and flex-direction row
export function WrapperFlexRow({ style = {}, children, className, onClick, onMouseEnter, onMouseLeave }) {

    return (
        <div style={style} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`WrapperFlexRow ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}

// Wrapper display: grid for 4 elements 
export function WrapperGrid4({ style = {}, children, className, onClick }) {

    return (
        <div style={style} onClick={onClick} className={`WrapperGrid4 ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}

// Wrapper display: grid for 2 elements 
export function WrapperGrid2({ style = {}, children, className, onClick }) {

    return (
        <div style={style} onClick={onClick} className={`WrapperGrid2 ${(className !== undefined) ? className : ""}`}>
            {children}
        </div>
    )
}

// Simple Box used in ControlPanel
export function PerrmissionBox({ style = {}, className, text = "" }) {

    return (
        <div style={style} className={`PerrmissionBox ${(className !== undefined) ? className : ""}`}>
            <p className='SmallerText text-bold'>{text}</p>
        </div>
    )
}



// This is wrapper that has 1rem gap and flex-direction column 
export function WrapperFlexCol({ children, as_form = false, on_submit = "", style = {}, className, onClick }) {

    // as_form means that we can return form instead of div 

    return (
        <>
            {(as_form)
                ?
                <form onSubmit={on_submit} className={`WrapperFlexCol ${(className !== undefined) ? className : ""}`} style={style} onClick={onClick}>
                    {children}
                </form>
                :
                <div style={style} className={`WrapperFlexCol ${(className !== undefined) ? className : ""}`} onClick={onClick}>
                    {children}
                </div>
            }
        </>
    )
}

// Used mainly in Popups (Like new client or something)
export function TwoActionButtonsWrapper({ onCancel, as_form = true, onSave, cancelLabel = "Cancel", saveLabel = "Save", isSaveDisabled = false, saveButtonAsDanger = false }) {
    return (
        <div className="TwoActionButtonsWrapper">
            <ButtonCancel onClick={onCancel} label={cancelLabel} />
            {(saveButtonAsDanger)
                ?
                <ButtonDelete type={(as_form) ? "submit" : "button"} onClick={onSave} label={saveLabel} disabled={isSaveDisabled} />
                :
                <ButtonSave type={(as_form) ? "submit" : "button"} onClick={onSave} label={saveLabel} disabled={isSaveDisabled} />
            }
        </div>
    );
}

// When we delete something we use this in popups
export function TwoActionButtonsDangerWrapper({ onCancel, onSave, isSuccess = false, cancelLabel = "Cancel", deleteLabel = "Delete" }) {
    return (
        <div className="TwoActionButtonsDangerWrapper">
            <ButtonCancel onClick={onCancel} label={cancelLabel} />
            {
                (!isSuccess) && <ButtonDelete onClick={onSave} label={deleteLabel} />
            }

        </div>
    );
}

// This is used in AddNewClient popup
export function ImageSelectorWrapper({ children, client, animalIcons, pathToIcons, setClientIcon }) {

    return (
        <div className="ImageSelectorWrapper">
            {children}
            <SelectImageContainer
                client={client}
                animalIcons={animalIcons}
                pathToIcons={pathToIcons}
                setClientIcon={setClientIcon}
            />
        </div>
    );
}

// Helper for ImageSelectorWrapper
export function SelectImageContainer({ client, animalIcons, pathToIcons, setClientIcon }) {
    const handleIconSelection = (iconName) => {
        setClientIcon(iconName);
    };

    return (
        <div className="SelectImageContainer">
            {/* Picked image */}
            <div className="SelectedImageContainer">
                <img src={pathToIcons + client.icon} alt={client.icon} />
            </div>

            {/* Images gallery to choose from */}
            <div className="ImagesGallery HideScrollbar">
                {animalIcons.map((ele) => (
                    <img
                        key={ele.icon_name}
                        className={pathToIcons + ele.icon_name === client.icon ? "ImagePicked" : ""}
                        onClick={() => handleIconSelection(ele.icon_name)}
                        src={pathToIcons + ele.icon_name}
                        alt={ele.icon_name}
                    />
                ))}
            </div>
        </div>
    );
}

// This is used in user-settings
// it is almost the same as 'ImageSelectorWrapper' but made for user profile images instead
export function UserAvatarSelectorWrapper({ classes, children, user, avatarIcons, pathToIcons, setClientIcon, style }) {

    return (
        <div className={`ImageSelectorWrapper UserAvatarSelectorWrapper ${classes}`} style={style}>
            {children}
            <UserAvatarSelectImage
                user={user}
                avatarIcons={avatarIcons}
                pathToIcons={pathToIcons}
                setClientIcon={setClientIcon}
            />
        </div>
    );
}

// Helper for ImageSelectorWrapper
export function UserAvatarSelectImage({ user, avatarIcons, pathToIcons, setClientIcon }) {
    const handleIconSelection = (iconName) => {
        setClientIcon(iconName);
    };

    return (
        <div className="SelectImageContainer">
            {/* Picked image */}
            <div className="SelectedImageContainer">
                <img src={pathToIcons + user.avatar} alt={user.avatar} />
            </div>

            {/* Images gallery to choose from */}
            <div className="ImagesGallery HideScrollbar">
                {avatarIcons.map((ele, idx) => (
                    <img
                        key={`${ele.avatar_name}-${idx}`}
                        className={pathToIcons + ele.avatar_name === user.avatar ? "ImagePicked" : ""}
                        onClick={() => handleIconSelection(ele.avatar_name)}
                        src={pathToIcons + ele.avatar_name}
                        alt={ele.avatar_name}
                    />
                ))}
            </div>
        </div>
    );
}

// This is the icon that shows in title section
export function TitleSectionIconWrapper({ style = {}, stroke_fill = true, icon, className = {} }) {
    return (
        <div style={style} className="SectionIconWrapper">
            {React.cloneElement(icon, { className: `${(stroke_fill) ? "SVG_FILL" : "SVG_STROKE"} ${className}` })}
        </div>
    );
}


// This is the icon that client has in Table view
export function RowIcon({ iconSrc, altText, style = {} }) {
    return (
        <div style={style} className="RowIcon">
            <img src={iconSrc} alt={altText} />
        </div>
    );
}

// Two elements in column display (Like Name and under phone)
export function TwoElementsInColumn({ children, style = {}, className = {} }) {

    return (
        <div className={`TwoElementsInColumn ${className}`} style={style}>
            {children}
        </div>
    )
}

// Used in displaying ClientsTable and also ControllPanel guests and other
export function SingleRowDisplay({ children }) {

    return (
        <div className='SingleRowDisplay'>
            {children}
        </div>
    )
}

// This is our wrapper that will be on the left of SingleRow 
export function SingleRowLeft({ children, style = {} }) {

    return (
        <div className='SingleRowLeft' style={style}>
            {children}
        </div>
    )
}

// This is our wrapper that will be on the right of SingleRow
export function SingleRowRight({ children, style = {} }) {

    return (
        <div className='SingleRowRight' style={style}>
            {children}
        </div>
    )
}

// Tabs are filters first used in Clients
export function SectionTabsContainer({ handleFilter, filter, children }) {
    return (
        <>
            <div className={"SectionTabsContainer"}>
                {React.Children.map(children, (child, index) => {
                    // Clone the child element and pass additional props
                    return React.cloneElement(child, {
                        key: index,
                        onClick: () => handleFilter(child.props.filterType),
                        isActive: filter === child.props.filterType,
                    });
                })}
            </div>

            <hr className="SectionTabsContainer_Hr" />
        </>
    );
}

export function SelectBetweenButtons({ children, style = {}, translationFunction, currentValue = "" }) {

    // This is a wrapper where we can select multiple things (by pressing button) 
    // Only one value can be selected
    // We need to pass currentValue like currentPermissions and translationFunction that will take buttons text (in english) and translate it 


    const { t } = useTranslation();

    return (
        <div style={style} className='ButtonSelectBetween'>
            {React.Children.map(children, (child) => {
                // Check if the child element is a button
                if (React.isValidElement(child) && child.type === ButtonNeutralToLightPrimary) {

                    const translatedText = translationFunction(child.props.text, t);
                    const isActive = child.props.text === currentValue;


                    return React.cloneElement(child, {
                        text: translatedText,
                        className: isActive ? 'isActive' : '', // Add the isActive class conditionally
                    });

                }


                return child;
            })}
        </div>
    );
}

export function TabButton({ onClick, isActive, label, isDisabled, isLocked, }) {
    let buttonClasses = "TabText ";
    if (isActive) {
        buttonClasses += " FilterCurrent";
    }
    if (isLocked) {
        buttonClasses += ` FilterLocked`;
    }

    return (
        <button
            onClick={onClick}
            className={buttonClasses}
            disabled={isDisabled}
        >
            {label}
        </button>
    );
}

// Layout option is a wrapper that holds icon inside
// They are used as switch layout (Table <-> Box) in Clients.js
export function LayoutOption({ isActive, onClick, children, className = {} }) {
    let itemClasses = 'LayoutIcon';
    if (isActive) {
        itemClasses += ' CurrentLayout';
    }

    const iconClassName = isActive ? `SVG_STROKE svg_medium ${className}` : `${className} svg_medium SVG_STROKE `;

    return (
        <div className={itemClasses} onClick={onClick}>
            {React.cloneElement(children, { className: iconClassName })}
        </div>
    );
}

export function MySelect({ className, name, value, onChange, options, style, disabled = false }) {

    return (
        <select style={style} className={`MySelect ${className}`} name={name} value={value} onChange={onChange} disabled={disabled}>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
}




export function LoadingAnimationFourDots() {
    return (
        <span className="loading_animation"></span>
    )
}

export function DashboardColorPicker({ colors, selectedColor, onColorChange, style = {} }) {

    return (
        <div className="DashboardColorPicker" style={style}>
            {colors.map((color, idx) => (
                <div
                    key={`color-${idx}-${color}`}
                    className={selectedColor === color.color ? 'PickedColor' : ''}
                    onClick={() => onColorChange(color.color)}
                    style={{ backgroundColor: color.color }}
                ></div>
            ))}
        </div>
    );
}




export function DropdownContent({ children, className, stickTo = "Left" }) {

    // stickTo was made so we can decide if dropdown has to stick to right side or left side 
    // stickTo only can be as "Left" or "Right"
    // "Left" means that our dropdown will grow in width from left 
    // "Right" means that our dropdown will not grow in right direction it will stick to right as parent element and it will grow in left direction

    return (
        <div className={`DropdownContent ${className} ${(stickTo === "Left") ? "stickLeft" : ""} ${(stickTo === "Right") ? "stickRight" : ""}`}>
            {children}
        </div>
    )
}




export function RoundedProgresBar({ min = "0", max = "100", value = "0" }) {
    // Function to get the value of a CSS variable
    const getComputedStyleValue = (element, property) => {
        return window.getComputedStyle(element).getPropertyValue(property);
    };

    // Reference to the progress bar element
    const progressBarRef = React.useRef(null);

    // Effect to update the background color when the component mounts
    React.useEffect(() => {
        if (progressBarRef.current && parseFloat(value) < 80) {
            const primaryColor = getComputedStyleValue(progressBarRef.current, "--primary_color");
            const lightPrimary = getComputedStyleValue(progressBarRef.current, "--light_primary_hover");
            const radialGradient = getComputedStyleValue(progressBarRef.current, "--radial_gradient_settings");
            const progressBarStyle = {
                background: `
                radial-gradient(${radialGradient}),
                conic-gradient(${primaryColor} ${value}%, ${lightPrimary} 0)`,
            };
            progressBarRef.current.style.background = progressBarStyle.background;
        }
        else if (progressBarRef.current && parseFloat(value) >= 80) {
            const primaryColor = getComputedStyleValue(progressBarRef.current, "--error_color");
            const lightPrimary = getComputedStyleValue(progressBarRef.current, "--error_background");
            const radialGradient = getComputedStyleValue(progressBarRef.current, "--radial_gradient_settings");
            const progressBarStyle = {
                background: `
                radial-gradient(${radialGradient}),
                conic-gradient(${primaryColor} ${value}%, ${lightPrimary} 0)`,
            };
            progressBarRef.current.style.background = progressBarStyle.background;
        }
    }, [value]);

    return (
        <div className="RoundedProgresBar" ref={progressBarRef}>
            <p className='SmallerText text-bold'> {value}% </p>
            <progress value={value} min={min} max={max} style={{ visibility: "hidden", height: "0", width: "0" }}></progress>
        </div>
    );
}