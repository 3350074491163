

import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common.js"


// This API is a start of creating subscription, first we create a checkout session 
// This api will return proper stripe checkout url, so in frontend we will use it to open new tab to proceed with payment
export async function API_CreateCheckoutSession(token, price_Id, dashboard_id, user_id, user_email) {
    const URL = `${CURRENT_USING_URL}/stripe/create-checkout-session`;

    console.log("\n[API_CreateCheckoutSession]\nTrying to create Stripe checkout session.. \nCalling: ", URL)
    console.log("\n[API_CreateCheckoutSession]\nGot price_Id: ", price_Id)
    console.log("\n[API_CreateCheckoutSession]\nGot dashboard_id: ", dashboard_id)
    console.log("\n[API_CreateCheckoutSession]\nGot user_id: ", user_id)
    console.log("\n[API_CreateCheckoutSession]\nGot user_email: ", user_email)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify({
                priceId: price_Id,
                dashboardId: dashboard_id,
                userId: user_id,
                userEmail: user_email
            })
        });

        // console.log("STRIPE RESPONSE_ ", response)

        if (response.ok) {
            const response_json = await response.json();
            console.log("[API_CreateCheckoutSession] Success, returning checkout url: ", response_json)

            return { isOk: true, response: response, checkout_url: response_json }
        }

        else {
            const response_json = await response.json();

            console.log("\n[API_CreateCheckoutSession]\nSomething went wrong with the response: ", response)
            console.log("\n[API_CreateCheckoutSession]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_CreateCheckoutSession]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


export async function API_GetDashboardByOwnerWithSubscriptions(token) {
    const URL = `${CURRENT_USING_URL}/stripe/get-dashboards-by-user-with-subscription/`;

    console.log("\n[API_GetDashboardByOwnerWithSubscriptions]\nTrying to get dashboards by owner with subscriptions data. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_GetDashboardByOwnerWithSubscriptions]\nDashboards found: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetDashboardByOwnerWithSubscriptions]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetDashboardByOwnerWithSubscriptions]\nResponse json: \n", response_json)
            return { isOk: true, response: response }
        }

    } catch (error) {
        console.log("\n[API_GetDashboardByOwnerWithSubscriptions]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }

}


export async function API_CancelSubscription(token, dashboard_id) {
    const URL = `${CURRENT_USING_URL}/stripe/cancel-subscription/`; // Ensure this is the correct endpoint

    console.log("\n[API_CancelSubscription]\nTrying to cancel subscription.. \nCalling: ", URL);
    console.log("\n[API_CancelSubscription]\nGot dashboard_id: ", dashboard_id);

    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify({
                dashboard_id: dashboard_id,  // Use camelCase for consistency
            })
        });

        if (response.ok) {
            const response_json = await response.json();
            console.log("[API_CancelSubscription] Success: ", response_json);
            return { isOk: true, response: response, data: response_json };
        } else {
            const response_json = await response.json();
            console.log("\n[API_CancelSubscription]\nSomething went wrong with the response: ", response);
            console.log("\n[API_CancelSubscription]\nResponse json: \n", response_json);
            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json };
        }
    } catch (error) {
        console.log("\n[API_CancelSubscription]\nError fetching data:\n ", error);
        return { isOk: false, error_message: server_off_message };
    }
}

// Get dashboard_status_limits that will tell us the limits for every subscriptions
export async function API_GetSubscriptionsLimits(token) {
    const URL = `${CURRENT_USING_URL}/subscriptions/get-subscriptions-limits/`;

    console.log("\n[API_GetSubscriptionsLimits]\nTrying to get subscriptions limits data. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_GetSubscriptionsLimits]\nData found: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetSubscriptionsLimits]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetSubscriptionsLimits]\nResponse json: \n", response_json)
            return { isOk: true, response: response }
        }

    } catch (error) {
        console.log("\n[API_GetSubscriptionsLimits]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }

}


export async function API_STRIPE_GetPaymentMethods(token, user_id) {
    const URL = `${CURRENT_USING_URL}/stripe/get-payment-methods/`;

    console.log("\n[API_STRIPE_GetPaymentMethods]\nTrying to get payment methods.. \nCalling: ", URL)
    console.log("\n[API_STRIPE_GetPaymentMethods]\nUser id: ", user_id)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify({
                userId: user_id,
            })
        });

        if (response.ok) {
            const response_json = await response.json();
            console.log("[API_STRIPE_GetPaymentMethods] Success, returning payment methods: ", response_json)

            return { isOk: true, response: response, items: response_json }
        }

        else {
            const response_json = await response.json();

            console.log("\n[API_STRIPE_GetPaymentMethods]\nSomething went wrong with the response: ", response)
            console.log("\n[API_STRIPE_GetPaymentMethods]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_STRIPE_GetPaymentMethods]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_STRIPE_ChangePaymentMethodRequest(token, user_id, requestDetails) {
    const URL = `${CURRENT_USING_URL}/stripe/change-payment-method-request/`;

    console.log("\n[API_STRIPE_ChangePaymentMethodRequest]\nTrying to send payment method change request.. \nCalling: ", URL)
    console.log("\n[API_STRIPE_ChangePaymentMethodRequest]\nUser id: ", user_id)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify({
                userId: user_id,
                requestDetails: requestDetails
            })
        });

        if (response.ok) {
            const response_json = await response.json();
            console.log("[API_STRIPE_ChangePaymentMethodRequest] Success, returning payment methods: ", response_json)

            return { isOk: true, response: response, items: response_json }
        }

        else {
            const response_json = await response.json();

            console.log("\n[API_STRIPE_ChangePaymentMethodRequest]\nSomething went wrong with the response: ", response)
            console.log("\n[API_STRIPE_ChangePaymentMethodRequest]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_STRIPE_ChangePaymentMethodRequest]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// INVOICES
export async function API_STRIPE_GetAllInvoices(token, user_id) {
    const URL = `${CURRENT_USING_URL}/stripe/get-invoices/`;

    console.log("\n[API_STRIPE_GetAllInvoices]\nTrying to get user invoices.. \nCalling: ", URL)
    console.log("\n[API_STRIPE_GetAllInvoices]\nUser id: ", user_id)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify({
                userId: user_id,
            })
        });

        if (response.ok) {
            const response_json = await response.json();
            console.log("[API_STRIPE_GetAllInvoices] Success, returning invoices: ", response_json)

            return { isOk: true, response: response, items: response_json }
        }

        else {
            const response_json = await response.json();

            console.log("\n[API_STRIPE_GetAllInvoices]\nSomething went wrong with the response: ", response)
            console.log("\n[API_STRIPE_GetAllInvoices]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_STRIPE_GetAllInvoices]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


// INVOICES
export async function API_GetSpecifiedDashboardSubscriptionDetail(token, user_id, dashboard_id) {
    const URL = `${CURRENT_USING_URL}/subscriptions/get-specified-dashboard-subscription/`;

    console.log("\n[API_GetSpecifiedDashboardSubscriptionDetail]\nTrying to get dashboard subscription info.. \nCalling: ", URL)
    console.log("User id: ", user_id)
    console.log("Dashboard id: ", dashboard_id)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify({
                userId: user_id,
                dashboardId: dashboard_id,
            })
        });

        if (response.ok) {
            const response_json = await response.json();
            console.log("[API_GetSpecifiedDashboardSubscriptionDetail] Success, returning data: ", response_json)

            return { isOk: true, response: response, items: response_json }
        }

        else {
            const response_json = await response.json();

            console.log("\n[API_GetSpecifiedDashboardSubscriptionDetail]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetSpecifiedDashboardSubscriptionDetail]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_GetSpecifiedDashboardSubscriptionDetail]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}