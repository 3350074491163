// React 
import React, { memo, useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';


// Elements / Wrappers
import { DataBox, NormalBox, WrapperFlexCol, WrapperFlexRow, WrapperGrid2, WrapperGrid4 } from '../../../components/Elements/Wrappers';
import { ButtonNeutralToDanger, ButtonNeutralToLightPrimary, ButtonPrimaryFill } from '../../../components/Elements/Buttons';
import { SingleInput } from '../../../components/Elements/FormInputs';
import { PriceListTag } from './PriceList';

import { JumpingPopup } from '../../../components/Elements/JumpingPopup';
// import { LoadingDashboardPage } from '../../Elements/LoadingBoxes';

// Icons 



// APis 
import { API_GetVisitsByDateFromTo } from '../../../api/API_Analytics';



// Context 
import { useUserContext } from '../../../context/UserAuth';
import { useDashboardContext } from '../../../context/DashboardContext';
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext';


// Helpers
import { format, subDays, subMonths, subYears, eachDayOfInterval } from 'date-fns';
import dayjs from 'dayjs';
import CountUp from "react-countup";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import paths from "../../../data/paths.json"
import { getEndOfCurrentMonth, getFirstDayOfPreviousYear } from '../../../helper/dateFunctions';
import { useTheme } from '../../../context/ThemeProvider';




function Analytics() {

    const { t } = useTranslation()

    const { darkTheme } = useTheme()

    const { currentUser } = useUserContext()
    const { currentDashboard, dashboardUsersAsDictionary } = useDashboardContext()
    const { analyticsData, setAnalyticsData, chartA1Data, setChartA1Data, chartA2Data, setChartA2Data, filterBy, setFilterBy, filterOption, setFilterOption, priceListById, dashboardCurrency } = useSharedDashboardContext()

    const token = localStorage.getItem("access_token")

    const path_to_avatars = paths.path_to_avatar_images;

    const [dataLoading, setDataLoading] = useState(false)
    const [error, setError] = useState("")

    // For PDF download 
    const pageRef = useRef(null);

    // We have to keep it in format 
    // YYYY-MM-DD
    const [fetchDate, setFetchDate] = useState({
        date_from: format(new Date(), "yyyy-MM-dd"),
        date_to: format(new Date(), "yyyy-MM-dd")
    });



    const filters = ["Day", "Week", "Month", "Year"]

    const filters_options = {
        "Day": ["Today", "Yesterday"],
        "Week": ["Current Week", "Previous Week"],
        "Month": ["Current Month", "Previous Month", "3 Months", "6 Months", "9 Months"],
        "Year": [
            "Current Year",
            "Previous Year",
            // "All history (2 years)"
        ],
    };

    const translated_filters = [t("Analytics.day", "Day"), t("Analytics.week", "Week"), t("Analytics.month", "Month"), t("Analytics.year", "Year")]

    // Translation - Only for output
    const translated_filters_options = {
        "Day": [t("Analytics.today", "Today"), t("Analytics.yesterday", "Yesterday")],
        "Week": [t("Analytics.current_week", "Current Week"), t("Analytics.previous_week", "Previous Week")],
        "Month": [t("Analytics.current_month", "Current Month"), t("Analytics.previous_month", "Previous Month"), t("Analytics.three_months", "3 Months"), t("Analytics.six_months", "6 Months"), t("Analytics.nine_months", "9 Months")],
        "Year": [
            t("Analytics.current_year", "Current Year"),
            t("Analytics.previous_year", "Previous Year")
            // "All history (2 years)"
        ],
    };



    useEffect(() => {

        // Get the date range based on the selected filter option
        const [date_from, date_to] = getDateRangeFromFilterOption(filterOption);

        // Set the fetchDate state
        setFetchDate({
            date_from: date_from,
            date_to: date_to
        });

    }, [filterOption]);

    useEffect(() => {
        // console.log(filterBy)

        // If user switched option (like moved from Day to Week)
        // we want to clear filterOption that he chosen previously, so he wont get lost and maybe he wont make wrong fetch (he will change filter without filter option so date will be the same)
        if (!filters_options[filterBy].includes(filterOption)) {
            setFilterOption("")
        }
    }, [filterBy])



    async function loadVisits() {

        const { date_from, date_to } = fetchDate;

        if (date_from && date_to && currentUser && currentUser.id && token) {
            setDataLoading(true);

            try {
                const response = await API_GetVisitsByDateFromTo(token, currentDashboard.id, date_from, date_to);

                if (response.isOk && response.items) {
                    const { items: visits } = response;

                    // Create different format for visits to display in charts 
                    const chartA1Data = transformDataForChartA1(visits, fetchDate)
                    const chartA2Data = transformDataForChartA2(visits, fetchDate)

                    const totalRevenue = visits.reduce((sum, visit) => sum + Number(visit.price || 0), 0);

                    const analyticsData = visits.reduce((acc, visit) => {
                        const priceListId = visit.visit_assigned_to_price_list_id;
                        const userId = visit.visit_assigned_worker_id;

                        // Count visits by tag
                        acc.visits_count_by_tag[priceListId] = (acc.visits_count_by_tag[priceListId] || 0) + 1;

                        // Count visits by user
                        acc.visits_count_by_user[userId] = (acc.visits_count_by_user[userId] || 0) + 1;

                        // Calculate total price per user
                        acc.visits_count_price_by_user[userId] = (acc.visits_count_price_by_user[userId] || 0) + Number(visit.price || 0);

                        return acc;
                    }, {
                        visits_count_by_tag: {},
                        visits_count_by_user: {},
                        visits_count_price_by_user: {}
                    });

                    setAnalyticsData({
                        visits_amount: visits.length,
                        visits_avg: (totalRevenue / (visits.length ? visits.length : 1)).toFixed(2),
                        visits_revenue: totalRevenue.toFixed(2),
                        ...analyticsData
                    });

                    // setVisits(visits)
                    setChartA1Data(chartA1Data)
                    setChartA2Data(chartA2Data)

                }
                else {
                    let err_message = (response.response_json.detail) ? response.response_json.detail : response.error_message

                    // We want to get seconds from .detail because we get message like this from backend: 
                    // Request was throttled. Expected available in 60 seconds.
                    if (err_message.includes('secon')) {


                        const text = err_message;
                        const regex = /\d+(?= seconds)/; // Match one or more digits followed by " seconds"
                        const match = text.match(regex);
                        const seconds = match[0]

                        // setError(`Too many requests. Please wait ${seconds} seconds`)
                        setError(t('validationErrors.too_many_requests', `Too many requests. Please wait ${seconds} seconds`, { seconds }));

                    }

                    else {
                        setError(response.error_message)

                    }


                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            setDataLoading(false);
        }
    }



    // Helper function to get the date range based on the selected filter option
    const getDateRangeFromFilterOption = (option) => {


        const today = new Date();
        const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1));

        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        const startOfYear = new Date(today.getFullYear(), 0, 1);

        const prevWeekStart = new Date(startOfWeek);
        const prevWeekEnd = new Date(startOfWeek);


        switch (option) {
            case "Today":
                return [format(today, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")];

            case "Yesterday":
                const yesterday = subDays(today, 1);

                return [format(yesterday, "yyyy-MM-dd"), format(yesterday, "yyyy-MM-dd")];

            case "Current Week":
                prevWeekStart.setDate(prevWeekStart.getDate()); // Adjust to display the full previous week
                prevWeekEnd.setDate(prevWeekEnd.getDate() + 6);

                return [format(prevWeekStart, "yyyy-MM-dd"), format(prevWeekEnd, "yyyy-MM-dd")];

            case "Previous Week":
                prevWeekStart.setDate(prevWeekStart.getDate() - 7); // Adjust to display the full previous week
                prevWeekEnd.setDate(prevWeekEnd.getDate() - 1);

                return [format(prevWeekStart, "yyyy-MM-dd"), format(prevWeekEnd, "yyyy-MM-dd")];

            case "Current Month":
                return [format(startOfMonth, "yyyy-MM-dd"), format(endOfMonth, "yyyy-MM-dd")];

            case "Previous Month":
                const startOfPreviousMonth = subMonths(startOfMonth, 1);
                const endOfPreviousMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), 0);
                return [format(startOfPreviousMonth, "yyyy-MM-dd"), format(endOfPreviousMonth, "yyyy-MM-dd")];

            case "3 Months":
                const startOf3MonthsAgo = subMonths(today, 3);

                return [format(startOf3MonthsAgo, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")];

            case "6 Months":
                const startOf6MonthsAgo = subMonths(today, 6);

                return [format(startOf6MonthsAgo, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")];

            case "9 Months":
                const startOf9MonthsAgo = subMonths(today, 9);

                return [format(startOf9MonthsAgo, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")];

            case "Current Year":

                return [format(startOfYear, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")];

            case "Previous Year":

                const startOfPreviousYear = subYears(startOfYear, 1);
                const endOfPreviousYear = subDays(startOfYear, 1); // Adjust to the day before the current year starts

                return [format(startOfPreviousYear, "yyyy-MM-dd"), format(endOfPreviousYear, "yyyy-MM-dd")];

            case "All history (2 years)":

                const twoYearsAgo = subYears(today, 2);

                return [format(twoYearsAgo, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")];

            default:
                return ["", ""];
        }
    };


    function handleCustomDateChange(newDate, type) {

        // type can be either "FROM" or "TO" 
        // console.log(newDate)

        const _newDate = dayjs(newDate).format("YYYY-MM-DD")

        if (type === "FROM") {
            setFetchDate({
                ...fetchDate,
                date_from: _newDate,
            });
        }
        else if (type === "TO") {
            setFetchDate({
                ...fetchDate,
                date_to: _newDate,
            });
        }

    }

    function handleFilterByChange(newFilter) {
        setFilterBy(newFilter)
    }

    function handleFilterOptionChange(newFilterOption) {
        setFilterOption(newFilterOption)
    }


    // Data transform from fetched visits to charts formats 

    // Data for A1 chart 
    function transformDataForChartA1(visits) {
        const { date_from, date_to } = fetchDate;

        // Step 1: Group visits by day
        const groupedVisits = visits.reduce((acc, visit) => {
            const day = visit.visit_date;
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(visit);
            return acc;
        }, {});

        // Step 2: Create an array of all days in the date range
        const allDaysInRange = eachDayOfInterval({
            start: new Date(date_from),
            end: new Date(date_to),
        });

        // Step 3: Count the number of visits for each day or set count to 0 if no visits
        const visitCounts = allDaysInRange.map(day => {
            const formattedDay = format(day, "yyyy-MM-dd");
            const visits = groupedVisits[formattedDay] || [];
            return { x: formattedDay, y: visits.length };
        });

        // Step 4: Create an array of objects in the required format
        const seriesData = [{

            name: t("Analytics.amount_of_visits", "Amount of visits"),
            data: visitCounts,
        }];

        return seriesData;
    }

    // Data for A2 chart 
    function transformDataForChartA2(visits) {
        const { date_from, date_to } = fetchDate;

        // Step 1: Group visits by day
        const groupedVisits = visits.reduce((acc, visit) => {
            const day = visit.visit_date;
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(visit);
            return acc;
        }, {});

        // Step 2: Create an array of all days in the date range
        const allDaysInRange = eachDayOfInterval({
            start: new Date(date_from),
            end: new Date(date_to),
        });

        // Step 3: Calculate the sum of visit prices for each day or set sum to 0 if no visits
        const visitSums = allDaysInRange.map(day => {
            const formattedDay = format(day, "yyyy-MM-dd");
            const visits = groupedVisits[formattedDay] || [];
            const sumPrices = visits.reduce((sum, visit) => {
                const visitPrice = parseFloat(visit.price) || 0;
                return sum + visitPrice;
            }, 0);
            return { x: formattedDay, y: sumPrices };
        });

        // Step 4: Create an array of objects in the required format
        const seriesData = [{
            name: t("Analytics.visits_total", "Total visits revenue"),
            data: visitSums,
        }];

        return seriesData;
    }

    // function saveAsPdf() {
    //     if (!pageRef.current) {
    //         console.error('Page element not found');
    //         return;
    //     }

    //     html2canvas(pageRef.current).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF('p', 'mm', 'a4');
    //         const imgWidth = 210;
    //         const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //         pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //         pdf.save('downloaded.pdf');
    //     });
    // }

    // Reset all state to starting one
    function resetAll() {
        setFetchDate({
            date_from: format(new Date(), "yyyy-MM-dd"),
            date_to: format(new Date(), "yyyy-MM-dd")
        });

        setChartA1Data([])
        setChartA2Data([])
        setFilterBy("Week")
        setFilterOption("Current Week")

        setAnalyticsData({
            visits_amount: 0,
            visits_revenue: 0,
            visits_avg: 0,
            visits_count_by_tag: {},
            visits_count_by_user: {},
            visits_count_price_by_user: {}
        })
    }

    return (
        <div className='container is-max-desktop'>


            {/* {dataLoading &&
                <LoadingDashboardPage />
            } */}

            {
                (error) && <JumpingPopup message={error} clearState={setError} clearInterval={2000} />
            }

            <WrapperFlexCol style={{ padding: "0" }}>


                {/* Section title */}
                <WrapperFlexRow className={"WrapperTitle"}>

                    <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                        <h2 className="SectionTitleText"> {t("Sidebar.analytics", "Analytics")} </h2>
                        {/* <p className="DefaultText text_gray_on_layout"> {t("Analytics.under_title", "Maximum 2 years back")} </p> */}

                    </WrapperFlexCol>


                    <ButtonNeutralToDanger className={"withBorder"} onClick={resetAll} text={t("Analytics.reset", "Reset")} />

                </WrapperFlexRow>


                {/* Filters  */}
                <p className='DefaultText text-bold text-gray'>
                    {t("Analytics.filters", "Filters")}
                </p>


                <DataBox className={"HideScrollbar onLayout"}>


                    <WrapperFlexRow className={'FlexRowToColumnOnBigTablet'} style={{ padding: "0", alignItems: "flex-start", justifyContent: "space-between" }}>


                        {/* Custom date  */}
                        <WrapperFlexCol style={{ width: "unset", padding: "0", flex: "1", minWidth: "min-content" }}>

                            <h1 className='SmallText text_light_bold text-gray'>
                                {t("Analytics.select_time_span", "Choose your own time interval")}
                            </h1>

                            <WrapperFlexCol style={{ padding: "0", gap: "0.5rem" }} className={""}>

                                {/* From  */}
                                <WrapperFlexRow style={{ padding: "0" }}>
                                    <p className='DefaultText text_light_bold'> {t("Analytics.from", "From")} </p>
                                    <SingleInput
                                        style={{}}
                                        value={fetchDate.date_from}
                                        onChange={(e) => { handleCustomDateChange(e.target.value, "FROM") }}
                                        label="Date from"
                                        type="date"
                                        disabled={false}
                                        min={getFirstDayOfPreviousYear()}
                                        max={getEndOfCurrentMonth()}
                                    />
                                </WrapperFlexRow>

                                {/* to  */}
                                <WrapperFlexRow style={{ padding: "0" }}>
                                    <p className='DefaultText text_light_bold'> {t("Analytics.to", "To")} </p>
                                    <SingleInput
                                        style={{}}
                                        value={fetchDate.date_to}
                                        onChange={(e) => { handleCustomDateChange(e.target.value, "TO") }}
                                        label="Date to"
                                        type="date"
                                        disabled={false}
                                        min={getFirstDayOfPreviousYear()}
                                        max={getEndOfCurrentMonth()}
                                    />
                                </WrapperFlexRow>

                            </WrapperFlexCol>

                        </WrapperFlexCol>





                        {/* Quick select section */}
                        <WrapperFlexCol style={{ flex: "1", padding: "0", alignItems: "flex-end", minWidth: "min-content", justifyContent: "flex-end" }}>
                            <h1 className='SmallText text_light_bold text-gray'> {t("Analytics.quick_select", "Quick select")} </h1>

                            {/* Display Filters  */}
                            <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end" }}>
                                {filters.map((filter, idx) => {
                                    return (
                                        <ButtonNeutralToLightPrimary key={`filter-${idx}`} text={translated_filters[idx]} onClick={(e) => handleFilterByChange(filter)} className={`btnSmall btnBorder ${filter === filterBy ? 'ButtonPrimaryStyle' : ""}`} />
                                    )
                                })}
                            </WrapperFlexRow>

                            {/* Display filter options  */}
                            <WrapperFlexRow className={"FlexWrapOnTablet"} style={{ padding: "0", justifyContent: "flex-end", flexWrap: "wrap" }}>
                                {filters_options[filterBy].map((filter_option, idx) => {
                                    return (
                                        <ButtonNeutralToLightPrimary key={`filter-${idx}`} text={translated_filters_options[filterBy][idx]} onClick={(e) => handleFilterOptionChange(filter_option)} className={`btnSmall btnBorder ${filter_option === filterOption ? 'ButtonPrimaryStyle' : ""}`} />
                                    )
                                })}
                            </WrapperFlexRow>

                        </WrapperFlexCol>


                    </WrapperFlexRow>


                </DataBox>

                <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", marginBottom: "2rem", marginTop: "0.5rem" }}>
                    <ButtonPrimaryFill text={t("Analytics.load_data", "Load data")} disabled={(!fetchDate.date_from || !fetchDate.date_to || error)} onClick={() => loadVisits()} />
                </WrapperFlexRow>


            </WrapperFlexCol >

            {/* Charts  */}
            <PDFWrapperFlexCol PDFWrapperFlexCol ref={pageRef} style={{ padding: "0", marginTop: "1rem", gap: "1rem" }}>

                <WrapperGrid4 style={{ padding: "0" }} className={"smallerGap onTablet"}>

                    <DataBox className={"onLayout HideScrollBar smallerGap"} >
                        <h2 className='SmallText text_light_bold'> {t("Analytics.amount_of_visits", "Ammount of visits")} </h2>
                        <h2 className='BigText text-bold'>
                            <CountUp start={0} end={analyticsData.visits_amount} duration={2} />
                        </h2>
                    </DataBox>


                    <DataBox className={"onLayout HideScrollBar smallerGap"}>
                        <h2 className='SmallText text_light_bold'> {t("Analytics.total_revenue", "Total revenue")} </h2>
                        <h2 className='BigText text-bold'>
                            <CountUp start={0} end={analyticsData.visits_revenue} duration={2} />
                            &nbsp; {(dashboardCurrency.Symbol) || ""}
                        </h2>
                    </DataBox>


                    <DataBox className={"onLayout smallerGap"} style={{ maxHeight: "150px" }}>
                        <h2 className='SmallText text_light_bold'> {t("Analytics.number_of_services", "Number of services")} </h2>

                        {(Object.keys(analyticsData.visits_count_by_tag).length > 0) && Object.keys(analyticsData.visits_count_by_tag).map((id) => (
                            <WrapperFlexRow style={{ padding: "0" }} key={id} >
                                {(priceListById[id] && priceListById[id].tag && priceListById[id].color) ?
                                    // If visits had a pricelist (was connected)
                                    <PriceListTag text={priceListById[id].tag} bg={priceListById[id].color} />
                                    :
                                    // Or if visits was not connected to price list 
                                    <PriceListTag text={t("Analytics.not_assigned", "not assigned")} bg={"#666"} />
                                }
                                <h2 className='DefaultText text-bold'>
                                    <CountUp start={0} end={analyticsData.visits_count_by_tag[id]} duration={2} />
                                </h2>
                            </WrapperFlexRow>
                        ))}
                    </DataBox>


                    <DataBox className={"onLayout HideScrollBar smallerGap"}>
                        <h2 className='SmallText text_light_bold'> {t("Analytics.avg_visit_price", "Average visit price")} </h2>
                        <h2 className='BigText text-bold'>
                            <CountUp start={0} end={analyticsData.visits_avg} duration={2} />
                            &nbsp; {(dashboardCurrency.Symbol) || ""}
                        </h2>
                    </DataBox>


                </WrapperGrid4>


                <WrapperGrid2 className={"onBigTablet smallerGap"}>

                    <DataBox className={"onLayout HideScrollBar smallerGap"}>

                        <h2 className='DefaultText text-bold'> {t("Analytics.visit_by_worker", "Visits by worker's")} </h2>

                        {(Object.keys(analyticsData.visits_count_by_user).length > 0) && Object.keys(analyticsData.visits_count_by_user).map((id) => {

                            let pathToAvatar = (dashboardUsersAsDictionary[id].avatar) ? path_to_avatars + dashboardUsersAsDictionary[id].avatar : ""

                            return (
                                <WrapperFlexRow style={{ padding: "0", height: "unset" }} key={id} >
                                    <img style={{ maxWidth: "22px" }} src={pathToAvatar} alt={"User avatar"} />
                                    <h2 className='SmallText text_light_bold'> {dashboardUsersAsDictionary[id].username} </h2>
                                    <h2 className='DefaultText text-bold'>
                                        <CountUp start={0} end={analyticsData.visits_count_by_user[id]} duration={2} />
                                    </h2>

                                </WrapperFlexRow>
                            )

                        })}

                        {/* P1 */}
                        <NormalBox style={{ overflow: "hidden", marginTop: "1rem" }}>
                            {/* <h2 className='BigText text-bold'> Wizyty wg. pracownika </h2> */}
                            <PieChartVisitsPerUserP1 darkTheme={darkTheme} analyticsData={analyticsData} dashboardUsersAsDictionary={dashboardUsersAsDictionary} />
                        </NormalBox>


                    </DataBox>

                    <DataBox className={"onLayout HideScrollBar smallerGap"}>

                        <h2 className='DefaultText text-bold'> {t("Analytics.revenue_by_worker", "Revenue by worker's")}  </h2>

                        {(Object.keys(analyticsData.visits_count_price_by_user).length > 0) && Object.keys(analyticsData.visits_count_price_by_user).map((id) => {

                            let pathToAvatar = (dashboardUsersAsDictionary[id].avatar) ? path_to_avatars + dashboardUsersAsDictionary[id].avatar : ""

                            return (
                                <WrapperFlexRow style={{ padding: "0", height: "unset" }} key={id} >
                                    <img style={{ maxWidth: "22px" }} src={pathToAvatar} alt={"User avatar"} />
                                    <h2 className='SmallText text_light_bold'> {dashboardUsersAsDictionary[id].username} </h2>
                                    <h2 className='DefaultText text-bold'>
                                        <CountUp start={0} end={analyticsData.visits_count_price_by_user[id]} duration={2} /> {(dashboardCurrency.Symbol) || ""}
                                    </h2>

                                </WrapperFlexRow>
                            )

                        })}


                        {/* P2 */}
                        <NormalBox style={{ overflow: "hidden", marginTop: "1rem" }}>
                            <PieChartVisitsRevenuePerUserP2 darkTheme={darkTheme} dashboardCurrency={dashboardCurrency} analyticsData={analyticsData} dashboardUsersAsDictionary={dashboardUsersAsDictionary} />
                        </NormalBox>


                    </DataBox>
                </WrapperGrid2>


                {/* Charts  */}
                {/* A1  */}
                <DataBox className={"onLayout HideScrollBar smallerGap"} style={{ overflow: "hidden" }}>
                    <h2 className='DefaultText text-bold'> {t("Analytics.visits_period", "Number of visits in a given period")} </h2>
                    <LineChartVisitsAmountPerTimeSpanA1 darkTheme={darkTheme} chartA1Data={chartA1Data} />
                </DataBox>

                {/* A2  */}
                <DataBox className={"onLayout HideScrollBar smallerGap"} style={{ overflow: "hidden" }}>
                    <h2 className='DefaultText text-bold'> {t("Analytics.visits_revenue_period", "Revenue from visits in a given period")} </h2>
                    <LineChartVisitsRevenuePerTimeSpanA2 darkTheme={darkTheme} dashboardCurrency={dashboardCurrency} chartA2Data={chartA2Data} />
                </DataBox>

            </PDFWrapperFlexCol >
        </div >

    );
}

// Wrapper that lets us donwload the page as pdf 
const PDFWrapperFlexCol = React.forwardRef(({ children, style }, ref) => (
    <div className={"WrapperFlexCol"} ref={ref} style={style}>
        {children}
    </div>
));

// Line chart: Amount of visits 
// Chart name: A1
const LineChartVisitsAmountPerTimeSpanA1 = memo(({ darkTheme, chartA1Data }) => {

    const color = "#3876fd"

    const options = {
        chart: {
            height: 350,
            type: "line",
            // animations: {
            //     enabled: false
            // }
        },
        theme: {
            mode: (darkTheme) ? "dark" : "light"
        },
        stroke: {
            curve: "smooth"
        },
        colors: [color],
        series: chartA1Data,
        xaxis: {
            labels: {
                show: true,
                rotateAlways: true,
                hideOverlappingLabels: true,
                style: {
                    colors: [],
                    fontSize: '10px',
                    fontFamily: 'sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: color
                },
                labels: {
                    style: {
                        colors: color
                    },
                    formatter: function (val) {
                        return val;
                    }
                },
            },
        ],
        tooltip: {
            shared: false,
            intersect: false,
        },
        legend: {
            horizontalAlign: "left",
            offsetX: 40
        }
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type={"line"} height={450} />
            <div id="html-dist"></div>
        </div>
    );
})

// Line chart: Visits revenue per time
// Chart name: A2
const LineChartVisitsRevenuePerTimeSpanA2 = memo(({ darkTheme, dashboardCurrency, chartA2Data }) => {

    const color = "#3876fd"

    const options = {
        chart: {
            height: 350,
            type: "line",
            // animations: {
            //     enabled: false
            // }
        },

        theme: {
            mode: (darkTheme) ? "dark" : "light"
        },

        stroke: {
            curve: "smooth"
        },
        colors: [color],
        series: chartA2Data,
        xaxis: {
            labels: {
                show: true,
                rotateAlways: true,
                hideOverlappingLabels: true,
                style: {
                    colors: [],
                    fontSize: '10px',
                    fontFamily: 'sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                    color: color
                },
                labels: {
                    style: {
                        colors: color
                    },
                    formatter: function (val) {
                        return val + ` ${(dashboardCurrency.Symbol) || ""}`
                    }
                },
            },
        ],
        tooltip: {
            shared: false,
            intersect: false,
        },
        legend: {
            horizontalAlign: "left",
            offsetX: 40
        }
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type={"line"} height={450} />
            <div id="html-dist"></div>
        </div>
    );
})


// Pie chart: Visits per user (worker)
// Chart name: P1
const PieChartVisitsPerUserP1 = memo(({ darkTheme, analyticsData, dashboardUsersAsDictionary }) => {


    // We turn counted by user {25: 3, 27: 3} user.id: amout of visits to array [3, 7]
    const visitsCountByUser_Series = Object.values(analyticsData.visits_count_by_user)
    const visitsCountByUser_Labels = Object.keys(analyticsData.visits_count_by_user).map((userId) => {
        return (dashboardUsersAsDictionary && dashboardUsersAsDictionary[userId]) ? dashboardUsersAsDictionary[userId].username : ""
    })


    const options = {
        series: visitsCountByUser_Series,

        labels: visitsCountByUser_Labels,

        theme: {
            mode: (darkTheme) ? "dark" : "light"
        },


        options: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type={"donut"} height={450} />
            <div id="html-dist"></div>
        </div>
    );
})

// Pie chart: Visits revenue per user (worker)
// Chart name: P2
const PieChartVisitsRevenuePerUserP2 = memo(({ darkTheme, dashboardCurrency, analyticsData, dashboardUsersAsDictionary }) => {


    const visitsCountByUser_Series = Object.values(analyticsData.visits_count_price_by_user)
    const visitsCountByUser_Labels = Object.keys(analyticsData.visits_count_price_by_user).map((userId) => {
        return (dashboardUsersAsDictionary && dashboardUsersAsDictionary[userId]) ? dashboardUsersAsDictionary[userId].username : ""
    })




    const options = {
        series: visitsCountByUser_Series,

        labels: visitsCountByUser_Labels,

        theme: {
            mode: (darkTheme) ? "dark" : "light"
        },

        options: {
            chart: {
                type: 'donut'
            },

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return value + ` ${(dashboardCurrency.Symbol || "")}`
                }
            }
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={options.series} type={"donut"} height={450} />
            <div id="html-dist"></div>
        </div>
    );
})




export default Analytics;
