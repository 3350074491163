import React, { useEffect, useRef, useState } from 'react'
import { useSharedDashboardContext } from '../../context/SharedDashboardDataContext';

export function SingleInput({ step, defaultValue, className, onFocus, name, disabled = false, required, style = {}, value = '', type = "text", onChange, label, min, placeholder, max, inputMode, pattern, maxLength }) {


    // This is helper that will check if user passed value higher or lower than max and min 
    // And will set value to max or min 
    if (type === 'number' && max && parseFloat(value) > max) {
        value = max
    }
    else if (type === 'number' && min && parseFloat(value) < min) {
        value = min
    }

    return (
        <div className="SingleInput" style={style}>

            <input
                type={type}
                value={value ?? ''}
                onChange={onChange}
                id={`input-basic-${label}`}
                disabled={disabled}
                autoComplete={label}
                {...(placeholder ? { placeholder } : {})}
                placeholder={placeholder}
                {...(className ? { className } : {})}
                {...(min ? { min } : {})}
                {...(max ? { max } : {})}
                {...(onFocus ? { onFocus } : {})}
                {...(name ? { name } : {})}
                {...(step ? { step } : {})}
                {...(maxLength ? { maxLength } : {})}
                {...(inputMode ? { inputMode } : {})}
                {...(pattern ? { pattern } : {})}
                {...(type === 'number' && pattern && { pattern: "[0-9]*", inputMode: "numeric" })}
                {...(required ? { required } : {})}
            />
        </div>
    );
}

export function CurrencyInput({ value, onChange, min = "0", max = "10000", step = "5", label = "Visit price", disabled = false, inputStyles = {}, style = {}, className }) {
    const [isFocused, setIsFocused] = useState(false);
    const currencyInputRef = useRef(null);

    const { dashboardCurrency } = useSharedDashboardContext()

    const handleFocus = () => {
        setIsFocused(true);
        // Lock scrolling on the page
        document.body.style.overflow = 'hidden';
    };

    const handleBlur = () => {
        setIsFocused(false);
        // Unlock scrolling on the page
        document.body.style.overflow = 'auto';
    };

    const handleClickOutside = (event) => {
        if (currencyInputRef.current && !currencyInputRef.current.contains(event.target)) {
            handleBlur();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={currencyInputRef} className={`CurrencyInput ${isFocused ? 'CurrencyInput-focused' : ''} ${(className !== undefined) ? className : ""}`} style={{ minWidth: "max-content", position: "relative", display: "inline-flex", alignItems: "center", ...style }}>
            <input
                type="number"
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                id={`input-currency-${label}`}
                disabled={disabled}
                autoComplete={label}
                style={{
                    flex: "1",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    borderRight: "unset",
                    ...inputStyles
                }}
                min={min}
                max={max}
                step={step}
                placeholder={label}
                pattern="[0-9]*"
                inputMode="numeric"
            />
            <span className="currency-input-block">{(dashboardCurrency.Symbol) || ""}</span>
        </div>
    );
}
// This is mainly used as textarea field for description or some long text 
export function TextFieldInput({ style = {}, type = "text", onChange, value = '', label, rows = 4, required }) {

    return (
        <div className="TextFieldInput" style={style}>
            <textarea
                type={type}
                value={value ?? ''}
                rows={rows}
                cols={50}
                onChange={onChange}
                id={`textfield-basic-${label}`}
                autoComplete={label}
                placeholder={(label) && label}
                {...(required ? { required } : {})}
            />
        </div>
    );
}

export function SearchInput({ value = '', onChange, placeholder = "", className, style = {} }) {
    return (
        <input
            className={`SearchInput ${(className !== undefined) ? className : ""}`}
            value={value ?? ''}
            onChange={onChange}
            id={`outlined-basic-search`}
            type="text"
            placeholder={(placeholder) && placeholder}
            style={style}
        />
    );
}