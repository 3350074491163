
// React
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// Styles
import classes from "./Pages_styles/CheckingHashLoadingPage.module.scss"

// (My) React elements
import { LoadingAnimationFourDots } from '../../../components/Elements/Wrappers'
import { ButtonPrimaryFill } from '../../../components/Elements/Buttons'


function CheckingHashLoadingPage() {

    const navigate = useNavigate()

    const { t } = useTranslation();

    function handleGoToHome() {
        // Dont change that, because it is navigation to leave the dashboard!
        navigate("/")
    }

    return (
        <div className={classes.CheckingHashLoadingPage}>

            <div className={classes.CheckingHashWrapper}>
                <div className={classes.Upper}>
                    <p className='DefaultText'> {t("loadingMessages.loading", "Loading..")} </p>
                    <LoadingAnimationFourDots />
                </div>

                <div className={classes.Lower}>
                    <p className='SmallText text-gray text-light'>{t("CheckingHashLoadingPage.loading_takes_too_much_time", "If loading takes too much time")}</p>

                    <ButtonPrimaryFill text={t("CheckingHashLoadingPage.go_back_to_home", "Go back to Home")} onClick={handleGoToHome} />
                </div>
            </div>


        </div>
    )
}

export default CheckingHashLoadingPage