// React
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Layout
import Layout from './Dashboard/Layout/Layout'

// Pages
import HomeDashboard from "./Dashboard/Pages/HomeDashboard"
import Calendar from "./Dashboard/Pages/Calendar"
import Notes from "./Dashboard/Pages/Notes"
import Clients from "./Dashboard/Pages/Clients"
import Analytics from "./Dashboard/Pages/Analytics"
import Settings from "./Dashboard/Pages/Settings"
import Client from './Dashboard/Pages/Client'
import DashboardNotFound from './Dashboard/Pages/DashboardNotFound'
import ControlPanel from './Dashboard/Pages/ControlPanel'
import Activities from './Dashboard/Pages/Activities'
import PriceList from './Dashboard/Pages/PriceList'

// Context
import { DashboardContextProvider, useDashboardContext } from '../context/DashboardContext'
import { SharedDashboardDataContextProvider } from '../context/SharedDashboardDataContext'




// Helper so we can use context properly
// Also it acts as a middleware s
function DashboardWrapper() {

    return (
        <DashboardContextProvider>
            {/* Other components */}
            <SharedDashboardDataContextProvider>
                <Dashboard />
            </SharedDashboardDataContextProvider>
            {/* Other components */}
        </DashboardContextProvider>
    );

}

function Dashboard() {

    const { dashboardHash, currentPath, setCurrentPath, currentDashboardMenu } = useDashboardContext();
    const navigate = useNavigate();


    useEffect(() => {

        // This is only for safety 
        // When user hits url like "/dashboard/<somehash>" or "/dashboard/<somehash>/" we will move hime to home 
        if (currentPath === "/dashboard/" + dashboardHash || currentPath === "/dashboard/" + dashboardHash + "/") {
            setCurrentPath("/dashboard/" + dashboardHash + "/home")
            navigate("/dashboard/" + dashboardHash + "/home")
        }

    })

    // This functions checks path, and returns releted component to that 
    function DisplayCorrectContent() {

        // We treat /home and "" as home, you can change that later dud
        if (currentPath === "/dashboard/" + dashboardHash + "/home") {
            return <HomeDashboard />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/calendar") {
            return <Calendar />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/price-list") {
            return <PriceList />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/notes") {
            return <Notes />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/clients") {
            return <Clients setCurrentPath={setCurrentPath} /> // needed so we can pass this to Client and to navigate to /dasboard/client/:client_id
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/analytics") {
            return <Analytics />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/control-panel") {
            return <ControlPanel />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/settings") {
            return <Settings />
        }
        else if (currentPath === "/dashboard/" + dashboardHash + "/activities") {
            return <Activities />
        }
        else if (currentPath.startsWith("/dashboard/" + dashboardHash + "/client")) {


            // Below this comment, we do some checks about URL so we can properlly displa CLient and find his id
            const parts = currentPath.split('/');

            if (parts.length === 4 && parts[3] === 'client') {
                // Block the URL '/dashboard/<hash>/client'
                console.log('Invalid URL');
                return <DashboardNotFound />
            } else if (parts.length === 5 && parts[3] === 'client' && !isNaN(parts[4])) {
                // Valid URL pattern '/dashboard/<hash>/client/<client_id>'
                const clientId = parseInt(parts[4]);
                return <Client client_id={clientId} />;
            } else {
                console.log("Client page not found")
                return <DashboardNotFound />
            }
        }
        else {
            return <DashboardNotFound />
        }


    }

    return (

        <Layout currentDashboardMenu={currentDashboardMenu} setCurrentPath={setCurrentPath} dashboardHash={dashboardHash} currentPath={currentPath}>

            <DisplayCorrectContent />

        </Layout>
    )
}

export default DashboardWrapper