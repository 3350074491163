

// Function to set an object in local storage by name
export function setObjectInLocalStorage(name, obj) {
    try {
        const objJson = JSON.stringify(obj);
        localStorage.setItem(name, objJson);
        return true
    } catch (error) {
        console.error(`Error setting object ${name} in local storage:`, error);
        return false
    }
}

// Function to get and parse an object from local storage by name
export function getObjectFromLocalStorage(name) {
    try {
        const objJson = localStorage.getItem(name);
        return objJson ? JSON.parse(objJson) : null;
    } catch (error) {
        console.error(`Error getting object ${name} from local storage:`, error);
        return null;
    }
}

// Function to set a value in localStorage
export const setLocalStorageItem = (itemName, itemValue) => {
    try {
        localStorage.setItem(itemName, JSON.stringify(itemValue));
    } catch (error) {
        console.error(`Error setting ${itemName} in localStorage: ${error.message}`);
    }
};

// Function to get a value from localStorage
export const getLocalStorageItem = (itemName) => {
    try {
        const storedItem = localStorage.getItem(itemName);
        return storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error(`Error getting ${itemName} from localStorage: ${error.message}`);
        return null;
    }
};