import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common.js"
import { getRandomIndexInJson } from "../helper/funcs";
import user_avatars from "../data/avatar_icons.json"

// # USER AUTH STUFF #

// Update user profile
export async function API_UpdateUserProfileBasic(token, user) {
    const URL = `${CURRENT_USING_URL}/user-profile-basic/update`;

    console.log("\n[API_UpdateUserProfileBasic]\nTrying to update user \nCalling: ", URL)
    console.log("[API_UpdateUserProfileBasic]\nNew user data is:\n", user)

    try {

        const response = await fetch(URL, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(user)
        });


        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_UpdateUserProfileBasic]\nUser updated! \n", response_json)
            return { isOk: true, response: response }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_UpdateUserProfileBasic]\nSomething went wrong with the response: ", response)
            console.log("\n[API_UpdateUserProfileBasic]\nResponse json: \n", response_json)
            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    } catch (error) {
        console.log("\n[API_UpdateUserProfileBasic]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_UpdateUserProfilePassword(token, passwords) {
    const URL = `${CURRENT_USING_URL}/user-profile-password/update`;

    // passwords MUST be: 
    // passwords = {
    //     "currentPassword" : "<str>",
    //     "newPassword" : "<str>",
    //     "repeatPassword" : "<str>",
    // }

    console.log("\n[API_UpdateUserProfilePassword]\nTrying to update user password \nCalling: ", URL)
    console.log("[API_UpdateUserProfilePassword]\nNew password data is:\n", passwords)

    try {

        const response = await fetch(URL, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(passwords)
        });


        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_UpdateUserProfilePassword]\nUser updated! \n", response_json)
            return { isOk: true, response: response }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_UpdateUserProfilePassword]\nSomething went wrong with the response: ", response)
            console.log("\n[API_UpdateUserProfilePassword]\nResponse json: \n", response_json)
            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    } catch (error) {
        console.log("\n[API_UpdateUserProfilePassword]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// User Registration
export async function API_RegisterNewUser(new_user, t) {

    /*
        How new_user should look like
        
        new_user = {
            "email": "test@test.pl",
            "username": "test123",
            "password": "test123"
        }

        Registers new user to db
        We return isOk information back to client to know about the operation!
        We also want to return errors as array of strings: 
        errors = ['error1', 'error2']
    */


    const URL = `${CURRENT_USING_URL}/auth/register/`;

    console.log("\n[API_RegisterNewUser]\nTrying to add new user.. \nCalling: ", URL)
    console.log("\n[API_RegisterNewUser]\nPassing user data: \n ", new_user)

    try {

        // Here we assign default user avatar
        new_user["avatar"] = user_avatars[getRandomIndexInJson(user_avatars)].avatar_name

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(new_user),
        });


        // User added correctly!
        if (response.status === 201 || response.status === 200) {
            console.log("\n[API_RegisterNewUser]\nUser created successfully!")
            return { isOk: true }
        }
        else {

            // Return with SERVER SIDE errors
            const response_json = await response.json();

            console.log("\n[API_RegisterNewUser]\nSomething went wrong with the response: ", response)
            console.log("\n[API_RegisterNewUser]\nResponse json: \n", response_json)

            // Here we are manualy translating errors from django 
            if (response_json && response_json.error) {

                // Email already exists 
                if (response_json.error.includes("(ua_e01)")) {
                    response_json.error = t("validationErrors.email_exists", "This email already exists") + " (ua_e01)"
                }

                // Username already exists 
                if (response_json.error.includes("(ua_u01)")) {
                    response_json.error = t("validationErrors.username_already_exists", "This username already exists") + " (ua_u01)"
                }
            }

            // This is needed because we gather errors like [aaa, bbb]
            // And server 'response_json' is returning like {error: [...]}
            let server_errors = Object.values(response_json)
            server_errors = [].concat(...server_errors);

            console.log("\n[API_RegisterNewUser]\nServer_errors: \n", server_errors)

            let objReturn = { errors: server_errors, isOk: false }

            return objReturn
        }
    }

    catch (error) {
        // If we are here, means server might be offline
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}

// User Login
export async function API_GetUserToken(email, password, t) {
    /*
       user = {
           "username": "test1234",
           "password": "test1234"
       }

       Get users token
   */

    const URL = `${CURRENT_USING_URL}/auth/token/`;

    console.log("\n[API_GetUserToken]\nTrying to get user token.. \nCalling: ", URL)


    const user = {
        "email": email,
        "password": password
    }

    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });

        if (response && response.ok) {
            const response_json = await response.json();
            console.log("\n[API_GetUserToken]\nToken captured successfully.\nToken:", response_json)
            return response_json;
        } else if (response) {
            const response_json = await response.json();

            if (response_json.detail && response_json.detail.includes("No active account")) {
                response_json.detail = t("Login.no_active_account_found", "No active account found with the given credentials") + " (ua_a01)"
            }

            console.log("\n[API_GetUserToken]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetUserToken]\nResponse json: \n", response_json)
            return response_json
        }
    } catch (error) {
        // If we are here, means server might be offline
        console.error('Error fetching data:', error);
        return Promise.reject({ isOk: false, error_message: server_off_message }); // Reject the promise with the error
        // This is currently the best fix, without it, when login in and backend was off, we got some errors!
    }
}

// Get user with token 
export async function API_GetUserFromToken(token) {
    /*
      token = "askjhdbaskjhdbas"

       Get user details from token
   */

    const URL = `${CURRENT_USING_URL}/auth/user/`;

    console.log("\n[API_GetUserFromToken]\nTrying to get user with token.. \nCalling: ", URL)


    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetUserFromToken]\nUser found in database!")
            const response_json = await response.json();
            console.log("\n[API_GetUserFromToken]\nUser response_json data: \n", response_json)
            return response_json
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetUserFromToken]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetUserFromToken]\nResponse json: \n", response_json)
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}

// Get user new access token using refresh token!
export async function API_GetUserNewToken(refresh_token) {
    /*
        token = "askjhdbaskjhdbas"

        Get users access_token when it is expired
   */

    const URL = `${CURRENT_USING_URL}/auth/token/refresh/`;

    console.log("\n[API_GetUserNewToken]\nTrying to get new user with token using refresh token.. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "refresh": refresh_token }),
        });


        if (response.ok) {
            console.log("\n[API_GetUserNewToken]\nNew token generated!")
            const response_json = await response.json();

            console.log("\n[API_GetUserNewToken]\nResponse json: \n", response_json)
            return response_json
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetUserNewToken]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetUserNewToken]\nResponse json: \n", response_json)

        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}


// Logout token
export async function API_LogoutUser(access_token) {
    /*
      token = "askjhdbaskjhdbas"

       Get user details from token
   */

    const URL = `${CURRENT_USING_URL}/logout/`;

    console.log("\n[API_LogoutUser]\nTrying to logout user with token.. \nCalling: ", URL)


    try {

        const response = await fetch(URL, {
            method: "POST", // Use POST method to perform a logout
            headers: {
                'Content-Type': 'application/json',
                // You may or may not need the Authorization header for logout
                'Authorization': `Bearer ${access_token}`
            }
        });


        if (response.ok) {
            console.log("\n[API_LogoutUser]\nUser logged out successfully!")
            const response_json = await response.json();
            console.log("\n[API_LogoutUser]\nUser response_json data: \n", response_json)
            return response_json
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_LogoutUser]\nSomething went wrong with the response: ", response)
            console.log("\n[API_LogoutUser]\nResponse json: \n", response_json)
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}
