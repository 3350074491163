

/* 
Here we put all the small functions 

Like for sorting, randomizing .. 
*/

// For deciding if we have to put white or black color on some background 
// Used in tags
export const getContrastColor = (hexColor) => {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate contrast ratio
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose white or black based on luminance
    return luminance > 0.5 ? "#000000" : "#ffffff";
};

// Input 'home' -> returns "Home"
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}



// Gets phone number, if its in format XXXXXXXXX it returns XXX-XXX-XXX else it return as it is 
export function phoneFormatter(phone) {

    // Remove all non-numeric characters
    const numericOnly = phone.replace(/\D/g, '');

    // Insert hyphens at the appropriate positions
    const formattedPhoneNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');

    return formattedPhoneNumber;
}



// It reformats phone from xxx-xxx-xxx or xxx xxx xxx to xxxxxxxxx
export function phoneReformatter(formattedPhone) {
    const reformattedString = formattedPhone.replace(/[-\s]/g, '');
    return reformattedString
}

// This is awesome, it creates for you time intervals that you can use when user has to select some time stuff 
// for example we can display visit lenght by using this 
// it returns array like [10:00, 10:30, 11:00, 11:30]
export function createTimeIntervals(startHour, stopHour, stepSize) {
    const timeIntervals = [];

    for (let hour = startHour; hour <= stopHour; hour++) {
        for (let minute = 0; minute < 60; minute += stepSize) {
            // We add all but not nothing that starts with 00
            if (!(hour === 0)) {
                const hourString = hour.toString().padStart(2, '0');
                const minuteString = minute.toString().padStart(2, '0');
                timeIntervals.push({ value: `${hourString}:${minuteString}`, label: `${hourString}:${minuteString}` });
            }
        }
    }

    return timeIntervals;
}

// Returns random index from data like json [{}, {}]
export function getRandomIndexInJson(jsonData) {
    if (Array.isArray(jsonData)) {
        const randomIndex = Math.floor(Math.random() * jsonData.length);
        return randomIndex;
    } else {
        return -1; // Return -1 for non-array input
    }
}


// From #123123, (0,1) to -> 'rgba(12, 33, 44, 0.5)'
export function hexToRgba(hex, alpha) {
    // Remove the hash sign if it's present
    hex = hex.replace(/^#/, '');

    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Ensure alpha is in the range [0, 1]
    const a = Math.min(1, Math.max(0, alpha));

    // Return the RGBA value
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

// This returns "08:00 - 08:30" out of our visit data 
export function formatTimeRange(start_hour, start_minute, length) {
    if (start_hour !== null && start_minute !== null && length !== null) {
        // Helper function to format hours and minutes to a 2-digit format
        const formatTwoDigits = (num) => num.toString().padStart(2, '0');

        // Create a Date object for the start time
        const startTime = new Date();
        startTime.setHours(parseInt(start_hour, 10), parseInt(start_minute, 10), 0, 0);

        // Calculate the end time by adding the length in minutes
        const endTime = new Date(startTime);
        endTime.setMinutes(startTime.getMinutes() + length);

        // Format start and end times to "HH:MM" format
        const formattedStartTime = `${formatTwoDigits(startTime.getHours())}:${formatTwoDigits(startTime.getMinutes())}`;
        const formattedEndTime = `${formatTwoDigits(endTime.getHours())}:${formatTwoDigits(endTime.getMinutes())}`;

        // Return the combined string
        return `${formattedStartTime} - ${formattedEndTime}`;
    } else {
        return "";
    }
}
