import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";

// Styles  
import classes from "./NavbarHero.module.scss"

// Icons / Png
import { ReactComponent as Logo } from "../../../static/n_icons/Logo.svg"
import { ReactComponent as UserProfileIcon } from "../../../static/n_icons/UserEdit.svg"
import { ReactComponent as LogoutIcon } from "../../../static/n_icons/Go_out.svg"
import { ReactComponent as ErrorDiamondIcon } from "../../../static/n_icons/ErrorDiamond.svg"
import { ReactComponent as DarkThemeIcon } from "../../../static/n_icons/Moon.svg"
// import { ReactComponent as IconCalendar } from "../../static/icons/Calendar-done.svg"

// Material Ui stuff
// import Button from '@mui/material/Button';
// import Stack from '@mui/material/Stack';
// import muStyles from "../../styles/mu-styles.module.scss"

import { ButtonPrimaryFill, ButtonPrimaryOutlined, ToggleButton } from "../../../components/Elements/Buttons"

// App Context
import { useUserContext } from '../../../context/UserAuth';
import { useTheme } from '../../../context/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { DropdownContent, WrapperFlexRow } from '../../../components/Elements/Wrappers';


// Public files
import paths from "../../../data/paths.json"
import { setLocalStorageItem } from '../../../helper/local_storage';



// Description:
// This is navbar for hero / page section only!
// We have separate navbar for our inside app (panel has different menu)

function Navbar_Hero() {

    // Stores path to avatar images in public folder 
    // "/user_avatars/"
    const path_to_avatars = paths.path_to_avatar_images;


    const { t } = useTranslation();


    const { toggleTheme, darkTheme } = useTheme()


    const [toggleBurger, setToggleBurger] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { logoutUser, isLogged, currentUser } = useUserContext();

    const navigate = useNavigate();

    // Change current page with "/route" 
    const handleNavigation = (route) => {
        navigate(route);
    };

    // Toggle menu when in mobile view
    function handleToggleBurger() {
        setToggleBurger(!toggleBurger);
    }


    // Handle dark mode toggle from menu 
    function handleToggleDarkMode() {
        setLocalStorageItem('dark_mode', !darkTheme)
        toggleTheme()
    }



    // This useEffect checks is we clicked out side of menu bar (on mobile) so it will be closed
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Check if the click is outside the navbar and not the burger icon
            if (
                toggleBurger &&
                !event.target.closest('.navbar-menu') &&
                !event.target.closest('.navbar-burger')
            ) {
                setToggleBurger(false);
            }
        };

        // Listen for clicks on the document
        document.addEventListener('click', handleOutsideClick);

        // Clean up the event listener when component is unmounted
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [toggleBurger]);

    return (

        <nav className={"navbar is-fixed-top " + classes.Navbar} role="navigation" aria-label="main navigation">

            <div className='container'>

                <div className={"navbar-brand " + classes.NavbarBrand}>

                    {/* Logo  */}
                    <Logo className={classes.NavbarLogo} />

                    {/* Burger menu (on mobile) */}
                    <p onClick={handleToggleBurger} role="button" className={"navbar-burger " + classes.NavbarBurger + " " + ((toggleBurger) ? "is-active" : "")} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </p>
                </div>

                <div id="navbarBasicExample" className={"navbar-menu HideScrollbarBigTablet  " + classes.NavbarMenu + ((toggleBurger) ? " is-active" : null)}>

                    {/* Menu items on left side */}
                    <div className={classes.NavbarStart}>

                        <Link to={"/dashboards"} className={classes.NavbarItem + " navbarLink DefaultText text_light_bold"}>
                            {t("HERO_Navbar.dashboards", "Dashboards")}
                        </Link>

                        <Link to={"/discover-annualty"} className={classes.NavbarItem + " navbarLink DefaultText text_light_bold"}>
                            {t("HERO_Navbar.learn_annualty", "Discover Annualty")}
                        </Link>

                    </div>

                    {/* Menu List right side (Login / Logout / Signin stuff)  */}
                    <div className={"navbar-end " + classes.NavbarEnd}>

                        <div className={classes.NavbarEndItem}>

                            <div className={"buttons " + classes.Buttons}>


                                {(isLogged && Object.keys(currentUser).length > 0)
                                    ?
                                    <WrapperFlexRow
                                        style={{
                                            position: "relative"
                                        }}
                                        onMouseEnter={() => setIsDropdownOpen(true)}
                                        onMouseLeave={() => setIsDropdownOpen(false)}
                                        className={`${classes.UserProfileBox} ${isDropdownOpen ? classes.UserProfileBoxActive : ''} `}
                                    >

                                        <p className='DefaultText text-bold text-capitalize'> {currentUser.username} </p>
                                        <img className={"UserImage"} src={path_to_avatars + currentUser.avatar} alt="" />

                                        <DropdownContent className={`${isDropdownOpen ? 'show' : ''} ShowContentOnBigTablet`} stickTo='Right'>

                                            <Link to={'/user-settings'} className='WrapperFlexRow borderRadius smallPadding isHoverableOnWhite'>
                                                <UserProfileIcon className={"SVG_STROKE"} />
                                                <p className='SmallText text_light_bold text_no_wrap'> {t("HERO_Navbar.user_profile", "User profile")} </p>
                                            </Link>

                                            {/* Toggle dark theme */}
                                            <WrapperFlexRow className={"borderRadius smallPadding"}>
                                                <WrapperFlexRow style={{ padding: "0" }}>
                                                    <DarkThemeIcon className={"SVG_STROKE"} />
                                                    <p className='SmallText text_light_bold text_no_wrap'> {t("HERO_Navbar.dark_mode", "Dark mode")} </p>
                                                </WrapperFlexRow>
                                                <ToggleButton onChange={handleToggleDarkMode} isChecked={darkTheme} />
                                            </WrapperFlexRow>

                                            <hr className='myHr' style={{ margin: "0.5rem 0rem" }} />

                                            <Link to={'/report-a-bug'} className='WrapperFlexRow borderRadius smallPadding isHoverableOnWhite_error'>
                                                <ErrorDiamondIcon className={'SVG_STROKE_ERROR'} />
                                                <p className='SmallText text_light_bold text_no_wrap text_error'> {t("HERO_Navbar.report_a_bug", "Report a bug")}  </p>
                                            </Link>

                                            <Link onClick={logoutUser} className='WrapperFlexRow borderRadius smallPadding isHoverableOnWhite_error'>
                                                <LogoutIcon className={'SVG_STROKE_ERROR'} />
                                                <p className='SmallText text_light_bold text_no_wrap text_error'> {t("HERO_Navbar.logout", "Log out")}  </p>
                                            </Link>


                                        </DropdownContent>
                                    </WrapperFlexRow>
                                    :
                                    <>
                                        <ButtonPrimaryOutlined onClick={() => { handleNavigation("/login") }} text={t("HERO_Navbar.login", "Log in")} />
                                        <ButtonPrimaryFill onClick={() => { handleNavigation("/signin") }} text={t("HERO_Navbar.sign_in", "Sign in")} />
                                    </>
                                }

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </nav >
    )
}




export default Navbar_Hero