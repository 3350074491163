import React from 'react'
import LayoutHero from '../Entrance/LayoutHero/LayoutHero'



// If we are here, that means that user successfuly paid for our product, 
// From here, we have to make sure that everything is correct and update proper stuff in our database 

function Payment_Success() {


    // Here, we are sure that we get csid (CHECKOUT_SESSION_ID) from backend set to something so we get this from url 
    const params = new URLSearchParams(window.location.search);

    const csid = params.get("csid") || undefined



    return (



        <LayoutHero>


            <div className="container ">

                <p className='DefaultText'> One moment, we are validating your payment </p>

            </div>

        </LayoutHero>



    )
}

export default Payment_Success