import React from 'react'
import { useTranslation } from 'react-i18next';

const gap_from_icon = 0.5


// Label that has left icon and on right end of width also has icon Like scroll 
export function LabelWithLeftIconAndScrollIcon({ LeftIcon, RightIcon, LabelText, LabelBold, style = {} }) {

    return (
        <div className="LabelWithLeftIconAndScrollIcon" style={style}>
            <div style={{ display: "flex", gap: String(gap_from_icon) + "rem", alignItems: "center" }}>
                {LeftIcon && <LeftIcon className="SVG_STROKE" />}
                <p className='DefaultText text_overflow_hidden' style={{ fontWeight: "400" }}> {LabelText} <b>{LabelBold}</b> </p>
            </div>
            {RightIcon && <RightIcon className="SVG_STROKE" />}
        </div>
    )
}

// Label that has left icon and descibe TWO inputs (so we have to labelBold texts)
export function LabelLeftIconTwoBoldsTexts({ LeftIcon, LabelText, LabelBold1, LabelBold2, style = {}, LabelAfterBold = "" }) {

    const { t } = useTranslation();

    return (
        <div className="LabelLeftIconTwoBoldsTexts" style={style}>
            {LeftIcon && <LeftIcon className="SVG_STROKE" />}
            <p className='DefaultText text_overflow_hidden' style={{ fontWeight: "400" }}> {LabelText} <b>{LabelBold1}</b> {t("Common.and", "and")} <b>{LabelBold2}</b> {LabelAfterBold}</p>
        </div>
    )
}

export function LabelLeftIconThreeBoldsTexts({ LeftIcon, LabelText, LabelBold1, LabelBold2, LabelBold3, style = {}, LabelAfterBold = "" }) {

    const { t } = useTranslation();

    return (
        <div className="LabelLeftIconTwoBoldsTexts" style={style}>
            {LeftIcon && <LeftIcon className="SVG_STROKE" />}
            <p className='SmallText'> {LabelText} <b>{LabelBold1}</b>, <b>{LabelBold2}</b> {t("Common.and", "and")}  <b>{LabelBold3}</b> {LabelAfterBold}</p>
        </div>
    )
}

export function LabelLeftSingleIcon({ LeftIcon, LabelText, LabelBold, style = {}, LabelAfterBold = "" }) {
    return (
        <div className="LabelLeftSingleIcon" style={style}>
            {LeftIcon && <LeftIcon className="SVG_STROKE" />}
            <p className='DefaultText text_overflow_hidden' style={{ fontWeight: "400" }}>
                {LabelText} <b>{LabelBold}</b> {LabelAfterBold}
            </p>
        </div>
    );
}
