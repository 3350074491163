
// ========== USERNAME VALIDATION ==========

// A-z
// 0-9 
// No spaces or any symbols 
// (sv-u01)
function isUsernameProperCharacters(username, t) {
    // Define a regular expression pattern for alphanumeric characters and underscores
    const pattern = /^[a-zA-Z0-9_]+$/;

    // Test if the username matches the pattern
    const isProper = pattern.test(username);

    if (!isProper) {
        // If the username does not match the pattern, return an error message
        return [t("validationErrors.username_invalid_characters", "Invalid characters in the username. Only alphanumeric characters and underscores are allowed.") + " (sv-u01)"];
    }

    // If the username passes the validation, return null (indicating no error)
    return [];
}


// Check if first char in username is a-z not 1-9 
// (sv-u04)
function isUsernameStartingWithChar(username, t) {
    const pattern = /^[A-Z]/i;

    // Test if the first character of the username matches the pattern
    const isStartingWithChar = pattern.test(username.charAt(0));

    if (!isStartingWithChar) {
        // If the username does not start with a character from A-Z, return an error message
        return [t("validationErrors.invalid_starting_character", "Username must start with a letter (A-Z).") + " (sv-u04)"];
    }

    // If the username starts with a character from A-Z, return null (indicating no error)
    return [];
}

// Is username in proper length
// (sv-u02)
// (sv-u03)
function isUsernameInProperLength(username, t) {
    let errors = []

    if (username.length > 20) {
        errors.push(t("validationErrors.username_too_long", "Username cannot be longer than 20 characters!") + " (sv-u02)")
    }

    if (username.length < 3) {
        errors.push(t("validationErrors.username_too_short", "Username has to have at least 3 characters!") + " (sv-u03)")
    }

    return errors
}

// USERNAME START 
// We check everything about username and return array of errors translated
// If no errors then we return -1
export function isUsernameValid(username, t) {
    let errors = []

    errors.push(...isUsernameInProperLength(username, t))
    errors.push(...isUsernameProperCharacters(username, t))
    errors.push(...isUsernameStartingWithChar(username, t))

    return errors;
}









// ========== PASSWORD VALIDATION ==========
// (sv-p01)
// (sv-p02)
function isPasswordInProperLength(password, t) {
    let errors = []

    if (password.length > 100) {
        errors.push(t("validationErrors.password_too_long", "Password is too long!") + " (sv-p01)")
    }

    if (password.length < 8) {
        errors.push(t("validationErrors.password_too_short", "Password has to have atleast 8 characters!") + " (sv-p02)")
    }

    return errors
}

// (sv-p03)
function isPasswordProperCharacters(password, t) {
    // Define a regular expression pattern for a valid password
    const pattern = /^[a-zA-Z0-9!@#$%^&*]+$/;

    // Test if the password matches the pattern
    const isValid = pattern.test(password);

    if (!isValid) {
        // If the password does not match the pattern, return an error message
        return [t("validationErrors.password_invalid_characters", "Invalid characters in the password. Use only alphanumeric characters and the symbols !@#$%^&*.") + " (sv-p03)"];
    }

    // If the password passes the validation, return null (indicating no error)
    return [];
}

export function isPasswordValid(password, t) {
    let errors = []

    errors.push(...isPasswordInProperLength(password, t))
    errors.push(...isPasswordProperCharacters(password, t))

    return errors;
}









// ========== EMAIL VALIDATION ==========

// (sv-e01)
// (sv-e02)
function isEmailPatternValid(email, t) {
    let errors = []

    // Define a regular expression pattern for a valid email address
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test if the email matches the pattern
    const isValidFormat = pattern.test(email);

    // Check if the email length is within the allowed range (adjust min and max values as needed)
    const isValidLength = email.length >= 5 && email.length <= 255;


    if (!isValidFormat) {
        errors.push(t("validationErrors.invalid_email_format", "Email has the wrong format!") + " (sv-e01)");
    }
    if (!isValidLength) {
        errors.push(t("validationErrors.invalid_email_length", "Email must be between 5 and 255 characters long.") + " (sv-e02)");
    }

    return errors;
}


export function isEmailValid(email, t) {
    let errors = []

    errors.push(...isEmailPatternValid(email, t))

    return errors;

}
