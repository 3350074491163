import { useEffect, useState } from "react"
import "./Elements_styles/JumpingPopup.scss"







export function JumpingPopup({ message = "", clearState, clearInterval = 3000 }) {
    const [visible, setVisible] = useState(true);

    // Function to hide the popup after a certain interval
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            // After hiding the popup, clear the state
            // clearState(""); // Commented out to trigger slideUp animation first
        }, clearInterval);

        // Clear the timer when the component unmounts or when clearInterval changes
        return () => clearTimeout(timer);
    }, [clearState, clearInterval]);

    // Function to handle the animation classes
    const containerClassName = visible ? "JumpingPopup_Container slideDown" : "JumpingPopup_Container slideUp";

    // Function to trigger slideUp animation before hiding the popup
    useEffect(() => {
        if (!visible) {
            const slideUpTimer = setTimeout(() => {
                // After a delay, clear the state to hide the popup
                clearState("");
            }, 500); // Adjust the delay time as needed
            return () => clearTimeout(slideUpTimer);
        }
    }, [visible, clearState]);

    return (
        <div className={containerClassName}>
            <div className="JumpingPopup">
                <p className="SmallText text_white text_light_bold">{message}</p>
            </div>
        </div>
    );
}