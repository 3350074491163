import React from 'react'
import { useTheme } from './context/ThemeProvider'


// This is simple app wrapper
// Here, we mainly change Theme! 

function App({ children }) {

    const { themeClassName } = useTheme()

    return (
        <main className={`${themeClassName}`}>
            {children}
        </main>
    )
}

export default App