



import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";

export async function API_CreateNewVisit(token, visit) {
    const URL = `${CURRENT_USING_URL}/visits/create-visit-to-dashboard/`;

    console.log("\n[API_CreateNewVisit]\nTrying to add new visit.. \nCalling: ", URL)
    console.log("[API_CreateNewVisit]\nNew visit data is:\n", visit)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(visit),
        });


        if (response.ok) {
            console.log("\n[API_CreateNewVisit]\nNew visit added!")
            const response_json = await response.json();
            console.log(response_json);
            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();

            console.log("\n[API_CreateNewVisit]\nSomething went wrong with the response: ", response)
            console.log("\n[API_CreateNewVisit]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_CreateNewVisit]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_GetVisitsByMonthAndYear(token, dashboard_id, year, month) {
    const URL = `${CURRENT_USING_URL}/visits/get-current-month-visits/${String(dashboard_id)}/?year=${year}&month=${month}`;

    console.log("\n[API_GetVisitsByMonthAndYear]\nTrying to get visits by months and year.. \nCalling: ", URL)
    console.log("Dashboard id:", dashboard_id)
    console.log("Getting visits for: Month", month, "Year", year)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetVisitsByMonthAndYear]\nVisits found!")

            const response_json = await response.json();

            console.log("\n[API_GetVisitsByMonthAndYear]\nFetched visits array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetVisitsByMonthAndYear]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetVisitsByMonthAndYear]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetVisitsByMonthAndYear]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// PAGINATION - Cursor. Recieve upcoming visits 
export async function API_GetUpcomingVisitsByClientId(token, dashboard_id, client_id, cursor) {

    // We have to delete cursor if its null (if we fetch first time to recieve it)
    let URL = `${CURRENT_USING_URL}/visits/get-upcoming-visits-by-client-id/${client_id}/${dashboard_id}`;

    if (cursor) {
        URL = `${CURRENT_USING_URL}/visits/get-upcoming-visits-by-client-id/${client_id}/${dashboard_id}?cursor=${cursor}`;
    }


    console.log("\n[API_GetUpcomingVisitsByClientId]\nTrying to get upcoming visits for client.. \nCalling: ", URL)
    console.log("Dashboard id:", dashboard_id)
    console.log("Client id:", client_id, "Cursor = ", cursor)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetUpcomingVisitsByClientId]\nVisits found!")

            const response_json = await response.json();

            console.log("\n[API_GetUpcomingVisitsByClientId]\nFetched visits array: \n", response_json)

            return { isOk: true, response: response, items: (response_json.results) ? response_json.results : [], next: response_json.next }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetUpcomingVisitsByClientId]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetUpcomingVisitsByClientId]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetUpcomingVisitsByClientId]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// PAGINATION - Cursor. Recieve history visits 
export async function API_GetHistoryVisitsByClientId(token, dashboard_id, client_id, cursor) {
    let URL = `${CURRENT_USING_URL}/visits/get-history-visits-by-client-id/${client_id}/${dashboard_id}`;

    if (cursor) {
        URL = `${CURRENT_USING_URL}/visits/get-history-visits-by-client-id/${client_id}/${dashboard_id}?cursor=${cursor}`;
    }

    console.log("\n[API_GetHistoryVisitsByClientId]\nTrying to get history visits for client.. \nCalling: ", URL)
    console.log("Dashboard id:", dashboard_id)
    console.log("Client id:", client_id, "Cursor = ", cursor)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetHistoryVisitsByClientId]\nVisits found!")

            const response_json = await response.json();

            console.log("\n[API_GetHistoryVisitsByClientId]\nFetched visits array: \n", response_json)

            return { isOk: true, response: response, items: (response_json.results) ? response_json.results : [], next: response_json.next }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetHistoryVisitsByClientId]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetHistoryVisitsByClientId]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetHistoryVisitsByClientId]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_ToggleIsCheckedByOwner(token, visit_id) {
    const URL = `${CURRENT_USING_URL}/visits/toggle-is-checked-by-owner/${String(visit_id)}/`;

    console.log("\n[API_ToggleIsCheckedByOwner]\nTrying to visit isCheckedByOwner by id.. \nCalling: ", URL)
    console.log(`Id: ${visit_id}`)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {

            const response_json = await response.json();
            console.log("\n[API_ToggleIsCheckedByOwner]\nToggle success!")
            console.log("\n[API_ToggleIsCheckedByOwner]\nUpdated price_list: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_ToggleIsCheckedByOwner]\nSomething went wrong with the response: ", response)
            console.log("\n[API_ToggleIsCheckedByOwner]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_ToggleIsCheckedByOwner]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_DeleteVisit(token, visit_id) {
    const URL = `${CURRENT_USING_URL}/visits/${String(visit_id)}/delete/`;


    console.log("\n[API_DeleteVisit]\nTrying to delete visit by id.. \nCalling: ", URL)
    console.log("[API_DeleteVisit]\nVisit id is:", visit_id)

    try {

        const response = await fetch(URL, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        if (response.ok) {
            // successfully deleted
            console.log("\n[API_DeleteVisit]\nVisit deleted correctly!")
            return { isOk: true, response: response }
        }
        else {
            // something went wrong 
            console.log("\n[API_DeleteVisit]\nCound not delete visit!\nResponse:", response)
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }


    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_DeleteVisit]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }

    }
}


export async function API_EditVisit(token, visit, visit_id) {
    const URL = `${CURRENT_USING_URL}/visits/${String(visit_id)}/update/`;

    console.log("\n[API_EditVisit]\nTrying to update visit.. \nCalling: ", URL)
    console.log("[API_EditVisit]\nNew visit data is:\n", visit)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(visit),
        });


        if (response.ok) {
            console.log("\n[API_EditVisit]\nVisit updated!")
            const response_json = await response.json();
            console.log(response_json);
            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();

            console.log("\n[API_EditVisit]\nSomething went wrong with the response: ", response)
            console.log("\n[API_EditVisit]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_EditVisit]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}
