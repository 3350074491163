
// We are using it in settings.js so we can print day of week translated and not change any functionality with it
export function translateDayOfWeek(day, t) {
    const days = {
        monday: t("daysOfWeek.monday", "Monday"),
        tuesday: t("daysOfWeek.tuesday", "Tuesday"),
        wednesday: t("daysOfWeek.wednesday", "Wednesday"),
        thursday: t("daysOfWeek.thursday", "Thursday"),
        friday: t("daysOfWeek.friday", "Friday"),
        saturday: t("daysOfWeek.saturday", "Saturday"),
        sunday: t("daysOfWeek.sunday", "Sunday"),
    };

    return days[day] || day;
}

export function translateMonth(month, t) {
    const months = {
        january: t("months.january", "January"),
        february: t("months.february", "February"),
        march: t("months.march", "March"),
        april: t("months.april", "April"),
        may: t("months.may", "May"),
        june: t("months.june", "June"),
        july: t("months.july", "July"),
        august: t("months.august", "August"),
        september: t("months.september", "September"),
        october: t("months.october", "October"),
        november: t("months.november", "November"),
        december: t("months.december", "December"),
    };

    return months[month] || month;
}

// In Calendar.js where we display Single Select Buttons ('day', 'month')
export function translateCalendarTimeSpan(timeSpan, t) {


    const timeSpans = {
        "day": t("Calendar.day", "Day"),
        "month": t("Calendar.month", "Month")
    }

    return timeSpans[timeSpan] || timeSpan
}


// Used in Control Panel 
export function translateInvitationStatus(status, t) {
    const invitesStatuses = {
        Sent: t("ControlPanel.invitation_status.sent", "Sent"),
        Accepted: t("ControlPanel.invitation_status.accepted", "Accepted"),
        Declined: t("ControlPanel.invitation_status.declined", "Declined"),
    };

    return invitesStatuses[status] || status;
}



// They have to match same permissions like possiblePermissions in _UserPermissions.js
export function translateUserPermissions(permission, t) {

    const permissions = {
        "Manager": t("ControlPanel.user_permissions.manager", "Manager"),
        "Employee": t("ControlPanel.user_permissions.employee", "Employee"),
        "Guest": t("ControlPanel.user_permissions.guest", "Guest"),
        "Owner": t("ControlPanel.user_permissions.owner", "Owner")
    }

    return permissions[permission] || permission;
}




// Used for Displaying dashboard status 
export function translateDashboardStatus(status, t) {

    const statuses = {
        "Free": t("Dashboards.Dashboard_status.Free", "Free"),
        "Basic": t("Dashboards.Dashboard_status.Basic", "Basic"),
        "Pro": t("Dashboards.Dashboard_status.Pro", "Pro"),
        "Individual": t("Dashboards.Dashboard_status.Individual", "Individual")
    }

    return statuses[status] || status;
}


export function translateSubscriptionPlan(status, t) {

    const statuses = {
        "free": t("Subscriptions.free", "Free"),
        "Free": t("Subscriptions.free", "Free"),
        "Basic / Monthly": t("Subscriptions.basic_monthly", status),
        "Basic / Yearly": t("Subscriptions.basic_yearly", status),
        "Pro / Monthly": t("Subscriptions.pro_monthly", status),
        "Pro / Yearly": t("Subscriptions.pro_yearly", status),
    }

    return statuses[status] || status;
}

// https://support.stripe.com/questions/invoice-states
export function translateInvoiceStatus(invoice_status, t) {

    const invoice_status_lowerCase = invoice_status.toLowerCase()

    const invoiceStatuses = {
        "draft": t("InvoiceStatuses.draft", invoice_status),
        "deleted": t("InvoiceStatuses.deleted", invoice_status),
        "open": t("InvoiceStatuses.open", invoice_status),
        "paid": t("InvoiceStatuses.paid", invoice_status),
        "uncollectible": t("InvoiceStatuses.uncollectible", invoice_status),
        "void": t("InvoiceStatuses.void", invoice_status),
    }

    return invoiceStatuses[invoice_status_lowerCase] || invoice_status_lowerCase
}

export function translateBillingPeriods(billingPeriod, t) {


    const billingPeriods = {
        "Monthly": t("BillingPeriods.Monthly", "Monthly"),
        "Yearly": t("BillingPeriods.Yearly", "Yearly")
    }

    return billingPeriods[billingPeriod] || billingPeriod
}