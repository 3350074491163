import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common.js"







// ## Dashboards ##

// 04.12.2023 Updated with new standard of returning data
export async function API_CreateNewDashboard(token, newDashboard) {
    const URL = `${CURRENT_USING_URL}/dashboards/create_dashboard/`;

    console.log("\n[API_CreateNewDashboard]\nTrying to add new new Dashboard.. \nCalling: ", URL)
    console.log("\n[API_CreateNewDashboard]\nNew Dashboard data:\n", newDashboard)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(newDashboard),
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_CreateNewDashboard]\nNew dashboard added! \n", response_json)

            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_CreateNewDashboard]\nSomething went wrong with the response: ", response)
            console.log("\n[API_CreateNewDashboard]\nResponse json: \n", response_json)
            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }


    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_GetDashboardByOwner(token) {
    const URL = `${CURRENT_USING_URL}/dashboards/get-dashboard-by-owner/`;

    console.log("\n[API_GetDashboardByOwner]\nTrying to get dashboards by owner. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_GetDashboardByOwner]\nDashboards found: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetDashboardByOwner]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetDashboardByOwner]\nResponse json: \n", response_json)
            return { isOk: true, response: response }
        }

    } catch (error) {
        console.log("\n[API_GetDashboardByOwner]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


// 04.12.2023 Updated with new standard of returning data
export async function API_GetDashboardLimits(token, hash_id) {
    const URL = `${CURRENT_USING_URL}/dashboards/get-dashboard-limits/${String(hash_id)}`;

    console.log("\n[API_GetDashboardLimits]\nTrying to get dashboards limits. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_GetDashboardLimits]\nDashboards limits found: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetDashboardLimits]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetDashboardLimits]\nResponse json: \n", response_json)
            return { isOk: true, response: response }
        }

    } catch (error) {
        console.log("\n[API_GetDashboardLimits]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}





// 04.12.2023 Updated with new standard of returning data
export async function API_CheckIfDashboardExists(token, hash_id) {
    const URL = `${CURRENT_USING_URL}/dashboards/check-dashboard-hash/${String(hash_id)}`;

    console.log("\n[API_CheckIfDashboardExists]\nTrying to check if dashboard exists and we are proper user.. \nCalling: ", URL)
    console.log("[API_CheckIfDashboardExists]\nDashboard hash is:\n", hash_id)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        // In this scenario we return items as: 
        // {exists: boolean, dashboard: {}}

        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_CheckIfDashboardExists]\nDashboard exists and is valid!: \n", response_json)
            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_CheckIfDashboardExists]\nSomething went wrong with the response: ", response)
            console.log("\n[API_CheckIfDashboardExists]\nResponse json: \n", response_json)
            return { isOk: false, response: response, items: response_json }
        }

    } catch (error) {
        console.log("\n[API_CheckIfDashboardExists]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_UpdateDashboard(token, hash_id, updatedDashboard) {
    const URL = `${CURRENT_USING_URL}/dashboards/update-dashboard/${String(hash_id)}`;

    console.log("\n[API_UpdateDashboard]\nTrying to update dashboard \nCalling: ", URL)
    console.log("[API_UpdateDashboard]\nDashboard hash is:\n", hash_id)
    console.log("[API_UpdateDashboard]\nDashboard new data is:\n", updatedDashboard)

    let errors = [];

    try {

        // Client side errors
        if (updatedDashboard.name.length < 3) {
            errors.push("Name cannot be smaller than 3 characters!")
        }

        if (updatedDashboard.description.length < 3) {
            errors.push("Description has to have atleast 3 characters!")
        }

        if (errors.length === 0) {

            const response = await fetch(URL, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Include the Bearer token here
                },
                body: JSON.stringify(updatedDashboard)
            });


            if (response.ok) {
                const response_json = await response.json();
                console.log("\n[API_UpdateDashboard]\nDashboard updated! \n", response_json)
                return { isOk: true, response: response }
            }
            else {
                const response_json = await response.json();
                console.log("\n[API_UpdateDashboard]\nSomething went wrong with the response: ", response)
                console.log("\n[API_UpdateDashboard]\nResponse json: \n", response_json)
                return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
            }
        }
        else {
            console.log("\n[API_UpdateDashboard]\nFound Errors!: \n", errors)
            return { isOk: false, other_errors: errors }
        }

    } catch (error) {
        console.log("\n[API_UpdateDashboard]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_DeleteDashboard(token, hash_id) {
    const URL = `${CURRENT_USING_URL}/dashboards/delete-dashboard/${String(hash_id)}`;

    console.log("\n[API_DeleteDashboard]\nTrying to delete client by id.. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        if (response.ok) {
            // successfully deleted
            console.log("\n[API_DeleteDashboard]\nDashboard deleted correctly!")
            return { isOk: true, response: response }
        }
        else {
            // something went wrong 
            console.log("\n[API_DeleteDashboard]\nCound not delete Dashboard!\nResponse:", response)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }


    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_DeleteDashboard]\nError fetching data:\n ", error)
        // throw new Error("500 - Internal Server Error. Try again later");
        return { isOk: false, error_message: server_off_message }
    }
}
