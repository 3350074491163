// React
import React from 'react'

// Styles
import classes from "./DashboardDataNotFound.module.scss"

// Contexts
import { useDashboardContext } from '../../context/DashboardContext';

// React elements
import { ButtonPrimaryFill } from '../../components/Elements/Buttons';
import { useTranslation } from 'react-i18next';
import { TitleSectionIconWrapper, WrapperFlexCol } from '../../components/Elements/Wrappers';

// Icons 
import { ReactComponent as DBErrorIcon } from "../../static/n_icons/Neutral.svg"

function DashboardDataNotFound({ additionalText = "", goBackTo = "/home" }) {

    // goBackTo has to have dashboard path only! and only ending so 
    // Not valid are /dashboard/clients 
    // Valid are /home, /clients, /notes

    // We use this when we dont gather data or data dont exists in dashboard on whatever page
    // We also add some additionalInformation that we can call from parent 

    const { t } = useTranslation();

    const { currentPath, moveToPathInsideDashboard } = useDashboardContext()


    // Changes goBackTo etc (/dashboard) to Dashboard for button display
    function changeGoBackToText() {

        let buttonText = goBackTo.replace(/^\//, ''); // Remove starting '/'
        let translated_text = t(`Sidebar.${buttonText}`, `${buttonText}`)

        buttonText = translated_text.charAt(0).toLowerCase() + translated_text.slice(1);

        return buttonText;
    }

    function handleNavigation() {

        let basePath = currentPath.split('/').slice(0, 3).join('/') + goBackTo; // Extract /dashboard/<hash>  from url

        // Update current path
        moveToPathInsideDashboard(basePath)
    }

    return (
        <div className={classes.DashboardDataNotFound}>

            <WrapperFlexCol className={'DashboardErrorContainer'} style={{ alignItems: "center", height: "100%", paddingLeft: "1rem", paddingRight: "1rem" }}>

                <TitleSectionIconWrapper stroke_fill={false} icon={<DBErrorIcon />} />

                {/* <h2 className="SectionTitleText"> {t("Common.Oops", "Oops!")} </h2> */}

                <p style={{ textAlign: "center" }} className='DefaultText text-gray '> {t("DashboardDataNotFound.data_not_found", "We tried our best, but we didn't find anything in our database.")}  </p>

                {
                    (additionalText) &&
                    <div className={classes.DashboardDataNotFoundAdditionalText}>
                        <p className='DefaultText'>{additionalText}</p>
                    </div>

                }

                <ButtonPrimaryFill onClick={handleNavigation} text={t("DashboardDataNotFound.go_back_to", `Go back to ${changeGoBackToText()}`, { goBackToText: changeGoBackToText() })} />

            </WrapperFlexCol>

        </div>
    )
}

export default DashboardDataNotFound