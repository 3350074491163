// React
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Styles
import classes from "../styles/styles_pages/Login.module.scss"
import muStyles from "../styles/mu-styles.module.scss"

// Layout
import LayoutHero from './Entrance/LayoutHero/LayoutHero'

// Context 
import { useUserContext } from '../context/UserAuth'

// Apis
import { API_RegisterNewUser } from '../api/API_user_auth'

// Icons / SVG
import signin_img from "../static/hero-img.png"
import logo from "../static/Annualty-logo.png"
import { ReactComponent as IconLogin } from "../static/n_icons/Email.svg"
import { ReactComponent as IconPassword } from "../static/n_icons/Lock.svg"
// import { ReactComponent as IconGoogle } from "../static/icons/Google.svg"
// import { ReactComponent as IconFacebook } from "../static/icons/Facebook.svg"
import { ReactComponent as IconUser } from "../static/n_icons/Gem.svg"

// MU
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '../components/Elements/FormMessageBoxes'
import { isEmailValid, isPasswordValid, isUsernameValid } from '../utils/data_validators/signin_validator'
import { ButtonPrimaryFill } from '../components/Elements/Buttons'
import { LabelLeftSingleIcon } from '../components/Elements/FormLabels'
import { WrapperFlexCol, WrapperFlexRow } from '../components/Elements/Wrappers'
import { SingleInput } from '../components/Elements/FormInputs'


// Our signin page (shares the same scss file with login page)


function Signin() {

    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    const { loginUser } = useUserContext()

    // Use this to check if we have some errors while loggin in! 
    // Also this displays error message 
    const [signinError, setSigninError] = useState([]);

    const [newUser, setNewUser] = useState({
        email: "",
        username: "",
        password: ""
    })

    // MU Logic for Password show / hide 
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // Change current page with "/route" 
    const handleNavigation = (route) => {
        navigate(route);
    };


    async function loginAfterRegistering(email, password) {

        const response = await loginUser(email, password)

        if (response && response.loginSuccess) {
            console.log("Success!")

            // Redirect to the intended page (e.g., dashboard)
            const intendedPage = location.state?.from || "/"; // Update with the correct URL
            handleNavigation(intendedPage);
        }
    }

    async function registerNewUser(e) {
        e.preventDefault();

        // We are collecting errors to display them all to user
        // errors = ['error1', 'error2']
        let errors = [];

        const usernameValidation = isUsernameValid(newUser.username, t)
        const passwordValidation = isPasswordValid(newUser.password, t)
        const emailValdation = isEmailValid(newUser.email, t)

        if (usernameValidation.length > 0) errors = errors.concat(usernameValidation)
        if (passwordValidation.length > 0) errors = errors.concat(passwordValidation)
        if (emailValdation.length > 0) errors = errors.concat(emailValdation)


        // If we have all inputs filled
        if (errors.length === 0) {

            // Try to add new user
            try {

                const addNewUser = await API_RegisterNewUser(newUser, t);

                if (addNewUser.isOk && addNewUser.isOk === true) {
                    // New user added we can login him now
                    console.log("Trying to login new registered user!")
                    try {
                        await loginAfterRegistering(newUser.email, newUser.password)
                    }
                    catch (error) {
                        console.log("Something went wrong in loginAfterRegistering()")
                        handleNavigation("/")
                    }


                } else {

                    // If we are here, server found some errors!
                    if (addNewUser.errors) {
                        console.log("Got server_erros: ", addNewUser.errors)
                        errors = errors.concat(addNewUser.errors)
                    }
                    else {
                        errors.push(t("Common.something_went_wrong", "Something went wrong. Try again later."))
                    }

                }

            }
            // If we are here, we couldn't register. Might be because server is off or we have no connection!
            catch (error) {
                errors.push(error.message)
            }
        }
        setSigninError(errors);
    }


    return (
        <LayoutHero>
            <div className={classes.LoginContainer}>

                <div className="container is-max-desktop">

                    <div className={classes.LoginContainerWrapper}>

                        <div className={classes.LoginBox}>

                            <div className={classes.LoginBoxLeft}>

                                <h2 className='BigText text-bold'> {t("Common.sign_in", "Sign in")} </h2>

                                <WrapperFlexRow style={{ padding: "0" }}>
                                    <p className={"DefaultText"}>
                                        {t("Signin.have_an_account", "Have an account?")}

                                    </p>

                                    <p className={"DefaultText as_link_new text_no_wrap linkPrimary"} onClick={() => { handleNavigation('/login') }}>
                                        {t("Common.log_in", "Log in")}
                                    </p>
                                </WrapperFlexRow>


                                {/* Error message */}
                                {(signinError.length > 0) && (
                                    <ErrorContainer errors={signinError} />
                                )}

                                <WrapperFlexCol on_submit={registerNewUser} as_form={true} style={{ padding: "0" }} className={classes.FormContainer}>
                                    {/* Username */}
                                    {/* <div style={{ marginTop: "1rem" }} className={classes.LabelContainer}>
                                        <IconUser className={classes.LabelIcon} />
                                        <p className={classes.LabelText}> {t("Signin.username_label", "Enter your username")} </p>
                                    </div> */}
                                    <LabelLeftSingleIcon LeftIcon={IconUser} LabelText={t("Signin.username_label", "Enter your username")} />
                                    <SingleInput onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} placeholder={t("Signin.username_placeholder", "Username")} value={newUser.username} />


                                    {/* Login / Email  */}
                                    <LabelLeftSingleIcon LeftIcon={IconLogin} LabelText={t("Signin.email_label", "Enter your email")} />

                                    {/* <TextField onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} className={muStyles.mu_TextFiledInput} fullWidth id="outlined-basic" label={t("Signin.email_placeholder", "Email Address")} variant="outlined" /> */}
                                    <SingleInput value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} placeholder={t("Signin.email_placeholder", "Email Address")} />


                                    {/* Password with hide option  */}
                                    <LabelLeftSingleIcon LeftIcon={IconPassword} LabelText={t("Signin.password_label", "Enter password")} />

                                    <SingleInput type={"password"} value={newUser.password} autoComplete="off" onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} placeholder={t("Signin.password_placeholder", "Password")} />

                                    {/* Submit login  */}
                                    <ButtonPrimaryFill style={{ marginTop: "1rem" }} className={"BtnFullWidth BtnShadow"} disabled={!(newUser.username && newUser.password && newUser.email)} type="submit" onClick={registerNewUser} text={t("Common.sign_in", "Sign in")} />

                                </WrapperFlexCol>

                                {/* <div className={classes.OrSignUpContainer}>
                                <hr /> {t("Signin.or_sign_in_with", "Or sign in with")} <hr />
                            </div> */}

                                {/* To do: Add Google and Facebook login  */}
                                {/* <div className={classes.MediaLoginContainer}>
                                <Button className={muStyles.mu_btnOutlinedGoogle} variant="outlined" startIcon={<IconGoogle />}> Google  </Button>
                                <Button className={muStyles.mu_btnOutlinedFacebook} variant="outlined" startIcon={<IconFacebook />}> Facebook </Button>
                            </div> */}

                            </div>


                            {/* Right side graphic */}

                            <div className={classes.LoginBoxRight}>
                                <div className={classes.LoginBoxRightWrapper}>
                                    <img src={signin_img} alt="Login graphic" />
                                    <p className='SmallText text-gray' style={{ textAlign: "center" }}>
                                        {t("Signin.embrace_seamless_organization_message", "Embrace seamless organization with the power of Annualty - your all-in-one hub for stress-free appointments and efficient work management. ")}

                                    </p>
                                </div>


                            </div>
                        </div>


                    </div>

                </div>


            </div>
        </LayoutHero>


    )
}


export default Signin