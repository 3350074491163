// React
import React, { useEffect, useState } from 'react'

// Styles
import muStyles from "../styles/mu-styles.module.scss";

// Mu
import { Alert, Snackbar } from '@mui/material';

// Context
import { useUserContext } from '../context/UserAuth';
import { useTranslation } from 'react-i18next';


// Snackbar that is used after login, only ONCE 
// From 21.12.2023 - Currently used only after user success login. You can add more Snackbars later


function WelcomeSnackbar({ message, isLogged }) {

    const [showSnackbar, setShowSnackbar] = useState(false)

    useEffect(() => {

        if (localStorage.getItem("showSnackbarAfterLogin") === 'true') {
            setShowSnackbar(true)

            // Close snackbar after some seconds 
            setTimeout(() => {
                handleClose()
            }, 3000)
        }

    }, [isLogged])


    function handleClose() {
        setShowSnackbar(false);
        localStorage.setItem("showSnackbarAfterLogin", "false")
    }

    return (

        <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: '100%' }}
                className={muStyles.SnackbarAlert}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

// Our Snackbar context that will be displayed everywhere, depending on what snackbar we choose 
export function SnackbarWrapper({ children }) {

    const { currentUser, isLogged } = useUserContext()
    // isLogged is needed because this is how our page works, we need to wait for page rerender to hit showSnackbarAfterLogin to true

    const { t } = useTranslation();

    const snackbar_welcome_message = t("SnackbarWrapper.welcome_back", "Hello") + " " + currentUser.username;

    return (
        <>
            <WelcomeSnackbar message={snackbar_welcome_message} isLogged={isLogged} />
            {children}
        </>
    );
}

