// React
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// Layout
import LayoutHero from '../../Entrance/LayoutHero/LayoutHero'

// Styles
import classes from "./Pages_styles/DashboardNoPermisions.module.scss"

// Helpers

// Elements / Components
import { WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { ButtonNeutralToDanger, ButtonPrimaryFill } from '../../../components/Elements/Buttons'

// Images / static 
import errorImage from "../../../static/404image.png"




function DashboardNoPermisions() {

    // if we are here, that means that we hit url like /dashboard/<hash>
    // there might be two options 
    // 1. We have a correct length hash but we have no permissions 
    // 2. We are on /dashboard/abcd 
    // For now, we just say that we couldn't show anything.. 

    const { t } = useTranslation();

    const navigate = useNavigate();

    function handleGoToDashboards() {
        // Here, we dont update currentPath because we go back to /dashboards
        navigate(`/dashboards`)

    }

    // TO DO 
    // MAKE SURE U UNDERSTAND WHEN THIS PAGE IS RENDERED 
    // CHANGE IT TO DISPLAY 403 error (No permissions)

    return (
        <LayoutHero>

            <div className={classes.DashboardNoPermisions}>

                <div className="container">

                    <div className={classes.DashboardNoPermisionsWrapper}>

                        <img className={classes.ErrorImage} src={errorImage} alt="Error graphic" />
                        <h2 className="SectionTitleText">{t("DashboardNotFound.page_not_found_title", "Oops!")}</h2>
                        <h3 className='DefaultText'>{t("DashboardNotFound.page_not_found_subtitle", "Page not found!")}</h3>

                        <WrapperFlexRow style={{ alingItems: "center", justifyContent: "center" }}>
                            <ButtonPrimaryFill text={t("Common.go_back_to_home", "Go back to Dashboards")} onClick={handleGoToDashboards} />
                            <ButtonNeutralToDanger text={t("Common.report_a_bug", "Report a bug")} />
                        </WrapperFlexRow>

                    </div>
                </div>

            </div>

        </LayoutHero>
    )
}

export default DashboardNoPermisions