import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";

export async function API_CreatePriceList(token, price_list) {
    const URL = `${CURRENT_USING_URL}/price-lists/create/`;

    console.log("\n[API_CreatePriceList]\nTrying to create new price list.. \nCalling: ", URL)
    console.log("[API_CreatePriceList]\nNew price list data is:\n", price_list)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(price_list),
        });


        if (response.ok) {
            console.log("\n[API_CreatePriceList]\nNew price list added!")
            const response_json = await response.json();
            console.log(response_json);
            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();

            console.log("\n[API_CreatePriceList]\nSomething went wrong with the response: ", response)
            console.log("\n[API_CreatePriceList]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_CreatePriceList]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_GetPriceLists(token, dashboard_id) {
    const URL = `${CURRENT_USING_URL}/price-lists/${String(dashboard_id)}`;

    console.log("\n[API_GetPriceLists]\nTrying to get price lists by dashboard.. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetPriceLists]\nPrice lists found!")

            const response_json = await response.json();

            console.log("\n[API_GetPriceLists]\nFetched price list array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetPriceLists]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetPriceLists]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetPriceLists]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

export async function API_ToggleFav(token, price_list_id) {
    const URL = `${CURRENT_USING_URL}/price-lists/toggle-favourite/${String(price_list_id)}/`;

    console.log("\n[API_ToggleFav]\nTrying to toggle price lists fav by id.. \nCalling: ", URL)
    console.log(`Id: ${price_list_id}`)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {

            const response_json = await response.json();
            console.log("\n[API_ToggleFav]\nToggle success!")
            console.log("\n[API_ToggleFav]\nUpdated price_list: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_ToggleFav]\nSomething went wrong with the response: ", response)
            console.log("\n[API_ToggleFav]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_ToggleFav]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


export async function API_UpdatePriceList(token, updated_price_list, id) {
    const URL = `${CURRENT_USING_URL}/price-lists/update/${String(id)}/`;

    console.log("\n[API_UpdatePriceList]\nTrying to update price lists by id.. \nCalling: ", URL)
    console.log(`Price list data: ${updated_price_list}`)

    try {

        const response = await fetch(URL, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(updated_price_list),
        });


        if (response.ok) {

            const response_json = await response.json();
            console.log("\n[API_UpdatePriceList]\nUpdate success!")
            console.log("\n[API_UpdatePriceList]\nUpdated price_list: \n", response_json)

            return { isOk: true, response: response, updated_item: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_UpdatePriceList]\nSomething went wrong with the response: ", response)
            console.log("\n[API_UpdatePriceList]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_UpdatePriceList]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}



export async function API_DeletePriceList(token, price_list_id) {
    const URL = `${CURRENT_USING_URL}/price-lists/delete/${String(price_list_id)}/`;

    console.log("\n[API_DeletePriceList]\nTrying to delete price lists by id.. \nCalling: ", URL)
    console.log(`Id: ${price_list_id}`)

    try {

        const response = await fetch(URL, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_DeletePriceList]\nDeletion success!")

            return { isOk: true, response: response }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_DeletePriceList]\nSomething went wrong with the response: ", response)
            console.log("\n[API_DeletePriceList]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_DeletePriceList]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}



