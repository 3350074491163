// Translated categories that we use in search bar (next to it)
export function getSearchCategories_priceList(t) {
    const searchCategories = [
        { value: `search_by_name`, label: t("PriceList.p_name", `Name`) },
        { value: `search_by_tag`, label: t("PriceList.p_tag", `Tag`) },
        { value: `search_by_length`, label: t("PriceList.p_length", `Length`) },
        { value: `search_by_price`, label: t("PriceList.p_price", `Price`) },
        { value: `search_by_description`, label: t("PriceList.p_description", `Description`) },
    ]

    return searchCategories
}