// React
import React, { createContext, useContext, useEffect, useState } from 'react';

/*
 This file is for checking current width of agent window 
 and we can decide to display his app as mobile or desktop 
*/

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }

            // console.log("Window size: ", window.innerWidth, isMobile)
        };

        // Initial check
        checkIfMobile();

        // Attach a resize event listener to update the state when the viewport size changes
        window.addEventListener('resize', checkIfMobile);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    return (
        <DeviceContext.Provider value={{ isMobile }}>
            {children}
        </DeviceContext.Provider>
    );
};

export const useDeviceContext = () => {
    return useContext(DeviceContext);
};
