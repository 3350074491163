// ThemeContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getLocalStorageItem } from '../helper/local_storage';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [darkTheme, setDarkTheme] = useState(false);

    // Default is light color (false), and if we want to put dark theme, then we will add class .dark-theme to App.js!  
    const themeClassName = darkTheme ? 'dark-theme' : 'light-theme';

    const toggleTheme = () => {
        setDarkTheme((prevTheme) => !prevTheme);
    };

    // This useEffect searches in localStorage for 'dark_mode' it can be either true or false 
    // We set 'dark_mode' from 'NavbarHero.js'
    useEffect(() => {
        console.log("[ThemeProvider] Looking for current theme")
        const isDarkTheme = getLocalStorageItem('dark_mode')

        console.log(`[ThemeProvider] Applying theme: ${isDarkTheme} | ${isDarkTheme ? 'dark-theme' : 'light-theme'}`)
        setDarkTheme(isDarkTheme)
    }, [])


    // This effect is needed, so we will also change color-scheme of the app 
    // so we will have scrollbars also with dark / light mode <3 
    useEffect(() => {
        // Add or remove the "isDark" class based on darkTheme state
        // It is important that we make it in body, not in html or root (they wont work on all pages)
        const rootElement = document.getElementsByTagName("body")[0]; // Get the first body element
        if (rootElement) {
            if (darkTheme) {
                rootElement.classList.add('isDark');
            } else {
                rootElement.classList.remove('isDark');
            }
        }
    }, [darkTheme]);

    return (
        <ThemeContext.Provider value={{ darkTheme, toggleTheme, themeClassName }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    return useContext(ThemeContext);
};