// React
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

// API 
import { API_UpdateUserProfilePassword } from '../../../../api/API_user_auth'

// Elements 
import { DataBox, WrapperFlexCol, WrapperFlexRow } from '../../../../components/Elements/Wrappers'
import { SingleInput } from '../../../../components/Elements/FormInputs'
import { ButtonPrimaryFill } from '../../../../components/Elements/Buttons'
import { ErrorContainer, SuccessContainer } from '../../../../components/Elements/FormMessageBoxes'

function ChangePassword() {


    const { t } = useTranslation();

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    const token = localStorage.getItem("access_token")

    // This is storing passwords separately, so we can have second logic for passwords
    // Warning 
    // We are using empty additional currentPassword property, so we dont expose user password here, 
    const [currentPasswordsToUpdate, setCurrentPasswordsToUpdate] = useState({
        currentPassword: "",
        newPassword: "",
        repeatPassword: "",
    })


    // For password only change!
    async function updateUserPassword(e) {
        e.preventDefault()
        let errors = []

        if (token) {

            const response = await API_UpdateUserProfilePassword(token, currentPasswordsToUpdate)

            if (response && response.isOk) {


                setIsSuccess(true)

                setCurrentPasswordsToUpdate({
                    currentPassword: "",
                    newPassword: "",
                    repeatPassword: "",
                })

                // Why you put setCurrentUser here? 
                // Because, changing currentUser will fully refresh page (state), so 
                // We want to inform user about change and all and update page after 2 seconds 
                // Same u did in Settings 
                setTimeout(() => {
                    setIsSuccess(false)
                    // setCurrentUser(updatedUser)
                }, 1500)
            }
            else if (response && response.isOk === false) {

                if (response.error_message) {

                    errors.push(response.error_message)


                    // Here we take some messages from django and manualy handle them for users for better understatning of error 
                    // Because throwing error "400 - Bad Request" is not the best option here
                    if (response.response_json && response.response_json.non_field_errors) {
                        errors.push(t("validationErrors.passwords_missmatch", "New password and repeat password do not match."))
                    }
                    if (response.response_json && response.response_json.currentPassword) {
                        errors.push(t("validationErrors.user_password_is_incorrect", "Current password is incorrect"))
                    }



                }
            }
        }

        setErrors(errors)
    }

    return (
        <div>

            {(errors.length > 0 && !isSuccess) && (
                <ErrorContainer errors={errors} />
            )}

            {(isSuccess) &&
                <SuccessContainer message={t("ChangePassword.container.success", "Password updated successfully!")} />
            }


            <DataBox className={"HideScrollbar smallerGap"} style={{ borderRadius: "0", gap: "0.5rem", padding: "0rem" }}>


                {/* Title  */}
                <WrapperFlexRow className={"WrapperTitle"} style={{ flexWrap: "wrap", gap: "1rem" }}>

                    <WrapperFlexCol style={{ padding: "0", gap: "0", width: "unset" }}>

                        <h2 className="SectionTitleText"> {t("ChangePassword.title", "Change password")} </h2>

                    </WrapperFlexCol>

                    <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", flex: "1" }}>
                        <ButtonPrimaryFill disabled={!(
                            currentPasswordsToUpdate.currentPassword && currentPasswordsToUpdate.newPassword && currentPasswordsToUpdate.repeatPassword
                        )}
                            onClick={updateUserPassword} text={t("Common.button.changePassword", "Change password")} />
                    </WrapperFlexRow>


                </WrapperFlexRow>


                <WrapperFlexRow style={{ padding: "0" }}>
                    <p className='SmallText text_gray_on_layout text-italic'>
                        {t("ChangePassword.before_proceeding", "To change your password, please ensure all three input fields are filled, and the accept the changes.")}
                    </p>
                </WrapperFlexRow>


                <hr className='myHr' style={{ marginBottom: "1rem" }} />



                <WrapperFlexCol style={{ padding: "0", gap: "2rem" }}>

                    {/* Current Password  */}
                    <WrapperFlexRow style={{ padding: "0", flexwrap: "wrap" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("ChangePassword.input.password.placeholder", "Current password")}</p>
                            <p className='SmallText text-gray'> {t("ChangePassword.input.edit_password.placeholder", "Enter your current password")}  </p>
                        </WrapperFlexCol>


                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentPasswordsToUpdate.currentPassword}
                            onChange={(e) => setCurrentPasswordsToUpdate({ ...currentPasswordsToUpdate, currentPassword: e.target.value })}
                            label="current-password"
                            placeholder={t("ChangePassword.input.enter_password.placeholder", "Enter current password")}
                            type={"password"}
                        />


                    </WrapperFlexRow>




                    <hr className='myHr' />



                    {/* New Password */}
                    <WrapperFlexRow style={{ padding: "0", flexwrap: "wrap" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("ChangePassword.input.new_password.placeholder", "New password")}</p>
                            <p className='SmallText text-gray'> {t("ChangePassword.input.edit_new_password.placeholder", "Enter your new password")}  </p>
                        </WrapperFlexCol>


                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentPasswordsToUpdate.newPassword}
                            onChange={(e) => setCurrentPasswordsToUpdate({ ...currentPasswordsToUpdate, newPassword: e.target.value })}
                            label="new-password"
                            placeholder={t("ChangePassword.input.enter_new_password.placeholder", "Enter new password")}
                            type={"password"}


                        />


                    </WrapperFlexRow>



                    {/* Repeat new password  */}
                    <WrapperFlexRow style={{ padding: "0", flexwrap: "wrap" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("ChangePassword.input.repeat_new_password.placeholder", "Repeat new password")}</p>
                            <p className='SmallText text-gray'> {t("ChangePassword.input.edit_repeat_new_password.placeholder", "Repeat your new password")}  </p>
                        </WrapperFlexCol>


                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentPasswordsToUpdate.repeatPassword}
                            onChange={(e) => setCurrentPasswordsToUpdate({ ...currentPasswordsToUpdate, repeatPassword: e.target.value })}
                            label="off"
                            placeholder={t("ChangePassword.input.repeat_new_password.placeholder", "Repeat new password")}
                            type={"password"}
                        />


                    </WrapperFlexRow>




                </WrapperFlexCol>

            </DataBox>


        </div>
    )
}

export default ChangePassword