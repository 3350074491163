// React
import React, { useEffect, useState } from 'react'

// Layout
import classes from "./Layout.module.scss"

// Dashboard Specific components 
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar/Sidebar'

function Layout({ children, setCurrentPath, dashboardHash, currentDashboardMenu }) {


    const [showMenu, setShowMenu] = useState(false)

    console.log(`[Layout] Current view: ${currentDashboardMenu}`)


    // We share this function with Navbar and Sidebar, depending on menu state, we show or hide menu 
    function toggleMenu() {
        setShowMenu(!showMenu)
    }

    function closeMenu() {
        setShowMenu(false)
    }

    // This Effect is only for scrolling any /dashboard/.. page to top (because it is weird when we go back to page and we are at the end or middle of it right? )
    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    }, [children]);


    // This is needed so our app will better understand current height of the page 
    // It solves problem when we hit bottom of page and it was not actuall end of it (also without it it bugged scrolling)
    function appHeight() {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', appHeight)
    appHeight()





    return (
        <>
            {/* Blurr on sidebar active  */}
            <div className={(showMenu) ? classes.SidebarBlurr : ""} onClick={() => { closeMenu() }}></div>


            <div className={classes.Layout}>

                <Navbar toggleMenu={toggleMenu} showMenu={showMenu} />

                {/* Side bar here  */}
                <Sidebar closeMenu={closeMenu} setCurrentPath={setCurrentPath} showMenu={showMenu} dashboardHash={dashboardHash} />

                <div className={classes.LayoutContainer} >


                    {/* Main content here  */}
                    <div className={`${classes.LayoutContent} ${(currentDashboardMenu === 'calendar') ? classes.CalendarContent : ""}`}>
                        {/* <div className={`${(currentDashboardMenu !== 'calendar' && currentDashboardMenu !== 'price-list') ? 'container is-max-desktop' : ""}`}> */}
                        {children}
                        {/* </div> */}
                    </div>



                </div>

            </div>
        </>
    )

}

export default Layout