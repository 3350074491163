// React
import React from 'react'
import { useTranslation } from 'react-i18next';

// Styles
import classes from "./DashboardLoading.module.scss"


function DashboardLoading() {

    // We use this component when we load data like /Client 
    const { t } = useTranslation();

    return (
        <div className={classes.DashboardLoading}>

            <p className='DefaultText' style={{ textAlign: "center" }}>
                {t("DashboardLoading.loading_data", "Hold tight! We are loading your data..")}
            </p>


            <span className="loading_animation"></span>

        </div>

    )
}

export default DashboardLoading