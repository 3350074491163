import { useState } from "react";
import { ReactComponent as HeartIcon } from "../../static/icons/Heart.svg"

// Here we specify only buttons that show up in popups or do some server side acctions
// If you search for "normal" page buttons go to Buttons.js

export function ButtonCancel({ onClick, label, type = "button", style = {}, className, disabled = false }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            className={`ButtonCancel ${(className !== undefined) ? className : ""}`}
            disabled={disabled}
        >
            {label}
        </button>
    );
}

export function ButtonSave({ onClick, label, type = "button", style = {}, className, disabled = false }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            className={`ButtonSave ${(className !== undefined) ? className : ""}`}
            disabled={disabled}
        >
            {label}
        </button>
    );
}

export function ButtonDelete({ onClick, label, type = "button", style = {}, className, disabled = false }) {
    return (
        <button
            type={type}
            style={style}
            onClick={onClick}
            className={`ButtonDelete ${(className !== undefined) ? className : ""}`}
            disabled={disabled}
        >
            {label}
        </button>
    );
}


export function HeartToggle({ initialFavState, favIconLocked, toggleFavourite, clientId, style = {} }) {
    const [isFav, setIsFav] = useState(initialFavState);

    const toggleFav = () => {
        if (!favIconLocked) {
            toggleFavourite(clientId, setIsFav); // Pass the clientId and toggled state to the parent component
        }
    };

    return (
        <div style={style} className="HeartToggle">
            <p
                onClick={toggleFav}
                className={`ClientFavHeart ${isFav ? 'ClientIsFav' : ''} ${favIconLocked ? 'FavLocked' : ''}`}
            >
                <HeartIcon className={"SVG_STROKE"} />
            </p>
        </div>
    );
}