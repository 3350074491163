
import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";


export async function API_GetVisitsByDateFromTo(token, dashboard_id, date_from, date_to) {
    const URL = `${CURRENT_USING_URL}/visits/get-visits-by-date/${String(dashboard_id)}/?date_from=${date_from}&date_to=${date_to}`;

    console.log("\n[API_GetVisitsByDateFromTo]\nTrying to get visits by months and year.. \nCalling: ", URL)
    console.log("Dashboard id:", dashboard_id)
    console.log("Getting visits for: date_from", date_from, "date_to", date_to)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetVisitsByDateFromTo]\nVisits found!")

            const response_json = await response.json();

            console.log("\n[API_GetVisitsByDateFromTo]\nFetched visits array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetVisitsByDateFromTo]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetVisitsByDateFromTo]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    } catch (error) {
        console.log("\n[API_GetVisitsByDateFromTo]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}