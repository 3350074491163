
// Returns today in yyyy-mm-dd for input type date
export function getCurrentDateFormatted() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}


// Returns Today in DD.MM
export function getFormattedToday() {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0');

    return `${day}.${month}`;
}


// Returns current hour as int from (0 to 24)
// Used when adding new visit 
export function getCurrentHour() {
    const now = new Date();
    let hour = now.getHours();
    const minutes = now.getMinutes();

    if (minutes > 0) {
        hour += 1;
    }

    // Adjust for 24-hour format if it goes over 23 (e.g., 23:30 should return 0 for 0 hour)
    if (hour >= 24) {
        hour -= 24;
    }

    return hour;
}


export function getCurrentMinute() {
    const now = new Date();
    let minutes = now.getMinutes();

    // Round up to the next multiple of 15
    minutes = Math.floor(minutes / 15) * 15;

    // If minutes are 60 or above, set to 0 (upscale to the next hour)
    if (minutes >= 60) {
        minutes = 0;
    }

    return minutes;
}


// returns Today + 1 in DD.MM + 1
export function getFormattedTomorrow() {

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // Increment the date by 1 to get tomorrow

    const day = tomorrow.getDate().toString().padStart(2, '0');
    const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0');

    return `${day}.${month}`;
}


// returns datetime splitted like [dd.mm.yyyy, hh,mm]
export function formatDateTime(inputString) {

    const inputDateTime = new Date(inputString);
    const day = inputDateTime.getDate().toString().padStart(2, '0');
    const month = (inputDateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = inputDateTime.getFullYear().toString();
    const hours = inputDateTime.getHours().toString().padStart(2, '0');
    const minutes = inputDateTime.getMinutes().toString().padStart(2, '0');

    const formatedDate = `${day}.${month}.${year}`
    const formatedTime = `${hours}:${minutes}`

    return [formatedDate, formatedTime]
}


// returns date from dateTime 
export function convertDateTimeToDate(dateTimeString) {
    const date = new Date(dateTimeString);

    if (isNaN(date)) {
        return "Invalid Date"; // Handle invalid datetime strings
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


// Returns current date and time in format YYYY-MM-DDTHH:MM
export function getCurrentDateTimeString() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Add 1 to month as it is 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}


// Returns date formatted in YYYY-MM-DDTHH:MM with passed dateTimeString 
export function formatDateTimeToOurFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Add 1 to month as it is 0-based
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}


// from "2023-09-16T18:42" --> Output: "2023-09-16T18:42:00+02:00"
export function convertToExtendedFormat(datetimeString, timezoneOffset) {
    const dateObject = new Date(datetimeString);
    const formattedDate = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}T${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}:00${timezoneOffset}`;
    return formattedDate;
}


// FOR Calendar.js 
// Creates time slot like ['6:15', '6:30']
export function createTimeSlots(from, to, step) {
    const startTime = parseTime(from);
    const endTime = parseTime(to);
    const timeSlots = [];

    // Helper to parseTime 
    function parseTime(timeStr) {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const time = new Date();
        time.setHours(hours, minutes, 0, 0); // sets hours and minutes, resets seconds and milliseconds
        return time;
    }

    // Here we specify our date format. Currently at "6:15" not "06:15"
    function formatTime(date) {
        // Pads the hour and minutes to ensure two digits
        return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
    }

    while (startTime <= endTime) {
        timeSlots.push(formatTime(startTime));
        startTime.setMinutes(startTime.getMinutes() + step);
    }

    return timeSlots;
}


// From "2024-02-24" return "24.02.2024"
export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};


// From "24.02.2024" return "24.02"
export const formatDayMonth = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based

    return `${day}.${month}`;
};


// From "24.02.2024" return "2024"
export const getYear = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();

    return year;
};




// Added in Analytics.js 
export function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate();
}

export function getFirstDayOfPreviousYear() {
    const currentDate = new Date();
    const year = currentDate.getFullYear() - 1;

    return `${year}-01-01`;
}

export function getEndOfCurrentMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // JavaScript months are 0-based

    const lastDay = getLastDayOfMonth(year, month);

    return `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
}


// Turn TimeStamp (time in seconds) to date in format "DD.MM.YYYY"
export function getDateFromTimeStamp(timeStamp) {
    // Create a new Date object with the provided timestamp (convert to milliseconds by multiplying by 1000)
    const date = new Date(timeStamp * 1000);

    // Extract day, month, and year components from the date
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
    const year = date.getFullYear();

    // Return the formatted date in "DD.MM.YYYY" format
    return `${day}.${month}.${year}`;
}