
import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";


export async function API_GetTodayVisits(token, dashboard_id) {
    const URL = `${CURRENT_USING_URL}/visits/get-today-visits/${String(dashboard_id)}/`;

    console.log("\n[API_GetTodayVisits]\nTrying to get visits for current day.. \nCalling: ", URL)
    console.log("Dashboard id:", dashboard_id)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetTodayVisits]\nVisits found!")

            const response_json = await response.json();

            console.log("\n[API_GetTodayVisits]\nFetched visits array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetTodayVisits]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetTodayVisits]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    } catch (error) {
        console.log("\n[API_GetTodayVisits]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}