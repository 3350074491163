
// React 
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Elements 
import { DataBox, UserAvatarSelectorWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { ButtonNeutralToDanger, ButtonPrimaryFill } from '../../../components/Elements/Buttons'
import { ErrorContainer, SuccessContainer } from '../../../components/Elements/FormMessageBoxes'
import { SingleInput } from '../../../components/Elements/FormInputs'

// Context 
import { useUserContext } from '../../../context/UserAuth'

// Apis 
import { API_UpdateUserProfileBasic } from '../../../api/API_user_auth'


// Helpers
import paths from "../../../data/paths.json"
import user_avatars from "../../../data/avatar_icons.json"


function UserSettings() {

    const { t } = useTranslation();

    const { currentUser, setCurrentUser } = useUserContext()

    const token = localStorage.getItem("access_token")


    const [anyChangesFound, setAnyChangesFound] = useState(false)

    const [currentUserToUpdate, setCurrentUserToUpdate] = useState({ ...currentUser });

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    const path_to_icons = paths.path_to_avatar_images

    useEffect(() => {
        checkForChanges()

        // eslint-disable-next-line
    }, [currentUserToUpdate]);

    function checkForChanges() {
        // Get all keys from both objects
        const keys = Object.keys(currentUser);
        const updatedKeys = Object.keys(currentUserToUpdate);

        // Combine the keys from both objects
        const allKeys = Array.from(new Set([...keys, ...updatedKeys]));

        // Check if any differences are found
        const differencesFound = allKeys.some(key => currentUser[key] !== currentUserToUpdate[key]);

        setAnyChangesFound(differencesFound);

    }

    async function updateUserBasic(e) {
        e.preventDefault()
        let errors = []

        if (token && currentUser && currentUser.id >= 0) {


            // Trim spaces 
            currentUserToUpdate.name = currentUserToUpdate.name.trim();
            currentUserToUpdate.lastname = currentUserToUpdate.lastname.trim();

            const response = await API_UpdateUserProfileBasic(token, currentUserToUpdate)

            if (response && response.isOk) {


                const updatedUser = {
                    ...currentUser,
                    name: currentUserToUpdate.name,
                    lastname: currentUserToUpdate.lastname,
                    avatar: currentUserToUpdate.avatar,
                    username: currentUserToUpdate.username,
                    email: currentUserToUpdate.email
                }

                setCurrentUserToUpdate(updatedUser)

                setIsSuccess(true)

                // Why you put setCurrentUser here? 
                // Because, changing currentUser will fully refresh page (state), so 
                // We want to inform user about change and all and update page after 2 seconds 
                // Same u did in Settings 
                setTimeout(() => {
                    setIsSuccess(false)
                    setCurrentUser(updatedUser)
                    localStorage.setItem("current_user_local", JSON.stringify({ ...updatedUser }))
                }, 1500)
            }
            else if (response && response.isOk === false) {
                console.log(response)
                if (response.error_message) {

                    errors.push(response.error_message)

                    // Here we take some messages from django and manualy handle them for users for better understatning of error 
                    // Because throwing error "400 - Bad Request" is not the best option here
                    if (response.response_json && response.response_json.email) {
                        let res = response.response_json.email[0]
                        console.log(res)

                        if (res.includes("(mu_e01)") || res.includes("Enter a valid email address")) {
                            errors.push(t("validationErrors.email_invalid_format", "Invalid email format.") + " (mu_e01)")
                        }
                        else if (res.includes("(mu_e02)")) {
                            errors.push(t("validationErrors.email_length", "Email must be between 5 and 255 characters long.") + " (mu_e02)")
                        }
                        else if (res.includes("(mu_e03)")) {
                            errors.push(t("validationErrors.email_empty", "Email cannot be blank or contain only spaces.") + " (mu_e03)")
                        }
                        else if (res.includes("my user")) {
                            errors.push(t("validationErrors.email_already_exists", "This email already exists!"))
                        }


                    }

                    if (response.response_json && response.response_json.username) {
                        let res = response.response_json.username[0]

                        if (res.includes("(mu_u01)")) {
                            errors.push(t("validationErrors.username_invalid_characters", "Invalid characters in the username. Only alphanumeric characters and underscores are allowed.") + " (mu_u01)")
                        }
                        else if (res.includes("(mu_u03)")) {
                            errors.push(t("validationErrors.username_length", "Username must be between 3 and 20 characters long.") + " (mu_u03)")
                        }
                        else if (res.includes("(mu_u04)")) {
                            errors.push(t("validationErrors.username_no_spaces", "Username cannot contain spaces.") + " (mu_u03)")
                        }
                        else if (res.includes("(ua_u01)") || res.includes("my user")) {
                            errors.push(t("validationErrors.username_already_exists", "This username already exists!"))
                        }
                    }

                    if (response.response_json && response.response_json.name) {
                        let res = response.response_json.name[0]

                        if (res.includes("(mu_nl01)")) {
                            errors.push(t("validationErrors.name_invalid_characters", "Invalid characters in the username. Only alphanumeric characters and spaces are allowed.") + " (mu_u01)")
                        }
                    }



                }
            }
        }

        setErrors(errors)
    }

    function resetChanges() {
        setCurrentUserToUpdate(currentUser)
        setErrors([])
    }

    return (
        <div>

            {(errors.length > 0 && !isSuccess) && (
                <ErrorContainer errors={errors} />
            )}

            {(isSuccess) &&
                <SuccessContainer message={t("UserProfile.container.success", "Profile updated successfully!")} />
            }

            <DataBox className={"HideScrollbar smallerGap"} style={{ borderRadius: "0", gap: "0.5rem", padding: "0" }}>


                {/* Title  */}
                <WrapperFlexRow className={"WrapperTitle"} style={{ flexWrap: "wrap", gap: "1rem" }}>

                    <WrapperFlexCol style={{ padding: "0", gap: "0", width: "unset" }}>

                        <h2 className="SectionTitleText"> {t("UserProfile.title", "User Profile")} </h2>
                        {/* <p className="DefaultText text_gray_on_layout"> {t("Clients.customers_base", "Your customer base")}  </p> */}

                    </WrapperFlexCol>

                    {/* 
                        // Aditional logic for front end (client) 
                        // if email or username is empty then disable buttons 
                    */}
                    <WrapperFlexRow style={{ justifyContent: "flex-end", padding: "0", flex: "1" }}>

                        <ButtonPrimaryFill disabled={!anyChangesFound || (!currentUserToUpdate.email || !currentUserToUpdate.username)} onClick={updateUserBasic} text={t("Common.button.saveChanges", "Save changes")} />
                        <ButtonNeutralToDanger disabled={!anyChangesFound} onClick={resetChanges} text={t("Common.button.resetChanges", "Reset changes")} />

                    </WrapperFlexRow>

                </WrapperFlexRow>


                <hr className='myHr' style={{ marginBottom: "1rem" }} />


                <WrapperFlexCol style={{ padding: "0", gap: "2rem" }}>


                    {/* User Avatar  */}
                    <WrapperFlexRow style={{ padding: "0", flexwrap: "wrap" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("UserProfile.input.avatar.placeholder", "Your avatar")}</p>
                            <p className='SmallText text-gray'> {t("UserProfile.input.edit_avatar.placeholder", "Edit your avatar")}  </p>
                        </WrapperFlexCol>

                        <UserAvatarSelectorWrapper
                            style={{ width: "100%" }}
                            user={currentUserToUpdate}
                            avatarIcons={user_avatars}
                            pathToIcons={path_to_icons}
                            setClientIcon={(iconPath) => setCurrentUserToUpdate({ ...currentUserToUpdate, avatar: iconPath })}
                        />


                    </WrapperFlexRow>



                    <hr className='myHr' />



                    {/* User name  */}
                    <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", alignItems: "flex-end" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("UserProfile.input.name.placeholder", "Name")}</p>
                            <p className='SmallText text-gray'> {t("UserProfile.input.edit_name.placeholder", "Edit your Name")}  </p>
                        </WrapperFlexCol>

                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentUserToUpdate.name}
                            onChange={(e) => setCurrentUserToUpdate({ ...currentUserToUpdate, name: e.target.value })}
                            label="given-name"
                            placeholder={t("UserProfile.input.enter_name.placeholder", "Enter Name")}
                        />


                    </WrapperFlexRow>


                    <hr className='myHr' />


                    {/* User lastname  */}
                    <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", alignItems: "flex-end" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("UserProfile.input.lastname.placeholder", "Last name")} </p>
                            <p className='SmallText text-gray'> {t("UserProfile.input.edit_lastname.placeholder", "Edit your Last name")} </p>
                        </WrapperFlexCol>

                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentUserToUpdate.lastname}
                            onChange={(e) => setCurrentUserToUpdate({ ...currentUserToUpdate, lastname: e.target.value })}
                            label="family-name"
                            placeholder={t("UserProfile.input.enter_lastname.placeholder", "Enter Last name")}
                        />


                    </WrapperFlexRow>



                    <hr className='myHr' />


                    {/* User Username  */}
                    <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", alignItems: "flex-end" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("UserProfile.input.username.placeholder", "Username")} </p>
                            <p className='SmallText text-gray'> {t("UserProfile.input.edit_username.placeholder", "Edit your username")} </p>
                        </WrapperFlexCol>


                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentUserToUpdate.username}
                            onChange={(e) => setCurrentUserToUpdate({ ...currentUserToUpdate, username: e.target.value.trim() })}
                            label="nickname"
                            placeholder={t("UserProfile.input.enter_username.placeholder", "Enter Username")}
                        />


                    </WrapperFlexRow>



                    <hr className='myHr' />


                    {/* User Email  */}
                    <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", alignItems: "flex-end" }}>

                        <WrapperFlexCol style={{ padding: "0", maxWidth: "calc(100% / 2)" }}>
                            <p className='DefaultText text-bold'> {t("UserProfile.input.email.placeholder", "E-mail")} </p>
                            <p className='SmallText text-gray'> {t("UserProfile.input.edit_email.placeholder", "Edit your e-mail address")} </p>
                        </WrapperFlexCol>


                        <SingleInput
                            style={{ flex: "1", minWidth: "max-content" }}
                            value={currentUserToUpdate.email}
                            onChange={(e) => setCurrentUserToUpdate({ ...currentUserToUpdate, email: e.target.value.trim() })}
                            label="user-email"
                            placeholder={t("UserProfile.input.enter_email.placeholder", "Enter e-mail")}
                        />


                    </WrapperFlexRow>



                </WrapperFlexCol>

            </DataBox>

        </div >
    )
}

export default UserSettings