// React
import React, { createContext, useContext, useEffect, useState } from 'react';

// Apis 
import { API_GetUserFromToken, API_GetUserNewToken, API_GetUserToken, API_LogoutUser } from '../api/API_user_auth';
import { useTranslation } from 'react-i18next';


import avatars from "../data/avatar_icons.json"

/*
 This file is for checking if we are logged or no (middleware)

 In this file, we also have methods that try to log us in, from access or refresh token 


// HOW IT WORKS:
We store some data in local storage like

- token_exp - access token expiration date
- current_user_local - all data from db about logged in user 
- access and refresh token

First we check if there is some data in localStorage and if the expiration date is still valid (this means our user is safe and we can login automatically)
If there is no data in localStorage, we try to use access_token to get user data 
If access_token is not valid (expired) then we try to refresh this token 
If token is refreshed, we catch user then and save it to localStorage (and our state also gets that user data from localStorage!)

*/

const UserAuthContext = createContext();

export function UserAuthProvider({ children }) {

    const { t } = useTranslation();

    const [isLogged, setIsLogged] = useState(false);
    const [currentUser, setCurrentUser] = useState({});

    const access_token = localStorage.getItem("access_token") || null
    const refresh_token = localStorage.getItem("refresh_token") || null

    // We put user data in localstorage so we can save some bandwith 
    const current_user_local = JSON.parse(localStorage.getItem("current_user_local"))

    // Here we hold information of how long our token is valid
    // So we can load data from localstorage or get new data from db about user 
    // Time of token is defined in Django settings.py
    const token_exp = new Date(localStorage.getItem("token_exp"))
    const currentTime = new Date();
    // Here u specify, what expiration time we add to our tokens when we login / refresh token. It has to be exactly the same as in django settings!
    // Currently set at 3 days!
    const oneWeekInMilliseconds = 3 * 24 * 60 * 60 * 1000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    const token_expiration_time_after_login = new Date(currentTime.getTime() + oneWeekInMilliseconds);


    // Here we store state, that is true when we are done with checking for user (either isLogged = true or false)
    // This was made to help with page refresh to stay on the same page (because our page checks if user is logged in)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // Here we try to login user from local storage 
        if (isLogged === false) {

            console.log("\n[UserAuthProvider]\nTrying to login user from local storage")

            if (access_token && token_exp && current_user_local) {
                // If we have access token and it is valid 
                if (token_exp > currentTime) {
                    if (Object.keys(current_user_local).length > 0) {
                        loginUserFromLocalStorage()
                    }
                    else {
                        console.log("\n[UserAuthProvider]\nNo user data in localStorage!")
                    }

                }
                // If token is not valid, we have to refresh it
                else if (token_exp < currentTime) {
                    console.log("\n[UserAuthProvider]\nUser's access_token expired, trying to use refresh token..")
                    refreshUserToken(refresh_token)
                }
            }

            // Login user with existing token
            else if (access_token) {
                loginUserUsingToken(access_token, refresh_token)
            }

            else if (refresh_token) {
                refreshUserToken(refresh_token)
            }

            // If we dont store any data in localstorage we cannot login user 
            else {
                console.log("\n[UserAuthProvider]\nThere is no data in localStorage to get")
            }

        }

        setIsLoading(false)

    }, [])

    // When user is logged, check his avatar if it belongs to our avatars
    // If not, then assing it to our default not_found.png 
    useEffect(() => {
        if (Object.keys(currentUser).length > 0 && isLogged) {
            validateAvatar()
        }
    }, [isLogged])


    function validateAvatar() {

        let avatar = currentUser.avatar

        // Check if avatar belongs to our avatars 
        const avatarInAvatars = avatars.find((a) => a.avatar_name === avatar);

        if (!avatarInAvatars) {
            console.log("\n[UserAuthProvider > validateAvatar]User's avatar is invalid. Changing it to not_found avatar")
            setCurrentUser({ ...currentUser, avatar: "not_found.png" })
        }

    }


    // We run this once, when user exists in localstorage
    // We want to make sure that this access_token exists and user is found in db 
    async function loginUserFromLocalStorage() {
        try {
            console.log("\n[UserAuthProvider > loginUserFromLocalStorage]\nChecking if data from localStorage is correct")
            const current_user = await API_GetUserFromToken(access_token)

            if (Object.keys(current_user).length > 0 && current_user.id === current_user_local.id && current_user.email === current_user_local.email) {
                // If id and email are the same as local data, we can login user (add to state)
                setCurrentUser({ ...current_user })
                setIsLogged(true);

                localStorage.setItem("current_user_local", JSON.stringify({ ...current_user }))

                console.log("\n[UserAuthProvider > loginUserFromLocalStorage]\nUser logged in from local storage")
            }
            else {
                // If so, the data in localstorage is not like in database, user has to login manualy first
                console.log("\n[UserAuthProvider > loginUserFromLocalStorage]\nThere is local vs db data missmatch, user has to log in manually!")

                setCurrentUser({})
                setIsLogged(false);

                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("current_user_local")
                localStorage.removeItem("token_exp")
            }
        }
        catch (error) {
            console.log("\n[UserAuthProvider > loginUserFromLocalStorage]\nServer is off. If user was logged in, we delete possible existing state!")

            setCurrentUser({})
            setIsLogged(false);

            return { error: error, server_error: true }
        }

    }


    async function loginUser(userEmail, password) {
        /* 
        Login in user when he passes correct username and password, then we set access and refresh token in localstorage 
        */

        if (!isLogged) {

            try {
                console.log("\n[UserAuthProvider > loginUser]\nTrying to login user with: ", userEmail, password)
                const response = await API_GetUserToken(userEmail, password, t)


                if (response.refresh) {
                    localStorage.setItem("refresh_token", response.refresh)
                }

                if (response.access) {
                    localStorage.setItem("access_token", response.access)
                    localStorage.setItem("token_exp", token_expiration_time_after_login)

                    const current_user = await API_GetUserFromToken(response.access)

                    setCurrentUser({ ...current_user })
                    setIsLogged(true);
                    console.log("\n[UserAuthProvider > loginUser]\nUser logged in successfully!")

                    // This is needed so we can check if we can show snackbar ONCE after successful login :) 
                    localStorage.setItem("showSnackbarAfterLogin", "true")

                    localStorage.setItem("current_user_local", JSON.stringify({ ...current_user }))

                    return { loginSuccess: true }
                }

                // If we have some error when loggin in
                if (response && response.detail) {
                    console.log("\n[UserAuthProvider > loginUser]\nError in login..", response)
                    return response
                }
            }
            catch (error) {
                console.log("\n[UserAuthProvider > loginUser]\nServer is off, we cannot login!")
                return { error: error, server_error: true }
            }
        }

        setIsLoading(true)

    }

    async function refreshUserToken(refresh_token) {
        const new_token = await API_GetUserNewToken(refresh_token)

        if (new_token && new_token.access) {

            console.log("\n[UserAuthProvider > refreshUserToken]\nNew access token generated! Saving that in local storage..")

            localStorage.setItem("access_token", new_token.access)

            const current_user_refreshed = await API_GetUserFromToken(new_token.access)

            localStorage.setItem("token_exp", token_expiration_time_after_login)

            setCurrentUser({ ...current_user_refreshed })
            setIsLogged(true);
            localStorage.setItem("current_user_local", JSON.stringify({ ...current_user_refreshed }))

            console.log("\n[UserAuthProvider > refreshUserToken]\nUser logged in from local storage with refresh technique")
        }

        else {
            console.log("\n[UserAuthProvider > refreshUserToken]\nSomething went wrong with API_GetUserNewToken. New token is:", new_token)

            // TO DO: You can add error handling here later
        }
    }

    async function loginUserUsingToken(access_token, refresh_token) {
        /* 
        Here we try to login user with token, access or refresh
        If token is valid, we can then assign user to current state 

        current_user_local is a information about logged user stored in localstorage so we dont have to fetch this data from db
        */

        const current_user = await API_GetUserFromToken(access_token)

        // If our user is found with access token
        if (current_user && current_user.id) {
            setCurrentUser({ ...current_user })
            setIsLogged(true);

            localStorage.setItem("current_user_local", JSON.stringify({ ...current_user }))

            console.log("\n[UserAuthProvider > loginUserUsingToken]\nUser logged in from local storage")
        }

        // REFRESHING TOKEN
        // If access_token is not valid, we try to use refresh token and we update the expiration date also!
        else if ((!current_user || current_user === undefined) && refresh_token) {
            refreshUserToken(refresh_token)
        }
        else {
            console.log("\n[UserAuthProvider > loginUserUsingToken]\nSomething went wrong with loginUserWithExisitngToken")
            console.log("\n[UserAuthProvider > loginUserUsingToken]\nFetched client from API:", current_user)
        }

    }

    async function logoutUser() {
        if (isLogged === true) {
            // Put user access token to blacklist 
            await API_LogoutUser(access_token)

            setCurrentUser({})
            setIsLogged(false)
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("current_user_local")
            localStorage.removeItem("token_exp")
            localStorage.removeItem("showSnackbarAfterLogin")

            console.log("\n[UserAuthProvider > logoutUser]\nUser logged out!")
        }
    }

    return (
        <UserAuthContext.Provider value={{ isLogged, currentUser, setIsLogged, setCurrentUser, logoutUser, loginUser }}>
            {/* NOTE FOR YOU  */}
            {/* Mozemy albo pominac ladowanie albo je pokazac (jest to na tyle szybkie ze mozna pominac)  */}
            {/* {isLoading ? <p>Loading...</p> : children} Conditional rendering */}
            {(!isLoading) && children}
        </UserAuthContext.Provider>
    );
};

export const useUserContext = () => {
    return useContext(UserAuthContext);
};
