// Here we store any helper functions that were used in Calendar.js

// ## VARIABLES ##
export const valid_work_days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
// By the way, we are displaying all stuff related to dates with Monday as FIRST day so 
// By default 0 = Sunday and in our case 0 should be monday! (Important when doing calendar type stuff)


export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];



export const possibleTimeSpans = ['day', 'week', 'month']


// Given dateString as '2024-02-04' it will return proper day of week from daysOfWeek
export function getDayName(dateString, lowerCase = false) {

    // If we want a Proper day with UpperCase like Monday we set lowerCase as false 
    // If we want a proper day with lowerCase all we set lowerCase as true 

    const date = new Date(dateString);
    let dayIndex = date.getDay();

    // We need this because we are counting monday as 0 not Sunday 
    dayIndex = (dayIndex + 6) % 7;

    if (lowerCase === true) {
        return daysOfWeek[dayIndex]
    }

    return valid_work_days[dayIndex];
};


export const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];



// ## Functions ## 

// Returns this array with objects so we can display it in labels (in Add / Edit visit)
export function getVisitTimeIntervals(t) {
    const timeIntervals = [
        { value: 5, label: t("Calendar.timeIntervals.5", "5 minutes") },
        { value: 10, label: t("Calendar.timeIntervals.10", "10 minutes") },
        { value: 15, label: t("Calendar.timeIntervals.15", "15 minutes") },
        { value: 20, label: t("Calendar.timeIntervals.20", "20 minutes") },
        { value: 25, label: t("Calendar.timeIntervals.25", "25 minutes") },
        { value: 30, label: t("Calendar.timeIntervals.30", "30 minutes") },
        { value: 45, label: t("Calendar.timeIntervals.45", "45 minutes") },
        { value: 60, label: t("Calendar.timeIntervals.60", "1 hour") },
        { value: 75, label: t("Calendar.timeIntervals.75", "1 hour 15 minutes") },
        { value: 90, label: t("Calendar.timeIntervals.90", "1 hour 30 minutes") },
        { value: 105, label: t("Calendar.timeIntervals.105", "1 hour 45 minutes") },
        { value: 120, label: t("Calendar.timeIntervals.120", "2 hours") },
        { value: 135, label: t("Calendar.timeIntervals.135", "2 hours 15 minutes") },
        { value: 150, label: t("Calendar.timeIntervals.150", "2 hours 30 minutes") },
        { value: 165, label: t("Calendar.timeIntervals.165", "2 hours 45 minutes") },
        { value: 180, label: t("Calendar.timeIntervals.180", "3 hours") },
        { value: 195, label: t("Calendar.timeIntervals.195", "3 hours 15 minutes") },
        { value: 210, label: t("Calendar.timeIntervals.210", "3 hours 30 minutes") },
        { value: 225, label: t("Calendar.timeIntervals.225", "3 hours 45 minutes") },
        { value: 240, label: t("Calendar.timeIntervals.240", "4 hours") },
        { value: 255, label: t("Calendar.timeIntervals.255", "4 hours 15 minutes") },
        { value: 270, label: t("Calendar.timeIntervals.270", "4 hours 30 minutes") },
        { value: 285, label: t("Calendar.timeIntervals.285", "4 hours 45 minutes") },
        { value: 300, label: t("Calendar.timeIntervals.300", "5 hours") },
        { value: 315, label: t("Calendar.timeIntervals.315", "5 hours 15 minutes") },
        { value: 330, label: t("Calendar.timeIntervals.330", "5 hours 30 minutes") },
        { value: 345, label: t("Calendar.timeIntervals.345", "5 hours 45 minutes") },
        { value: 360, label: t("Calendar.timeIntervals.360", "6 hours") }
    ];

    return timeIntervals
}

// This will return all calendar months from 1 do ... and also 
// This works with day shift, meaning 0 = MONDAY (not Sunday) 
// ALSO this will add days from prev month to fill calendar from left (first row) with zeros 
export const getDaysInMonth = (year, month) => {
    const firstDayOfMonth = new Date(year, month - 1, 1).getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const daysInMonth = new Date(year, month, 0).getDate();

    // Calculate the number of leading zeros needed
    const leadingZeros = Array.from({ length: firstDayOfMonth > 0 ? firstDayOfMonth - 1 : 6 }, () => '0');

    // Days of the current month with leading zeros
    const daysArray = Array.from({ length: daysInMonth }, (_, day) => String(day + 1));

    return [...leadingZeros, ...daysArray];
};


// From calendarDate returns string as 'dd.mm.yyyy' where MM and DD has padStart 0 
export function formatCalendarDate(calendarDate) {
    const formattedMonth = (calendarDate.month.toString()).padStart(2, '0');
    const formattedDay = (calendarDate.day.toString()).padStart(2, '0');
    return `${formattedDay}.${formattedMonth}.${calendarDate.year}`;
}

// DONT TOUCH THAT
// Formats calendarDate for input in add Visit 
export function formatCalendarDateForInput(calendarDate) {
    const formattedMonth = (calendarDate.month.toString()).padStart(2, '0');
    const formattedDay = (calendarDate.day.toString()).padStart(2, '0');
    return `${calendarDate.year}-${formattedMonth}-${formattedDay}`;
}

// DONT TOUCH THAT
// It returns current day in format that visits creation date is!
export function formatDateForCalendarDay() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}



// Helper so we can know what day it is, and use correct dashboard day (start and end time of the company)
// Returns proper key to dictionary to get start and end time of company
export function getWeekdayFromCalendarDate(calendarDate) {
    const date = new Date(calendarDate.year, calendarDate.month - 1, calendarDate.day); // YYYY-MM-DD
    let dayOfWeek = date.getDay();  // from 0 to 6 int 

    // Adjust the dayOfWeek to start from Monday (0) instead of Sunday (0)
    dayOfWeek = (dayOfWeek + 6) % 7;

    return valid_work_days[dayOfWeek].toLowerCase(); // 'monday' or 'tuesday'... 
}

// Helper that uses proper key to get proper start and end of work time in current day for example "08:00x17:00"
export function getTodaysWorkingHours(dashboardData, calendarDate) {
    let today = getWeekdayFromCalendarDate(calendarDate);
    return dashboardData[today];
}


// Returns true if currentTime is between working time 
export function isWorkTime(startTime, endTime) {
    const parseTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes; // Convert time to minutes
    };

    const startMinutes = parseTime(startTime);
    const endMinutes = (endTime === "00:00") ? 24 * 60 : parseTime(endTime); // Adjust for end time at midnight

    // Get current time in minutes
    const now = new Date();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    // Check if current time is within working hours
    if (startMinutes < endMinutes) {
        // Normal scenario where start time is earlier than end time within the same day
        return currentMinutes >= startMinutes && currentMinutes < endMinutes;
    } else {
        // Handle overnight work period crossing midnight
        return currentMinutes >= startMinutes || currentMinutes < endMinutes;
    }
}