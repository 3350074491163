import React from 'react'
import LayoutHero from './LayoutHero/LayoutHero'

function ReportBug() {
    return (
        <LayoutHero>
            <div className='container'>
                BUG
            </div>
        </LayoutHero>
    )
}

export default ReportBug