import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";


// # Dashboard guests (users) and invitations #


// CONTROL PANEL STUFF
// 04.12.2023 Updated with new standard of returning data
export async function API_GetControlPanelInvites(token, hash_id) {
    const URL = `${CURRENT_USING_URL}/dashboard-invitations/get-invitations-for-control-panel?dashboard_hash_id=${hash_id}`;

    console.log("\n[API_GetControlPanelInvites]\nTrying to get invites to users.. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetControlPanelInvites]\nInvites found!")

            const response_json = await response.json();

            console.log("\n[API_GetControlPanelInvites]\nFetched invites array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetControlPanelInvites]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetControlPanelInvites]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetControlPanelInvites]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_InviteNewGuest(token, newInvite) {
    const URL = `${CURRENT_USING_URL}/dashboard-invitations/create-new-invitation`;

    console.log("\n[API_InviteNewGuest]\nTrying to invite guest.. \nCalling: ", URL)
    console.log("\n[API_InviteNewGuest]\nInvite data:\n", newInvite)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(newInvite),
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_InviteNewGuest]\nNew guest added! \n", response_json)

            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_InviteNewGuest]\nSomething went wrong with the response: ", response)
            console.log("\n[API_InviteNewGuest]\nResponse json: \n", response_json)

            if (response_json && response_json.message) {
                return { isOk: false, response: response, error_message: response_json.message, response_json: response_json }
            }

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_DeleteInvite(token, invitation_id) {
    const URL = `${CURRENT_USING_URL}/dashboard-invitations/delete/${invitation_id}/`;


    console.log("\n[API_DeleteInvite]\nTrying to delete invite by id.. \nCalling: ", URL)
    console.log("[API_DeleteInvite]\nInvite id is:", invitation_id)

    try {

        const response = await fetch(URL, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        if (response.ok) {
            // successfully deleted
            console.log("\n[API_DeleteInvite]\nInvite deleted correctly!")
            return { isOk: true, response: response }
        }
        else {
            // something went wrong 
            console.log("\n[API_DeleteInvite]\nInvite could not be deleted!\nResponse:", response)
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }


    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_DeleteInvite]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }

    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_AcceptInvite(token, invite) {
    const URL = `${CURRENT_USING_URL}/dashboard-invitations/accept-invitation`;

    console.log("\n[API_AcceptInvite]\nTrying to accept invite.. \nCalling: ", URL)
    console.log("\n[API_AcceptInvite]\nInvite data:\n", invite)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(invite),
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_AcceptInvite]\nInvitation accepted! \n", response_json)

            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_AcceptInvite]\nSomething went wrong with the response: ", response)
            console.log("\n[API_AcceptInvite]\nResponse json: \n", response_json)

            if (response_json && response_json.message) {
                return { isOk: false, response: response, error_message: response_json.message }
            }

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_DeclineInvite(token, invite) {
    const URL = `${CURRENT_USING_URL}/dashboard-invitations/decline-invitation`;

    console.log("\n[API_DeclineInvite]\nTrying to accept invite.. \nCalling: ", URL)
    console.log("\n[API_DeclineInvite]\nInvite data:\n", invite)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(invite),
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_DeclineInvite]\nInvitation declined! \n", response_json)

            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_DeclineInvite]\nSomething went wrong with the response: ", response)
            console.log("\n[API_DeclineInvite]\nResponse json: \n", response_json)

            if (response_json && response_json.message) {
                return { isOk: false, response: response, error_message: response_json.message }
            }

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.error('Error fetching data:', error);
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_UpdateDashboardGuestPermissions(token, dashboard_id, user_id, edited_user, new_permissions = null) {
    const URL = `${CURRENT_USING_URL}/dashboard/${String(dashboard_id)}/guests/${String(user_id)}/permissions/`;
    // console.log("Token: ", token)

    console.log("\n[API_UpdateDashboardGuestPermissions]\nTrying to update user permissions.. \nCalling: ", URL)
    console.log("\nUser id:", user_id)
    console.log("\nDashboard id:", dashboard_id)
    console.log("\nEdited user:", edited_user)
    console.log("\n(OPTIONAL) new permissions:", new_permissions)

    try {

        const response = await fetch(URL, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ edited_user: edited_user, new_permissions: new_permissions }), // Pass the new_permission in the request body
        });

        // If we fetched client correctly 
        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_UpdateDashboardGuestPermissions]\nUser updated successfully!", response_json)
            return { isOk: true, response: response }
        }
        else {
            const response_json = await response.json();

            console.log("\n[API_UpdateDashboardGuestPermissions]\nSomething went wrong with the response: ", response)
            console.log("\n[API_UpdateDashboardGuestPermissions]\nResponse json: \n", response_json)
            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_UpdateDashboardGuestPermissions]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_GetUserByIdInDashboard(token, dashboard_id, user_id) {
    const URL = `${CURRENT_USING_URL}/users/get-user-by-id-in-dashboard/${String(dashboard_id)}/${String(user_id)}`;

    console.log("\n[API_GetUserByIdInDashboard]\nTrying to find user by id withing dashboard.. \nCalling: ", URL)
    console.log("[API_GetUserByIdInDashboard]\nUser id:", user_id)


    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        // If we fetched client correctly 
        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_GetUserByIdInDashboard]\nUser found!")
            console.log("[API_GetUserByIdInDashboard] Fetched user:", response_json)
            return { isOk: true, response: response, items: response_json }
        }
        else {
            console.log("\n[API_GetUserByIdInDashboard]\nUser not found!")
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_GetUserByIdInDashboard]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// Check if user exists BY EMAIL (and if he does, we return his data)
// Here we check if Any other user exists in our database (made firstly for new dashboard invite)
export async function API_DoesUserExistsByEmail(token, email) {
    const URL = `${CURRENT_USING_URL}/users/check-if-user-exists/${String(email)}`;

    console.log("\n[API_DoesUserExistsByEmail]\nTrying to find if user exists in database.. \nCalling: ", URL)
    console.log("[API_DoesUserExistsByEmail]\nUser email:", email)


    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        // If we fetched client correctly 
        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_DoesUserExistsByEmail]\nUser found!")
            console.log("[API_DoesUserExistsByEmail] Fetched user:", response_json)
            return { isOk: true, response: response, items: response_json }
        }
        else {
            console.log("\n[API_DoesUserExistsByEmail]\nUser not found!")
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_DoesUserExistsByEmail]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_GetDashboardGuestsByDashboard(token, dashboard_id) {
    const URL = `${CURRENT_USING_URL}/dashboard/${String(dashboard_id)}/users/`;

    console.log("\n[API_GetDashboardGuestsByDashboard]\nTrying to get users from dashboard guests.. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetDashboardGuestsByDashboard]\nUsers in dashboard guests found!")

            const response_json = await response.json();

            console.log("\n[API_GetDashboardGuestsByDashboard]\nFetched users array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetDashboardGuestsByDashboard]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetDashboardGuestsByDashboard]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetDashboardGuestsByDashboard]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}



// DASHBOARD / DASHBOARDS STUFF
// 04.12.2023 Updated with new standard of returning data
export async function API_GetDashboardInvitesByUser(token) {
    const URL = `${CURRENT_USING_URL}/dashboard-invitations/get-invitations-by-current-user`;

    console.log("\n[API_GetDashboardInvitesByUser]\nTrying to get dashboard invitations. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_GetDashboardInvitesByUser]\nDashboard invitations found: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetDashboardInvitesByUser]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetDashboardInvitesByUser]\nResponse json: \n", response_json)
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetDashboardInvitesByUser]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_DeleteGuestFromDashboard(token, dashboard_id, guest_id) {
    const URL = `${CURRENT_USING_URL}/dashboard/${dashboard_id}/guest/${guest_id}/delete/`;


    console.log("\n[API_DeleteGuestFromDashboard]\nTrying to delete guest by id.. \nCalling: ", URL)
    console.log("[API_DeleteGuestFromDashboard]\nGuest id is:", guest_id)
    console.log("[API_DeleteGuestFromDashboard]\nDashboard id is:", dashboard_id)

    try {

        const response = await fetch(URL, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        if (response.ok) {
            // successfully deleted
            console.log("\n[API_DeleteGuestFromDashboard]\nGuest deleted correctly!")
            return { isOk: true, response: response }
        }
        else {
            // something went wrong 
            console.log("\n[API_DeleteGuestFromDashboard]\nGuest could not be deleted!\nResponse:", response)
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }


    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_DeleteGuestFromDashboard]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }

    }
}

// 04.12.2023 Updated with new standard of returning data
export async function API_GetSharedDashboardsByUser(token) {
    const URL = `${CURRENT_USING_URL}/dashboard-guests/get-shared-dashboards`;

    console.log("\n[API_GetSharedDashboardsByUser]\nTrying to get shared dashboards. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            const response_json = await response.json();

            console.log("\n[API_GetSharedDashboardsByUser]\nShared dashboards found: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetSharedDashboardsByUser]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetSharedDashboardsByUser]\nResponse json: \n", response_json)
            return { isOk: true, response: response }
        }

    } catch (error) {
        console.log("\n[API_GetSharedDashboardsByUser]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}