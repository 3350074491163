import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { API_CheckIfDashboardExists } from '../api/API_dashboards';
import { API_GetDashboardGuestsByDashboard, API_GetUserByIdInDashboard } from '../api/API_dashboard_guests_and_invitations';

import DashboardNoPermisions from '../pages/Dashboard/Pages/DashboardNoPermisions';
import CheckingHashLoadingPage from '../pages/Dashboard/Pages/CheckingHashLoadingPage';
import { useUserContext } from './UserAuth';
import { API_GetPriceLists } from '../api/API_price_lists';
import { API_GetClientsByDashboard } from '../api/API_dashboard_clients';
import { API_GetActivities } from '../api/API_activities';
import { isPermissionValid } from '../helper/_UserPermissions';

// This is our middleware when user gets on to path like /dashboard
// when /dashboard path is shown this will run everytime to check if 
// we found page or we have correct hash and permisions

const DashboardContext = createContext();

export function DashboardContextProvider({ children }) {

    const token = localStorage.getItem("access_token")

    const { isLogged, currentUser } = useUserContext()

    const location = useLocation();
    const navigate = useNavigate();

    // Holds dashboard hash id like 'Mar-906282easda164624-55'
    const dashboardHash = location.pathname.split("/")[2] || ""

    // Holds dashboard Id from hash (we store dashboard id in hash at the end of hash -55 --> 55)
    const dashboardId = dashboardHash.split("-")[2] || "-1"

    // This holds currentDashboard data 
    const [currentDashboard, setCurrentDashboard] = useState({})

    // IMPORTANT 
    // Our /Dashboard can only work properly, if we change this variable to the current path we are in 
    // If we change this, then our app know what component of /Dashboard he has to render 
    // So anytime you change path, remember to use either this variable, or use this function moveToPathInsideDashboard
    const [currentPath, setCurrentPath] = useState(location.pathname)

    // Helper so we can extract what current dashboard menu is selected 
    // It will return 'notes', or 'calendar' etc... 
    const pathParts = currentPath.split('/');
    const currentDashboardMenu = pathParts[pathParts.length - 1];

    // This holds boolean, if hash exists in our db and we belong to it then its True 
    const [isHashValid, setIsHashValid] = useState(false);
    const [isHashValidationDone, setIsHashValidationDone] = useState(false);


    // This is for keeping information about current /dashboard situation, where if we change something and dont save 
    // We can use this value to display some popup that will inform us about it!
    // We had to do it using useRef so it wont reset our layout 
    const dashboardUnsavedChangesRef = useRef(false);

    // True means we have unsaved changes
    // False means we dont have unsaved changes 
    // Remeber that dashboardUnsavedChangesRef.current hold value! NOT dashboardUnsavedChangesRef itself!
    function updateDashboardUnsavedChanges(newValue) {
        console.log("[@DashboardContext > updateDashboardUnsavedChanges] \nSidebar lock moved to: ", newValue)
        dashboardUnsavedChangesRef.current = newValue;
    };

    // Here we store WHO is the current dashboard order (is it guest or owner)
    // Only two possible values "Guest" and "Owner"
    const [currentDashboardOwner, setCurrentDashboardOwner] = useState("")
    // Here we hold all information from db about dashboard owner 
    const [currentDashboardOwnerData, setCurrentDashboardOwnerData] = useState("")

    // Here we store permissions for user
    // 1. If we are the owner it will hold "Owner"
    // 2. If we are the dashboardGuest it will hold any of ['Manager', 'Employee', 'Guest'] 
    const [currentDashboardPermissions, setCurrentDashboardPermissions] = useState("")


    // const [dashboardCurrency, setDashboardCurrency] = useState(null)


    // DATA FOR MULTIPLE Files also

    const [dashboardGuests, setDashboardGuests] = useState([])
    const [isGuestsFetched, setIsGuestsFetched] = useState(false)

    // Here we hold all data about users (guests + currentUser / owner)
    // So we can use user data using ID 
    // 24: {username: 'Kladzia', email: 'kladzia@gmail.com', avatar: '004-woman-1.png', permissions: 'Restricted', work_time_from: '8:00', …}
    // 27: {id: 27, email: 'marcel@gmail.com', username: 'Marcel', avatar..
    const [dashboardUsersAsDictionary, setDashboardUsersAsDictionary] = useState({})



    // DATA FOR PriceList 
    const [dashboardPriceLists, setDashboardPriceLists] = useState([])
    const [isPriceListsFetched, setIsPriceListsFetched] = useState(false)



    // DATA FOR Activities 
    const [activities, setActivities] = useState([])
    const [activitiesLoaded, setActivitiesLoaded] = useState(false)



    // DATA FOR clients 
    const [clients, setClients] = useState([])
    const [clientsLoaded, setClientsLoaded] = useState(false)


    useEffect(() => {
        if (isLogged) {
            console.log("\n\n[@DashboardContext] runs (User is logged in)");
            checkIfHashIsValid();
        }

        // eslint-disable-next-line
    }, [isLogged]);

    useEffect(() => {
        if (isHashValidationDone && !isHashValid) {
            console.log("\n[@DashboardContext] Hash validation done, but hash is not valid.");
            setIsHashValidationDone(true);
        } else if (isHashValidationDone && isHashValid) {
            console.log("\n[@DashboardContext] Hash validation done, and hash is valid.");
            loadDashboardPriceLists();
            loadDashboardClients();
            loadDashboardGuests();
            loadActivities();
        }


        // eslint-disable-next-line
    }, [isHashValidationDone, isHashValid]);

    useEffect(() => {
        console.log("\n[@DashboardContext]\nMoving to: \n", location.pathname, "\nWith hash: ", dashboardHash);

        if (dashboardHash !== location.pathname.split("/")[2]) {
            setIsHashValid(false);
            setIsHashValidationDone(false);
            checkIfHashIsValid();
        }

        if (location.pathname !== currentPath) {
            console.log("\n[@DashboardContext]\nURL mismatch in dashboard, updating dashboard state to current URL.");
            setCurrentPath(location.pathname);
        }


        // eslint-disable-next-line
    }, [location]);

    async function checkIfHashIsValid() {

        if (token && isLogged) {
            const response = await API_CheckIfDashboardExists(token, dashboardHash)

            if (response && response.items && response.items.exists === true) {
                // hash exists and is valid 
                console.log("\n[@DashboardContext > checkIfHashIsValid]\nHash is valid")
                const current_dash = { ...response.items.dashboard }
                setIsHashValid(true)
                setCurrentDashboard(current_dash)

                // Here we check if we joined dashboard that is our or we are the guest 
                if (currentUser && current_dash && currentUser.id === current_dash.dashboard_owner) {
                    setCurrentDashboardOwner("Owner")
                    setCurrentDashboardOwnerData(currentUser)
                    setCurrentDashboardPermissions("Owner") // Here we know for sure if current user is the owner so permissions are OWNER 
                    console.log("\n[@DashboardContext > checkIfHashIsValid]\nWe are the dashboard owner")

                }
                else {
                    // If we are the guest we fetch owner data so we can display it somewhere like in controll panel 
                    setCurrentDashboardOwner("Guest")

                    const guest_user_data = await API_GetUserByIdInDashboard(token, current_dash.id, current_dash.dashboard_owner)
                    if (guest_user_data && guest_user_data.items) {
                        setCurrentDashboardOwnerData(guest_user_data.items)

                        console.log("\n[@DashboardContext > checkIfHashIsValid]\nWe are the dashboard guest")
                    }
                    else if (guest_user_data && guest_user_data.error_message) {
                        console.log("\n[@DashboardContext > checkIfHashIsValid]\nSomething went wrong! Error message:\n")
                        console.log(guest_user_data.error_message)
                    }
                    else {
                        console.log("\n[@DashboardContext > checkIfHashIsValid]\nSomething went wrong!")
                    }

                }
            }
            else if (response && response.items && response.items.exists === false) {
                // hash not valid / not exists
                console.log("\n[@DashboardContext > checkIfHashIsValid]\nHash is not valid")

                setIsHashValid(false)
                navigate("/dashboards")
            }
            else {
                console.log("\n[@DashboardContext > checkIfHashIsValid]\nServer is off (We got no response!)")
                setIsHashValid(false)
                navigate("/dashboards")
            }

        }

        setIsHashValidationDone(true)

    }

    // Loads dashboard guests and also creates a guests (users) + owner dictionary like
    // We treat dashboard guests as all users (with owner too) 
    // 24: {username: 'Kladzia', email: 'kladzia@gmail.com', avatar: '004-woman-1.png', permissions: 'Restricted', work_time_from: '8:00', …}
    // 27: {id: 27, email: 'marcel@gmail.com', username: 'Marcel', avata
    async function loadDashboardGuests() {
        if (token && currentDashboard.id) {
            const response = await API_GetDashboardGuestsByDashboard(token, currentDashboard.id);

            if (response && response.isOk && response.items) {
                console.log(`\n[@DashboardContext > loadDashboardGuests] Dashboard guests loaded correctly!`);

                // Here we are mixing dashboardGuests with Owner Data (we get owner user working hours from dashboard model )
                const dashboardGuests = [{ ...currentDashboardOwnerData, working_hours: currentDashboard.working_hours_owner }, ...response.items]

                // Reorder the array so that the current user appears first
                const reorderedDashboardGuests = dashboardGuests.sort((a, b) => {
                    if (a.id === currentUser.id) {
                        return -1; // Place the current user at the beginning
                    } else if (b.id === currentUser.id) {
                        return 1; // Place the current user after other guests
                    }
                    return 0; // Maintain the order for other guests
                });

                setDashboardGuests(reorderedDashboardGuests);


                if (response.items && (response.items.length > 0 || Object.keys(currentDashboardOwnerData).length > 0)) {

                    const usersDictionary = {};


                    for (const user of dashboardGuests) {
                        const { id, ...rest } = user;

                        // Because we gather all users, we need to find currentUser (not owner) to get his permissions 
                        // We have to make sure that this user is not the owner 
                        // This currently works fine 
                        if (currentUser && currentUser.id === id && currentDashboard.dashboard_owner !== currentUser.id) {
                            if (isPermissionValid(user.permissions)) {
                                setCurrentDashboardPermissions(user.permissions)
                            }
                            else {
                                setCurrentDashboardPermissions("None")
                            }

                        }

                        usersDictionary[id] = rest;
                    }

                    setDashboardUsersAsDictionary({ ...usersDictionary });
                    console.log("[@DashboardContext > loadDashboardGuests] Users as Dictionary: ", usersDictionary);
                }
            } else if (response && response.error_message) {
                console.log(`\n[@DashboardContext > loadDashboardGuests] Something went wrong with the fetch.`);
                console.log(`\n[@DashboardContext > loadDashboardGuests] ${response.error_message}`);
            } else {
                console.log(`\n[@DashboardContext > loadDashboardGuests] Something went wrong with the fetch`);
            }

            setIsGuestsFetched(true); // New state to track guests fetching completion
        }
    }



    async function loadActivities() {
        if (token && currentDashboard.id) {
            const response = await API_GetActivities(token, currentDashboard.id);

            if (response && response.isOk && response.items) {
                console.log(`\n[@DashboardContext > loadActivities] Activities lists loaded correctly!`);
                setActivities(response.items);

            } else if (response && response.error_message) {
                console.log(`\n[@DashboardContext > loadActivities] Something went wrong with the fetch.`);
                console.log(`\n[@DashboardContext > loadActivities] ${response.error_message}`);
            } else {
                console.log(`\n[@DashboardContext > loadActivities] Something went wrong with the fetch`);
            }

            setActivitiesLoaded(true); // New state to track guests fetching completion
        }
    }



    async function loadDashboardPriceLists() {
        if (token && currentDashboard.id) {
            const response = await API_GetPriceLists(token, currentDashboard.id);

            if (response && response.isOk && response.items) {
                console.log(`\n[@DashboardContext > loadDashboardPriceLists] Dashboard price lists loaded correctly!`);
                setDashboardPriceLists(response.items);

            } else if (response && response.error_message) {
                console.log(`\n[@DashboardContext > loadDashboardPriceLists] Something went wrong with the fetch.`);
                console.log(`\n[@DashboardContext > loadDashboardPriceLists] ${response.error_message}`);
            } else {
                console.log(`\n[@DashboardContext > loadDashboardPriceLists] Something went wrong with the fetch`);
            }

            setIsPriceListsFetched(true); // New state to track guests fetching completion
        }
    }

    async function loadDashboardClients() {
        if (token && currentDashboard.id) {
            const response = await API_GetClientsByDashboard(token, currentDashboard.id);

            if (response && response.isOk && response.items) {
                console.log(`\n[@DashboardContext > loadDashboardClients] Clients loaded correctly!`);
                setClients(response.items);

            } else if (response && response.error_message) {
                console.log(`\n[@DashboardContext > loadDashboardClients] Something went wrong with the fetch.`);
                console.log(`\n[@DashboardContext > loadDashboardClients] ${response.error_message}`);
            } else {
                console.log(`\n[@DashboardContext > loadDashboardClients] Something went wrong with the fetch`);
            }

            setClientsLoaded(true); // New state to track guests fetching completion
        }
    }




    // This is important!, Whenever we move inside /dashboard we have to change state also!
    function moveToPathInsideDashboard(newPath) {
        console.log("\n[@DashboardContext] Moving to new path within dashboard:\n", newPath)
        setCurrentPath(newPath)
        navigate(newPath)
    }

    return (
        <DashboardContext.Provider value={{ activities, currentDashboardPermissions, clients, setClients, dashboardPriceLists, setDashboardPriceLists, dashboardUsersAsDictionary, dashboardGuests, currentDashboardMenu, currentDashboardOwnerData, currentDashboardOwner, updateDashboardUnsavedChanges, dashboardUnsavedChangesRef, setCurrentDashboard, currentDashboard, dashboardHash, currentPath, setCurrentPath, dashboardId, moveToPathInsideDashboard }}>

            {/* This means that we are still checking hash  */}
            {(isHashValidationDone === false || isGuestsFetched === false || isPriceListsFetched === false || clientsLoaded === false || activitiesLoaded === false) ?
                <CheckingHashLoadingPage />
                :
                // This checks if hash is valid and we are done validating him and guests are fetched
                // This weird nested ifs actually prevent from refreshing layout. Keep it as it is 
                (isHashValid && isHashValidationDone && isGuestsFetched === true && isPriceListsFetched === true && clientsLoaded === true && activitiesLoaded === true) ?
                    children
                    :
                    (isHashValidationDone === true && !isHashValid)
                        ?
                        <DashboardNoPermisions />
                        :
                        <CheckingHashLoadingPage />

            }
        </DashboardContext.Provider>
    );
}

export const useDashboardContext = () => {
    return useContext(DashboardContext);
};