import React, { useEffect, useState } from 'react'
import { API_STRIPE_GetAllInvoices } from '../../../../api/API_Stripe'
import { useUserContext } from '../../../../context/UserAuth'
import { LoadingAnimationFourDots, WrapperFlexCol, WrapperFlexRow } from '../../../../components/Elements/Wrappers'
import { getDateFromTimeStamp } from '../../../../helper/dateFunctions'
import classes from "./shared.module.scss"
import { useTranslation } from 'react-i18next'
import { translateInvoiceStatus } from '../../../../translations/translation_helpers'

function Invoices() {


    const token = localStorage.getItem("access_token")
    const { currentUser } = useUserContext()
    const { t } = useTranslation()

    const [invoicesByDashboard, setInvoicesByDashboard] = useState({})

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        loadUserInvoices()


    }, [currentUser.id])

    async function loadUserInvoices() {

        if (token && currentUser && currentUser.id) {

            const response = await API_STRIPE_GetAllInvoices(token, currentUser.id)

            if (response.isOk && response.items) {
                setInvoicesByDashboard(response.items.invoices_by_dashboard)
                // console.log(response.items)
            }

            else {
                console.log("Something went wrong ")
                console.log(response)
            }

            // console.log(response)
            setIsLoading(false)

        }
        else {
            setIsLoading(false)
        }

    }

    return (
        <div>

            <WrapperFlexRow className={"WrapperTitle"} style={{ flexWrap: "wrap", gap: "1rem" }}>
                <h2 className='SectionTitleText'> {t("UserSettings.invoices", "Invoices")} </h2>
            </WrapperFlexRow>

            <p className='SmallText' style={{ marginBottom: "2rem" }}>
                {t("UserSettings.invoices_text", "Here you will find all invoices for the subscription plans you have purchased.")}
            </p>

            {(isLoading === false && Object.keys(invoicesByDashboard).length !== 0) ?

                Object.entries(invoicesByDashboard).map(([dashboardName, invoices]) => {


                    // We can have empty invoicesByDashboard arrays 
                    if (invoicesByDashboard[dashboardName].length !== 0 && invoicesByDashboard[dashboardName][0]) {


                        let dashboard_name = invoicesByDashboard[dashboardName][0].dashboard_name

                        // Here we hold bool if dashboard exists or not (we show FV for all dashboards that belong to user)
                        let dashboard_exists = invoicesByDashboard[dashboardName][0].dashboardExists

                        return (
                            <div key={dashboardName}>


                                {/* Dashboard name  */}
                                <p className='DefaultText text-bold' style={{ marginBottom: "1rem", marginTop: "2rem" }}>  {dashboard_exists === false && t("UserSettings.deleted_dashboard", "[Deleted]")} {dashboard_name}</p>


                                <div className={classes.TableWrapper}>
                                    <table className={classes.Table}>

                                        <thead>
                                            <tr>
                                                <th className='DefaultText text-bold'> {t("UserSettings.id", "Id")}  </th>
                                                <th className='DefaultText text-bold'> {t("UserSettings.number", "Number")} </th>
                                                <th className='DefaultText text-bold'> {t("UserSettings.desc", "Description")} </th>
                                                <th className='DefaultText text-bold'> {t("UserSettings.status", "Status")}  </th>
                                                <th className='DefaultText text-bold'> {t("UserSettings.subtotal", "Subtotal ")} </th>
                                                <th className='DefaultText text-bold'> {t("UserSettings.for_period", "For the period")}  </th>
                                                <th className='DefaultText text-bold'> {t("UserSettings.invoice_link", "Invoice link ")} </th>
                                            </tr>
                                        </thead>


                                        <tbody>

                                            {invoices.map((invoice, index) => {

                                                let status_translated = translateInvoiceStatus(invoice.status, t) || "NaN"

                                                return (
                                                    <tr key={index}>

                                                        <td className='DefaultText'> {index + 1}. </td>
                                                        <td className='SmallText'> {invoice.number} </td>
                                                        <td className='SmallerText'> {invoice.description} </td>
                                                        <td className='SmallText'> {status_translated} </td>
                                                        <td className='SmallText'> {parseFloat(invoice.amount / 100).toFixed(2)} {invoice.currency.toUpperCase()} </td>
                                                        <td className='SmallText'> {getDateFromTimeStamp(invoice.period_start)} - {getDateFromTimeStamp(invoice.period_end)} </td>
                                                        <td className='DefaultText' style={{ textAlign: "center" }}> <a target={"_blank"} className={'as_link_new linkPrimary'} href={invoice.hosted_invoice_url} rel="noreferrer"> Link </a> </td>

                                                    </tr>
                                                )
                                            })}


                                        </tbody>

                                    </table>
                                </div>


                            </div>
                        )


                    }

                })

                :

                <WrapperFlexCol style={{ alignItems: "center" }}>
                    <LoadingAnimationFourDots />
                </WrapperFlexCol>
            }





            <p className='SmallText' style={{ marginTop: "1rem" }}>
                {t("UserSettings.contact", "If you need help or have any questions, please contact us at")} <a className='as_link linkPrimary' href="mailto:support@annualty.com">support@annualty.com</a>
            </p>


        </div>
    )
}

export default Invoices