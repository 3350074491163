// React
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Styles
import classes from "../styles/styles_pages/Login.module.scss"
import muStyles from "../styles/mu-styles.module.scss"

// Layout
import LayoutHero from './Entrance/LayoutHero/LayoutHero'

// Context
import { useUserContext } from '../context/UserAuth'

// Icons / SVG
import rightImage from "../static/hero-img2.png"
import { ReactComponent as IconLogin } from "../static/n_icons/Email.svg"
import { ReactComponent as IconPassword } from "../static/n_icons/Lock.svg"
// import { ReactComponent as IconGoogle } from "../static/icons/Google.svg"
// import { ReactComponent as IconFacebook } from "../static/icons/Facebook.svg"


// Mu Components
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next'
import { ErrorContainer } from '../components/Elements/FormMessageBoxes'
import { ButtonPrimaryFill } from '../components/Elements/Buttons'
import { WrapperFlexCol, WrapperFlexRow } from '../components/Elements/Wrappers'
import { LabelLeftSingleIcon } from '../components/Elements/FormLabels'
import { SingleInput } from '../components/Elements/FormInputs'



// Login Page 

function Login() {

    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();

    // Context
    const { loginUser, isLogged } = useUserContext()

    // Here is our state for user email and password to be logged in with 
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    // Use this to check if we have some errors while loggin in! 
    // Also this displays error message 
    const [loginError, setLoginError] = useState([]);

    // MU Logic for Password show / hide 
    const [showPassword, setShowPassword] = useState(false);

    // MU Logic for Password show / hide 
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleNavigation = (route) => {
        // Change current page with "/route" 
        navigate(route);
    };

    async function handleLogin(e) {
        e.preventDefault();
        let errors = [];


        if (isLogged) {
            handleNavigation("/")
        }
        else if (!user.email || !user.password) {
            errors.push(t("validationErrors.enter_all_required_fields", "Enter values to all required fields!"))
        }
        else if (user.email && user.password) {
            console.log("Trying to log in user")

            try {
                const response = await loginUser(user.email, user.password)

                if (response && response.detail) {
                    errors = errors.concat(response.detail)
                }

                else if (response && response.loginSuccess) {
                    console.log("Success!")


                    // Redirect to the intended page (e.g., dashboard)
                    const intendedPage = location.state?.from || "/"; // Update with the correct URL
                    handleNavigation(intendedPage);
                }

                else if (response && response.isOk === false && response.error_message) {
                    errors.push(response.error_message)
                }
                else {
                    errors.push(t("Common.something_went_wrong", "Something went wrong. Try again later."))
                }
            }
            // If we are here, we couldn't register. Might be because server is off or we have no connection!
            catch (error) {
                errors.push(error.message)
            }
        }

        console.log(errors)
        setLoginError(errors)
    }


    return (
        <LayoutHero>

            <div className={classes.LoginContainer}>


                <div className="container is-max-desktop">

                    <div className={classes.LoginContainerWrapper}>

                        {/* <div className={classes.LogoBox}>
                            <img src={logo} alt={"Logo"} className={classes.Logo} />
                            <p className='DefaultText text-gray'> Magic happens here </p>
                        </div> */}

                        <div className={classes.LoginBox}>

                            <div className={classes.LoginBoxLeft}>

                                <h2 className='BigText text-bold'> {t("Common.log_in", "Login")} </h2>


                                <WrapperFlexRow style={{ padding: "0" }}>
                                    <p className={"DefaultText"}>
                                        {t("Login.no_account_yet", "Dont't have an account yet?")}

                                    </p>

                                    <p className={"DefaultText as_link_new text_no_wrap linkPrimary"} onClick={() => { handleNavigation('/signin') }} >
                                        {t("Common.sign_in", "Sign in")}
                                    </p>
                                </WrapperFlexRow>

                                {/* Error message */}

                                {(loginError.length > 0) && (
                                    <ErrorContainer errors={loginError} />
                                )}

                                <WrapperFlexCol on_submit={handleLogin} as_form={true} style={{ padding: "0" }} className={classes.FormContainer}>

                                    {/* Login / Email  */}
                                    <LabelLeftSingleIcon LeftIcon={IconLogin} LabelText={t("Signin.email_label", "Enter your email")} />

                                    {/* <TextField autoComplete="on" onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} className={muStyles.mu_TextFiledInput} fullWidth id="outlined-basic" label={t("Signin.email_placeholder", "Email Address")} variant="outlined" /> */}
                                    <SingleInput onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} placeholder={t("Signin.email_placeholder", "Email Address")} />



                                    {/* Password with hide option  */}
                                    <LabelLeftSingleIcon LeftIcon={IconPassword} LabelText={t("Signin.password_label", "Enter password")} />

                                    <SingleInput type={"password"} autoComplete="on" value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} placeholder={t("Signin.password_placeholder", "Password")} />



                                    {/* Forgot password  */}
                                    <WrapperFlexRow style={{ padding: "0", marginBottom: "1rem" }}>
                                        <a className={"DefaultText as_link linkPrimary text_no_wrap"} href="/forgot-password"> {t("Login.forgot_password", "Forgot Password")} </a>
                                    </WrapperFlexRow>



                                    {/* Submit login  */}
                                    <ButtonPrimaryFill type="submit" onClick={handleLogin} className={"BtnFullWidth BtnShadow"} text={t("Common.log_in", "Log in")} />

                                </WrapperFlexCol>

                                {/* <div className={classes.OrSignUpContainer}>
                                    <hr /> {t("Login.or_log_in_with", "Or sign in with")} <hr />
                                </div> */}

                                {/* To do: Add Google and Facebook login  */}
                                {/* <div className={classes.MediaLoginContainer}>
                                    <Button className={muStyles.mu_btnOutlinedGoogle} variant="outlined" startIcon={<IconGoogle />}> Google  </Button>
                                    <Button className={muStyles.mu_btnOutlinedFacebook} variant="outlined" startIcon={<IconFacebook />}> Facebook </Button>
                                </div> */}

                            </div>


                            {/* Right side graphic */}
                            <div className={classes.LoginBoxRight}>
                                <div className={classes.LoginBoxRightWrapper}>
                                    <img src={rightImage} alt="Login graphic" />
                                    <p className='SmallText text-gray' style={{ textAlign: "center" }}>
                                        {t("Login.increased_efficiency_message", "Increased efficiency is just around the corner with Annualty.")}
                                    </p>
                                </div>


                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </LayoutHero >


    )
}

export default Login