


// ========== NAME VALIDATION ==========
// (dcn-n02)
function isDashboardNameInProperLength(name, t) {
    let errors = []

    if (name.length > 128 || name.length < 3) {
        errors.push(t("validationErrors.dashboard_name_length", "Name must be between 3 and 128 characters.") + " (dcn-n02)")
    }

    return errors
}

// (dcn-n01)
function isDashboardNameProperCharacters(name, t) {

    // If empty we dont return anything because we will inform about length of name etc..
    if (!name.trim()) {
        return [];
    }

    // Define a regular expression pattern for a valid name
    // A-z0-9 and spaces
    const pattern = /^[^\d!@#$%^&*()_+\]{}|\\:;"'<>,.?/`~]*$/;


    // Test if the name matches the pattern
    const isValid = pattern.test(name);

    if (!isValid) {
        // If the name does not match the pattern, return an error message
        return [t("validationErrors.name_invalid_characters", "Invalid characters in the name. Use only alphanumeric characters.") + " (dcn-n01)"];
    }

    // If the name passes the validation, return null (indicating no error)
    return [];
}

export function isDashboardNameValid(name, t) {
    let errors = []

    errors.push(...isDashboardNameProperCharacters(name, t))
    errors.push(...isDashboardNameInProperLength(name, t))

    return errors;
}













// ========== DESCRIPTION VALIDATION ==========
// (dcn-n03)
function isDashboardDescriptionInProperLength(description, t) {
    let errors = []

    if (description.length > 512 || description.length < 3) {
        errors.push(t("validationErrors.dashboard_description_length", "Description must be between 3 and 512 characters.") + " (dcn-d01)")
    }

    return errors
}

// (dcn-n04)
function isDashboardDescriptionProperCharacters(description, t) {

    // If empty we dont return anything because we will inform about length of name etc..
    if (!description.trim()) {
        return [];
    }

    // Define a regular expression pattern for a valid description
    const pattern = /^[^!@#$%^&*()_+\-=\]{}|;:'",.<>/?\\`~]*$/;


    // Test if the description matches the pattern
    const isValid = pattern.test(description);

    if (!isValid) {
        // If the description does not match the pattern, return an error message
        return [t("validationErrors.description_invalid_characters", "Invalid characters in the description. Use only alphanumeric characters and space.") + " (dcn-d02)"];
    }

    // If the description passes the validation, return null (indicating no error)
    return [];
}

export function isDashboardDescriptionValid(name, t) {
    let errors = []

    errors.push(...isDashboardDescriptionInProperLength(name, t))
    errors.push(...isDashboardDescriptionProperCharacters(name, t))

    return errors;
}
