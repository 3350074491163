import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translations/en.json';
import translationPL from './translations/pl.json';
import translationDE from './translations/de.json'; // Import the German translation file

const userLanguage = navigator.language.split('-')[0];
console.log("[i18n] Using language: ", userLanguage)

const defaultLanguage = (() => {
    switch (userLanguage) {
        case 'pl':
            return 'pl';
        default:
            return 'en';
    }
})();

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN,
            },
            pl: {
                translation: translationPL,
            },
            de: {
                translation: translationDE,
            },
        },
        fallbackLng: defaultLanguage, // Set the default language based on user's browser language
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
