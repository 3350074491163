// React
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

// Our components
import Login from "./pages/Login";
import Signin from "./pages/Signin";
import DashboardWrapper from "./pages/Dashboard";

// Dashboard components
import Calendar from "./pages/Dashboard/Pages/Calendar";
import Clients from "./pages/Dashboard/Pages/Clients";
import Notes from "./pages/Dashboard/Pages/Notes";
import Settings from "./pages/Dashboard/Pages/Settings";
import HomeDashboard from "./pages/Dashboard/Pages/HomeDashboard";
import ControlPanel from "./pages/Dashboard/Pages/ControlPanel";
import Activities from "./pages/Dashboard/Pages/Activities";


// Here are all component / element styles that we share across website
import "./components/Elements/Elements_styles/Buttons.scss"
import "./components/Elements/Elements_styles/FormButtons.scss"
import "./components/Elements/Elements_styles/FormInputs.scss"
import "./components/Elements/Elements_styles/FormLabels.scss"
import "./components/Elements/Elements_styles/FormMessageBoxes.scss"
import "./components/Elements/Elements_styles/Wrappers.scss"
import "./pages/Dashboard/Dashboard_Styles_Shared.scss"

// Here is a correct place to import styles that are global. 
// Here they are in correct order! 
import "./styles/Base.scss"
import "./styles/globals.scss"
import "./styles/text.scss"
import "./styles/animations.scss"
import "./styles/theme_colors.scss"
import "./styles/svg_styles.scss"

// App Context
import { useUserContext } from "./context/UserAuth";

// Pages
import Analytics from "./pages/Dashboard/Pages/Analytics";
import Client from "./pages/Dashboard/Pages/Client";
import Dashboards from "./pages/Dashboards";
import DashboardNotFound from "./pages/Dashboard/Pages/DashboardNotFound";
import PageNotFound from "./pages/PageNotFound";
import PriceList from "./pages/Dashboard/Pages/PriceList";
import UserSettingsLayout from "./pages/Entrance/UserSettings/UserSettings_Layout";
import ChangePassword from "./pages/Entrance/UserSettings/SubPages/ChangePassword";
import Invoices from "./pages/Entrance/UserSettings/SubPages/Invoices";
// import BillingInformation from "./pages/Entrance/UserSettings/SubPages/BillingInformation";
import ChangePlan from "./pages/Entrance/UserSettings/SubPages/ChangePlan";
import CancelSubscription from "./pages/Entrance/UserSettings/SubPages/CancelSubscription";
import ReportBug from "./pages/Entrance/ReportBug";
import Payment_Success from "./pages/Stripe/Payment_Success";
import PaymentMethods from "./pages/Entrance/UserSettings/SubPages/PaymentMethods";


export default function AppRouter() {

    const { isLogged } = useUserContext();


    // Move from "/" to "/Dashboards"
    useEffect(() => {
        let currentPath = window.location.pathname
        if (currentPath === "/") {
            window.location.href = "/dashboards"
        }
    }, [])

    // This amazing code, checks if user is logged in or not, if yes he wont be able to access login or sign in!
    const AuthRoute = ({ path, element }) => {
        if (isLogged && (path === "/login" || path === "/signin")) {
            return <Navigate to="/dashboards" replace />;
        }

        return element;
    };

    // This checks if we are logged in, if we are we go where we want, if not, we have to login 
    // Also, if user comes from /dashboard etc. it will be redirected to that page after login !
    const AccessOnlyIfLoggedIn = ({ path, element }) => {
        const navigate = useNavigate();
        const location = useLocation();

        const current_user = localStorage.getItem("current_user_local")
        useEffect(() => {
            // Assume isLogged is a state that indicates whether the user is logged in
            if (!current_user || Object.keys(current_user).length === 0) {
                navigate("/login", { state: { from: location.pathname } });
            }

        }, [navigate, location.pathname]);

        return element;
    };

    return (
        <BrowserRouter>

            <Routes>
                {/* Redirect from root to a specific page */}
                <Route path="/" element={<Navigate to="/dashboards" />} />

                {/* Login  */}
                <Route path="/login" element={<AuthRoute path="/login" element={<Login />} />} />

                {/* Sign in  */}
                <Route path="/signin" element={<AuthRoute path="/signin" element={<Signin />} />} />



                {/* Dashboard and sub pages  */}
                <Route path="/dashboard/:dashboard_hash_id" element={<AccessOnlyIfLoggedIn path="/dashboard:dashboard_hash_id" element={<DashboardWrapper />} />} >
                    <Route path="home" element={<HomeDashboard />} />
                    <Route path="calendar" element={<Calendar />} />
                    <Route path="price-list" element={<PriceList />} />
                    <Route path="notes" element={<Notes />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="analytics" element={<Analytics />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="control-panel" element={<ControlPanel />} />
                    <Route path="activities" element={<Activities />} />

                    {/* Add a nested route to capture the client_id */}
                    <Route path="client/:client_id" element={<Client />} />

                    <Route path="*" element={<DashboardNotFound />} />
                </Route>




                {/* Dashboards  */}
                <Route path="/dashboards" element={<AccessOnlyIfLoggedIn path="/dashboards" element={<Dashboards />} />} />

                <Route path="/report-a-bug" element={<AccessOnlyIfLoggedIn path="/report-a-bug" element={<ReportBug />} />} />




                {/* User Settings  */}
                <Route path="/user-settings" element={<AccessOnlyIfLoggedIn path="/user-settings" element={<UserSettingsLayout />} />}>

                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="invoices" element={<Invoices />} />
                    {/* <Route path="billing-information" element={<BillingInformation />} /> */}
                    <Route path="payment-methods" element={<PaymentMethods />} />
                    <Route path="change-plan" element={<ChangePlan />} />
                    <Route path="cancel-subscription" element={<CancelSubscription />} />


                </Route>



                {/* Stripe stuff  */}
                <Route path="/stripe/payment-success" element={<AccessOnlyIfLoggedIn path="/payment-success" element={<Payment_Success />} />} />


                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}