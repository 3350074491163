








// ========== EMAIL VALIDATION ==========

// (cv-nl01)
// (cv-nl02)
function isNameLastnameCorrect(nameLastname, t) {
    let errors = [];

    // Define a regular expression pattern for a valid name or lastname
    const pattern = /^[^\d!@#$%^&*()_+=\[\]{}|\\:;"'<>,.?/`~]*$/;

    // Test if the nameLastname matches the pattern
    const isValidFormat = pattern.test(nameLastname);

    // Check if the nameLastname length is within the allowed range (adjust min and max values as needed)
    const isValidLength = nameLastname.length >= 1 && nameLastname.length <= 100;

    if (!isValidFormat) {
        errors.push(t("validationErrors.name_lastname_characters", "Invalid characters in the name or lastname. Use only alphabetical characters and spaces.") + " (cv-nl01)");
    }
    if (!isValidLength) {
        errors.push(t("validationErrors.name_lastname_length", "Name or lastname must be between 1 and 100 characters long.") + " (cv-nl02)");
    }

    return errors;
}

export function isNameLastnameValid(name, lastname, t) {
    let errors = [];

    // Return only one information not both
    errors.push(...isNameLastnameCorrect(name, t));

    if (errors.length === 0) {
        errors.push(...isNameLastnameCorrect(lastname, t));
    }



    return errors;
}