import React, { useEffect, useState } from 'react'
import { useUserContext } from '../../../../context/UserAuth'
import { useTranslation } from 'react-i18next'
import { API_STRIPE_ChangePaymentMethodRequest, API_STRIPE_EditCard, API_STRIPE_GetPaymentMethods } from '../../../../api/API_Stripe'
import { LoadingAnimationFourDots, TwoActionButtonsWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../../components/Elements/Wrappers'
import classes from "./shared.module.scss"
import { getDateFromTimeStamp } from '../../../../helper/dateFunctions'
import { ButtonNeutralToGray, ButtonNeutralToLightPrimary, ButtonPrimaryFill } from '../../../../components/Elements/Buttons'
import { ButtonSave } from '../../../../components/Elements/FormButtons'
import PopupLayout from '../../../Dashboard/Layout/PopupLayout'
import { DangerousWarrningContainer, ErrorContainer, SuccessContainer } from '../../../../components/Elements/FormMessageBoxes'
import { SingleInput, TextFieldInput } from '../../../../components/Elements/FormInputs'
import { isEmailValid } from '../../../../utils/data_validators/signin_validator'


function PaymentMethods() {

    const token = localStorage.getItem("access_token")
    const { currentUser } = useUserContext()
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(true)


    // Here we hold all paymentMethods 
    const [paymentMethodsByDashboard, setPaymentMethodsByDashboard] = useState({})



    const [showEditCardPopup, setShowEditCardPopup] = useState(false)
    const [selectedCardDetails, setSelectedCardDetails] = useState(null)

    useEffect(() => {

        loadPaymentMethods()


    }, [])


    async function loadPaymentMethods() {

        if (token && currentUser && currentUser.id) {

            const response = await API_STRIPE_GetPaymentMethods(token, currentUser.id)

            if (response.isOk && response.items) {
                setPaymentMethodsByDashboard(response.items.payment_methods_by_dashboard)
                // console.log(response.items.payment_methods_by_dashboard)
            }

            else {
                console.log("Something went wrong ")
                console.log(response)
            }

            // console.log(response)
            setIsLoading(false)

        }
        else {
            setIsLoading(false)
        }


    }






    function handleChangeCardChange(method, dashboardName) {

        if (method) {
            setSelectedCardDetails({ ...method, dashboardName: dashboardName })
            setShowEditCardPopup(true)
        }

    }

    return (
        <div>
            {showEditCardPopup && <EditCardPopup onClose={() => setShowEditCardPopup(false)} editCard={selectedCardDetails} />}


            {/* Title  */}
            <WrapperFlexRow className={"WrapperTitle"} style={{ flexWrap: "wrap", gap: "1rem" }}>

                <WrapperFlexCol style={{ padding: "0", gap: "0", width: "unset" }}>

                    <h2 className='SectionTitleText'> {t("UserSettings.payment_methods", "Payment methods")} </h2>

                </WrapperFlexCol>


            </WrapperFlexRow>

            <p className='SmallText' style={{ marginBottom: "2rem" }}>
                {t("UserSettings.payment_methods_text", "On this page, you will find the payment methods you have added, along with detailed information about payments for each of your dashboards.")}
            </p>

            {(isLoading === false) ?

                Object.entries(paymentMethodsByDashboard).map(([dashboardKey, paymentMethods]) => {


                    return (
                        <div key={dashboardKey}>


                            {/* Dashboard name  */}
                            <p className='DefaultText text-bold' style={{ marginBottom: "1rem", marginTop: "2rem" }}> {dashboardKey}</p>


                            <div className={classes.TableWrapper}>
                                <table className={classes.Table}>

                                    <thead>
                                        <tr>
                                            <th className='DefaultText text-bold'> {t("UserSettings.id", "Id")}  </th>
                                            <th className='DefaultText text-bold' style={{ width: "50px" }}> &nbsp; </th>
                                            <th className='DefaultText text-bold' style={{ width: "150px" }}> {t("UserSettings.card", "Card")} </th>
                                            <th className='DefaultText text-bold'> {t("UserSettings.4Digits", "Last 4 digits")}  </th>
                                            <th className='DefaultText text-bold'> {t("UserSettings.endDate", "Expiration date")} </th>
                                            <th className='DefaultText text-bold' style={{ width: "150px" }}> &nbsp; </th>
                                            <th className='DefaultText text-bold' style={{ width: "150px" }}> &nbsp; </th>
                                            {/* <th className='DefaultText text-bold'> {t("UserSettings.change_plan_section.action", "Action")}  </th> */}
                                        </tr>
                                    </thead>


                                    <tbody>

                                        {paymentMethods.map((method, index) => {

                                            return (
                                                <tr key={index}>

                                                    <td className='DefaultText'> {index + 1}. </td>
                                                    <td className='DefaultText'> <input style={{ appearance: "auto" }} type="radio" name={`card-default-${dashboardKey}`} defaultChecked={method.is_default} /> </td>
                                                    <td className='SmallText'> {method.card.brand} </td>
                                                    <td className='SmallText'> **** {method.card.last4} </td>
                                                    <td className='SmallText'> {method.card.exp_month} / {method.card.exp_year} </td>
                                                    <td className='SmallText'> <p style={{ textAlign: "center", width: "min-content" }} className={`${method.is_default ? 'text_primary_bg SmallText' : null}`}> {(method.is_default) ? t("UserSettings.default_card", "Default payment card") : null} </p> </td>
                                                    <td className='SmallText'> <ButtonNeutralToLightPrimary onClick={() => { handleChangeCardChange(method, dashboardKey) }} text={t("UserSettings.edit_card", "Edit card")} />  </td>
                                                    {/* <td className='SmallText'> {(!method.is_default) && <ButtonNeutralToLightPrimary text={t("UserSettings.set_as_default", "Set as default")} />} </td> */}
                                                </tr>
                                            )
                                        })}


                                    </tbody>

                                </table>
                            </div>


                        </div>
                    )

                })

                :

                <WrapperFlexCol style={{ alignItems: "center" }}>
                    <LoadingAnimationFourDots />
                </WrapperFlexCol>
            }

            <p className='SmallText' style={{ marginTop: "1rem" }}>
                {t("UserSettings.contact", "If you need help or have any questions, please contact us at")} <a className='as_link linkPrimary' href="mailto:support@annualty.com">support@annualty.com</a>
            </p>

        </div>
    )
}



export function EditCardPopup({ onClose, editCard }) {

    const { t } = useTranslation()
    const { currentUser } = useUserContext()
    const token = localStorage.getItem("access_token")

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false)

    const [requestDetails, setRequestDetails] = useState({
        userEmail_Account: currentUser.email, // Original user email for verification
        customer_id: editCard.customer,
        payment_method_id: editCard.id,
        userEmail_Typed: currentUser.email, // Email that we send request of payment change 
        additionalInfo_Typed: "", // Aditional info from user
        selectedDashboard_name: editCard.dashboardName
    })


    async function handleSubmit(e) {

        e.preventDefault()

        let errors = []

        const emailValdation = isEmailValid(requestDetails.userEmail_Typed, t)

        if (emailValdation.length > 0) errors = errors.concat(emailValdation)

        if (currentUser && token && errors.length === 0) {

            console.log(requestDetails)

            const response = await API_STRIPE_ChangePaymentMethodRequest(token, currentUser.id, requestDetails)

            console.log(response)

            if (response.isOk) {
                setIsSuccess(true)
            }
        }

        if (errors) {
            setErrors(errors)
        }

    }

    return (
        <PopupLayout closePopup={onClose} popupTitle={t("UserSettings.edit_card", "Edit card")} isHeroLayout={true}>


            <WrapperFlexCol style={{ padding: "0" }} as_form={true} on_submit={handleSubmit}>

                {/* Error message */}
                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("UserSettings.send_request_success", "Request to change payment method sent successfully!") + "."} />
                }

                <p className='DefaultText text_left_error_border'> {t("UserSettings.card_request_text", "To edit your card details, please fill out this form and submit your request. We will send you an email with a link to update your current card information.")}</p>

                <p className='SmallerText text-gray'> {t("UserSettings.edit_card_text", "You will receive an email from us as soon as possible, within a maximum of 24 hours.")}</p>

                <WrapperFlexRow style={{ padding: "0", marginTop: "1rem" }}>
                    <SingleInput
                        style={{ width: "100%" }}
                        autocomplete="email"
                        placeholder={t("Signin.email_placeholder", "adres@domain.com")}
                        type="text"
                        value={requestDetails['userEmail_Typed']}
                        onChange={(e) => setRequestDetails({ ...requestDetails, userEmail_Typed: e.target.value })}
                        required
                    />

                    <div className='SingleInput' style={{ width: "100%" }}>
                        <input
                            type="text"
                            defaultValue={requestDetails['selectedDashboard_name']}
                            disabled={true}
                        />
                    </div>
                </WrapperFlexRow>

                <TextFieldInput
                    required
                    value={requestDetails['additionalInfo_Typed']}
                    autocomplete="email"
                    label={t("UserSettings.additional_text", "Additional text")}
                    onChange={(e) => setRequestDetails({ ...requestDetails, additionalInfo_Typed: e.target.value })}
                />

                <TwoActionButtonsWrapper
                    onCancel={onClose}
                    onSave={handleSubmit}
                    cancelLabel={t("Common.cancel", "Cancel")}
                    saveLabel={t("Common.send", "Send")}
                    as_form={true}
                    isSaveDisabled={!requestDetails.userEmail_Typed}
                />


            </WrapperFlexCol>

        </PopupLayout>

    )
}




export default PaymentMethods