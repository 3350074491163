


// Translated categories that we use in search bar (next to it)
export function getSearchCategories(t) {
    const searchCategories = [
        { value: `search_by_name_and_lastname`, label: t("Clients.category_name_lastname", `Name and Lastname`) },
        { value: `search_by_phone`, label: t("Clients.category_phone", `Phone number`) },
        { value: `search_by_symbol`, label: t("Clients.category_symbol", `Symbol`) },
    ]

    return searchCategories
}