// React 
import React from 'react';
import ReactDOM from 'react-dom/client';

// MAIN router component
import AppRouter from './AppRouter';

// App Context 
import { UserAuthProvider } from './context/UserAuth';
import { DeviceProvider } from './context/DeviceRecognizer';
import { SnackbarWrapper } from './components/SnackbarWrapper';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ThemeProvider } from './context/ThemeProvider';
import App from './App';

// import reportWebVitals from './reportWebVitals';

const RootComponent = () => { // Access the toggleTheme function from the context

  return (
    // <React.StrictMode>
    <ThemeProvider>
      <I18nextProvider i18n={i18n}>
        <UserAuthProvider>
          <DeviceProvider>
            <SnackbarWrapper>
              <App>
                <AppRouter />
              </App>
            </SnackbarWrapper>
          </DeviceProvider>
        </UserAuthProvider>
      </I18nextProvider>
    </ThemeProvider>
    // </React.StrictMode> 
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send
