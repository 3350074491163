// React 
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


// Elements / Wrappers
import { DataBox, TitleSectionIconWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { PriceListTag } from './PriceList'


// Icons 
import { ReactComponent as SadIcon } from "../../../static/n_icons/Neutral.svg"

// APIs 
import { API_GetActivities } from '../../../api/API_activities'

// Context
import { useDashboardContext } from '../../../context/DashboardContext'
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext'


// Helpers 
import { formatDateTime } from '../../../helper/dateFunctions'
import { formatTimeRange } from '../../../helper/funcs'




function Activities() {

    const { t } = useTranslation()

    const { currentDashboard } = useDashboardContext()
    const { sharedActivities, setSharedActivities } = useSharedDashboardContext()
    const { dashboardUsersAsDictionary, moveToPathInsideDashboard, dashboardHash } = useDashboardContext()

    const token = localStorage.getItem("access_token")

    const [nextCursor, setNextCursor] = useState(null)
    const [nextActivitiesLoading, setNextActivitiesLoading] = useState(false)


    // Load / Silent load newest activities
    useEffect(() => {
        loadNewestActivities()

        // eslint-disable-next-line
    }, [])



    // "Silent fetch" when we enter the activities 
    async function loadNewestActivities() {
        if (token && currentDashboard.id) {
            const response = await API_GetActivities(token, currentDashboard.id);

            if (response && response.isOk && response.items) {
                console.log(`\n[@Activities > loadNewestActivities] Activities lists loaded correctly!`);
                setSharedActivities(response.items);

                if (response.next) {
                    // Get cursor from response.next
                    const urlParams = new URLSearchParams(response.next.split('?')[1]);
                    const cursorValue = urlParams.get('cursor');
                    setNextCursor(cursorValue);
                }
                else {
                    setNextCursor(null)
                }

            } else if (response && response.error_message) {
                console.log(`\n[@Activities > loadNewestActivities] Something went wrong with the fetch.`);
                console.log(`\n[@Activities > loadNewestActivities] ${response.error_message}`);
            } else {
                console.log(`\n[@Activities > loadNewestActivities] Something went wrong with the fetch`);
            }
        }
    }

    // Load next activites using cursor pagination (with nextCursor variable)
    async function loadNextActivities() {
        if (token) {
            setNextActivitiesLoading(true)
            const response = await API_GetActivities(token, currentDashboard.id, nextCursor)

            if (response.isOk && response.items) {
                const new_activities = response.items;

                if (response.next) {
                    // Get cursor from response.next
                    const urlParams = new URLSearchParams(response.next.split('?')[1]);
                    const cursorValue = urlParams.get('cursor');
                    setNextCursor(cursorValue);
                }
                else {
                    setNextCursor(null)
                }

                if (new_activities) {
                    setSharedActivities(prevVisits => [...prevVisits, ...new_activities]);
                }


            }
            else {
                console.log("[@Activities > loadNextActivities] Something is wrong!")
                console.log(response)
            }


            setNextActivitiesLoading(false)
        }
    }

    return (
        <div className={"container is-max-desktop"}>


            {/* Section title */}
            <WrapperFlexRow className={"WrapperTitle"}>

                <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                    <h2 className="SectionTitleText"> {t("Sidebar.activities", "Activities")} </h2>
                    <p className="DefaultText text_gray_on_layout"> {t("Activities.under_title", "Check what is happening in your company")}  </p>

                </WrapperFlexCol>

            </WrapperFlexRow>



            {(sharedActivities && sharedActivities.length > 0) ?

                <DataBox className={"onLayout HideScrollbar"}>

                    {(sharedActivities && sharedActivities.length > 0) &&

                        sharedActivities
                            .sort((a, b) => new Date(b.activity_creation_date) - new Date(a.activity_creation_date)) // Sort activities in descending order
                            .map((activity, idx) => {
                                return (
                                    <React.Fragment key={`activity-${idx}`}>
                                        <SingleActivity activity={activity} dashboardUsersAsDictionary={dashboardUsersAsDictionary} moveToPathInsideDashboard={moveToPathInsideDashboard} dashboardHash={dashboardHash} />
                                        {(idx < sharedActivities.length - 1)
                                            && <hr className='myHr' />
                                        }

                                    </React.Fragment>
                                )
                            })}


                    {(nextCursor !== null)
                        &&
                        <p className={"LoadMoreText"} onClick={loadNextActivities}> {(nextActivitiesLoading === false) ? t("Client.load_more", "Load more") : t("Client.loading", "Loading..")} </p>
                    }

                </DataBox>


                :


                <WrapperFlexCol className={'DashboardErrorContainer removeBoxShadow'} style={{ alignItems: "center", height: "100%", minHeight: "150px", padding: "4rem 1rem" }}>

                    <TitleSectionIconWrapper stroke_fill={false} icon={<SadIcon />} />

                    <p style={{ textAlign: "center", marginTop: "1rem" }} className='DefaultText text-gray'>
                        {t("Activities.oops_not_found", "Oops, we could not find any activity in your dashboard")}
                    </p>

                    <p style={{ textAlign: "center" }} className='SmallText text-gray'>
                        {t("Activities.add_something", "When you perform any action on the board, it will be recorded here.")}
                    </p>

                </WrapperFlexCol>
            }



        </div>
    )
}



export function SingleActivity({ activity, dashboardUsersAsDictionary, moveToPathInsideDashboard, dashboardHash }) {


    const { t } = useTranslation()



    // For css styles 
    function getProperActionClass(dashboard_action) {

        switch (dashboard_action) {
            case "Added": return "activity_added"
            case "Edited": return "activity_edited"
            case "Deleted": return "activity_deleted"
            default: return ""
        }
    }

    function getProperUserActivityOwner(activity) {

        // console.log(activity)

        // If we are proper user exisiting in dashboard
        if (dashboardUsersAsDictionary[activity.activity_owner]) {
            return dashboardUsersAsDictionary[activity.activity_owner].username
        }

        // If user was deleted, we want to get his username that was added from backend 
        // So we could still print who was it 
        else if (!dashboardUsersAsDictionary[activity.activity_owner] && activity.additional_content && activity.additional_content.deleted_user_username) {
            return activity.additional_content.deleted_user_username
        }

        // Username of user that deleted himself 
        else if (!dashboardUsersAsDictionary[activity.activity_owner] && activity.additional_content && activity.additional_content.deleted_himself === "yes") {
            return activity.additional_content.username
        }

        else {
            return ""
        }

    }

    const action_translated = {
        "Added": t("Activities.added", "Added"),
        "Edited": t("Activities.edited", "Edited"),
        "Deleted": t("Activities.deleted", "Deleted")
    }

    const type_translated = {
        "Visit": t("Activities.visit", "visit"),
        "Client": t("Activities.client", "client"),
        "Note": t("Activities.note", "note"),
        "User": t("Activities.user", "user"),
        "Service": t("Activities.service", "service")
    }


    let username = getProperUserActivityOwner(activity)
    let activity_creation_date_formated = formatDateTime(activity.activity_creation_date)

    return (

        // We need to make this check if there is user name because if I (ADMIN) delete user from dashboard 
        // There will be some activities with not connected username this is intentional and it is an edge case 
        // Avaliable only for me :)
        // Any other case will have username properly included 

        (username) &&
        <WrapperFlexCol style={{ padding: "0", justifyContent: "space-between", height: "unset" }}>

            <WrapperFlexRow style={{ padding: "0", gap: "0.4rem", flexWrap: "wrap", height: "unset", width: "unset" }}>

                {/* Username  */}
                <p className='text-capitalize DefaultText text-bold'>{username}</p>

                {/* Action  */}
                <span className={`SmallText text_light_bold text_gray_bg ${getProperActionClass(activity.dashboard_action)}`} style={{ padding: "2px 0.75rem" }}>{action_translated[activity.dashboard_action]}</span>

                {/* activity type  */}
                <span className='DefaultText text_light_bold'>{type_translated[activity.activity_type]}</span>

                <RenderProperContent t={t} dashboardHash={dashboardHash} moveToPathInsideDashboard={moveToPathInsideDashboard} dashboard_action={activity.dashboard_action} additional_content={activity.additional_content} activity_type={activity.activity_type} />

            </WrapperFlexRow>



            <WrapperFlexRow style={{ padding: "0", width: "unset", height: "unset" }}>
                <p className='SmallText text_light_bold'>{activity_creation_date_formated[0]} </p>
                <p className='SmallText text-gray'> {activity_creation_date_formated[1]} </p>
            </WrapperFlexRow>

        </WrapperFlexCol>
    )
}


// Render proper element based on activity_type 
function RenderProperContent({ additional_content, activity_type, dashboard_action, moveToPathInsideDashboard, dashboardHash, t }) {

    switch (activity_type) {
        case "Visit":

            let visit_time_span = formatTimeRange(additional_content.start_hour, additional_content.start_minute, additional_content.length)

            return (
                <>

                    <p className='DefaultText'> {t("Activities.for", "for")} </p>

                    <p className='DefaultText text_light_bold as_link_new linkPrimary text_no_wrap' onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${additional_content.visit_client.id}`)}> {additional_content.visit_client.name} {additional_content.visit_client.lastname} </p>

                    <p className='DefaultText'> {t("Activities.on_day", "on day")} </p>

                    <p className={"DefaultText text-gray"}>
                        {formatDateTime(additional_content.visit_date)[0]} {(visit_time_span) && <> {t("Activities.at", "at")} {visit_time_span} </>}
                    </p>
                </>
            );

        case "Client":

            return (

                <>

                    {dashboard_action !== "Deleted" ?
                        <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/client/${(additional_content.id) ? (additional_content.id) : "-1"}`)} className='DefaultText text_light_bold as_link_new linkPrimary text_no_wrap'> {additional_content.name} {additional_content.lastname} </p>
                        :
                        <p className='DefaultText text_light_bold text_no_wrap'> {additional_content.name} {additional_content.lastname} </p>
                    }
                </>


            );

        case "Service":

            return (

                <>

                    <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/price-list`)} className='DefaultText text_light_bold as_link_new linkPrimary text_no_wrap'>
                        {additional_content.name}
                    </p>

                    <PriceListTag bg={additional_content.color} text={additional_content.tag} />

                </>
            );


        case "User":

            // We print different stuff if user deleted himself from dashboard 
            if (dashboard_action === "Deleted" && additional_content.deleted_himself === "yes") {
                return (
                    <>

                        <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/control-panel`)} className='DefaultText text_light_bold as_link_new linkPrimary text_no_wrap'>
                            {additional_content.username}
                        </p>

                        <p className='SmallText text-gray'>
                            {t('Activities.user_left', `User left this dashboard`)}
                        </p>


                    </>
                )
            }

            return (

                <>

                    <p onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/control-panel`)} className='DefaultText text_light_bold as_link_new linkPrimary text_no_wrap'>
                        {additional_content.username}
                    </p>

                    <p className='SmallText text-gray'>
                        {additional_content.email}
                    </p>


                </>
            );

        default:
            return "";
    }
}

export default Activities