



// All functions that are used accross multiple API functions 

const HEROKU_BACKEND_URL = "https://kladzia-app-heroku-09b03e308dec.herokuapp.com/api"
const LOCAL_URL = "http://localhost:8000/api"

export const CURRENT_USING_URL = HEROKU_BACKEND_URL;

export const server_off_message = "500 - Internal Server Error"

// THE PATTERN WE FOLLOW WHEN WE RETURN SOMETHING TO FRONT END! 

// UPDATE
// > Success
// return { isOk: boolean, response: response }
// > Error
// return { isOk: boolean, response: response, error_message: check_status_code(response.status) }

// CREATE 
// [new_item] is anything that was created (like user or client) as single dictionary
// > Success
// return { isOk: boolean, response: response, new_item: response_json}
// > Error
// return { isOk: boolean, response: response, error_message: check_status_code(response.status)}

// Delete 
// > Success
// return { isOk: boolean, response: response}
// > Error
// return { isOk: boolean, response: response, error_message: check_status_code(response.status)}

// GET 
// [item] anything that will be gathered from database as array of dictionaries
// [other_errors] any errors that were checked here before doing a fetch as ['x', 'x', 'x']
// > Success
// return { isOk: boolean, response: response, items: response_json}
// > Error
// return { isOk: boolean, response: response, error_message: check_status_code(response.status), other_errors}


// This checks status code and returns proper error message
export function check_status_code(status_code) {
    console.log("Status_code: ", status_code);

    switch (status_code) {
        case 500:
            return "500 - Internal Server Error. Try again later";
        case 400:
            return "400 - Bad request";
        case 401:
            return "401 - You don't have permissions to do that!";
        case 403:
            return "403 - Forbidden";
        case 404:
            return "404 - Not found";
        case 429:
            return "429 - Too many requests";
        default:
            return "500 - Internal Server Error. Try again later";
    }
}
