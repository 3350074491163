
// React 
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { useEffect } from "react";

// Context
import { useUserContext } from "../../../context/UserAuth";
import { useDashboardContext } from "../../../context/DashboardContext";
import { useSharedDashboardContext } from "../../../context/SharedDashboardDataContext";

// Svg / Icons 
import { ReactComponent as WalletIcon } from "../../../static/n_icons/Wallet.svg"
import { ReactComponent as TwoUsersIcon } from "../../../static/n_icons/Users.svg"
import { ReactComponent as CalendarVisitsIcon } from "../../../static/n_icons/CalendarCircle.svg"
import { ReactComponent as CalendarLatestVisitIcon } from "../../../static/n_icons/CalendarLast.svg"
import { ReactComponent as ArrowRightIcon } from "../../../static/n_icons/ArrowSmallRight.svg"
import { ReactComponent as GemIcon } from "../../../static/n_icons/Gem_2.svg"
import { ReactComponent as FoundErrorIcon } from "../../../static/n_icons/foundError.svg"


// Wrappers / elements 
import { ButtonPrimaryErrorFill } from "../../../components/Elements/Buttons";
import { SingleActivity } from "./Activities";
import { DataBox, IconBox, LinkWithSVG, PrettyDate, VisitBox, WrapperFlexCol, WrapperFlexRow, WrapperGrid2, WrapperGrid4 } from "../../../components/Elements/Wrappers"


// Apis 
import { API_GetTodayVisits } from "../../../api/API_Home";


// Helpers
import { getDayName } from "../../../helper/_calendar";
import { translateDayOfWeek } from "../../../translations/translation_helpers";
import { getCurrentDateFormatted } from "../../../helper/dateFunctions";





function HomeDashboard() {

    const { t } = useTranslation();
    const { currentUser } = useUserContext()
    const { moveToPathInsideDashboard, dashboardHash, currentDashboard, dashboardUsersAsDictionary } = useDashboardContext()
    const { dashboardCurrency, todayVisits, setTodayVisits, dashboardUsers, sharedActivities } = useSharedDashboardContext()

    const token = localStorage.getItem("access_token")

    useEffect(() => {
        loadTodayVisits()

        // eslint-disable-next-line
    }, [])

    async function loadTodayVisits() {
        if (token && currentUser.id) {

            const response = await API_GetTodayVisits(token, currentDashboard.id)

            if (response.isOk && response.items) {
                const sortedVisits = response.items.sort((a, b) => {
                    // Compare start_hour first
                    if (a.start_hour !== b.start_hour) {
                        return a.start_hour - b.start_hour;
                    }
                    // If start_hour is the same, compare start_minute
                    return a.start_minute - b.start_minute;
                });
                setTodayVisits(sortedVisits);
            }
            else {
                setTodayVisits([])
            }
        }
    }

    const currentDayOfWeek = getDayName(new Date(), true);


    const countTodayRevenue = () => {

        if (todayVisits && todayVisits.length > 0) {
            let todayRevenue = todayVisits.reduce((totalRevenue, visit) => {
                // Parse the price to float with maximum 2 decimal points
                const visitPrice = parseFloat(visit.price).toFixed(2);
                // Add the parsed price to the total revenue
                return totalRevenue + parseFloat(visitPrice);
            }, 0); // Initialize totalRevenue with 0

            return todayRevenue
        }

        return 0

    }


    const countUsersWorkingToday = (users, dayOfWeek) => {
        // Initialize the count
        let count = 0;

        // Iterate through each user
        users.forEach(user => {

            // Check if the user has working hours for today
            if (user.working_hours[dayOfWeek] && user.working_hours[dayOfWeek] !== "closexclose") {
                // Increment the count if working hours are not "closexclose"
                count++;
            }
        });

        // Return the count of users working today
        return count;
    };

    // Function to filter the upcoming visits based on current time
    // returns only 2 closests visits to current hour
    const filterUpcomingVisits = (visits) => {

        if (visits && visits.length > 0) {

            const currentHour = new Date().getHours(); // Get the current hour
            const currentMinute = new Date().getMinutes(); // Get the current minutes

            // Filter visits based on the conditions
            const upcomingVisits = visits.filter(visit => {
                // Check if assigned_worker_id matches currentUser.id or if it's null/undefined
                const assignedWorkerId = visit.visit_assigned_worker_id;
                const isCurrentUserAssigned = assignedWorkerId === currentUser.id || !assignedWorkerId;

                // Extract hours and minutes from visit start time
                const hours = visit.start_hour;
                const minutes = visit.start_minute;

                // Check if start time is in the expected format and is after the current time (16:05)
                return !isNaN(hours) && !isNaN(minutes) &&
                    (hours > currentHour || (hours === currentHour && minutes >= currentMinute)) &&
                    isCurrentUserAssigned;
            });

            // Return the filtered upcoming visits
            return upcomingVisits;

        }

        return []
    };


    const findLatestVisitStartTime = (todayVisits) => {

        if (todayVisits && todayVisits.length > 0) {

            // Iterate through the sorted array in reverse order
            for (let i = todayVisits.length - 1; i >= 0; i--) {
                const visit = todayVisits[i];
                // Check if currentUser.id matches visit.visit_assigned_worker_id
                if (visit.visit_assigned_worker_id === currentUser.id) {
                    // Construct the visit start time string
                    const startTime = `${visit.start_hour.toString().padStart(2, '0')}:${visit.start_minute.toString().padStart(2, '0')}`;
                    return startTime; // Return the visit start time
                }
            }
        }


        return "-"; // Return an empty string if no matching visit is found
    };

    // Filter the upcoming visits
    const upcomingVisits = filterUpcomingVisits(todayVisits);


    let todayRevenue = countTodayRevenue();
    let todayWorkingUsers = countUsersWorkingToday(dashboardUsers, currentDayOfWeek)

    let latestVisitStartTime = findLatestVisitStartTime(todayVisits)

    const latestActivities = sharedActivities.slice(0, 3)


    const DUMMY_BLOG = [
        {
            title: "Jak przyspieszyć zarządzanie wizytami w Annualty.",
            date: "2024-02-01"
        },
        {
            title: "Od Marca nastąpi zmiana cen dotychczasowych subskrypcji. Ostatni moment na dołączenie do premium po niższych cenach.",
            date: "2024-01-01"
        }
        ,
        {
            title: "Nowa opcja dodania klienta już jest!",
            date: "2023-12-31"
        }
    ]

    console.log(currentDayOfWeek)

    return (
        <div className="container is-max-desktop">

            {/* Title  */}
            <WrapperFlexRow className={"WrapperTitle"} style={{ justifyContent: "space-between", flexWrap: "wrap", marginBottom: "1rem" }}>

                <h2 className="SectionTitleText"> {t("Home.welcome", "Welcome")} <p className="text-capitalize" style={{ display: "inline-block" }}> {currentUser.username}! </p> </h2>

                <DataBox className={"onLayout"}>
                    <WrapperFlexRow style={{ padding: "0", width: "unset", justifyContent: "flex-end", gap: "1rem" }}>
                        <PrettyDate date={getCurrentDateFormatted()} className={"borderRight"} />
                        <p className="BigText text_light_bold">{translateDayOfWeek(getDayName(new Date(), true), t)}</p>
                    </WrapperFlexRow>
                </DataBox>

            </WrapperFlexRow>



            <WrapperFlexCol style={{ padding: "0", gap: "16px" }}>

                {/* 4 Info boxes Upper */}
                <WrapperGrid4 className={"onTablet smallerGap"}>

                    {/* Today revenue  */}
                    <DataBox className={"onLayout HideScrollbar smallerGap"}>
                        <WalletIcon className={"SVG_STROKE_PRIMARY"} />

                        <p className="DataBoxValueText text_no_wrap">
                            <CountUp start={0} end={todayRevenue} duration={1} decimals={2} /> {dashboardCurrency.Symbol || ""}
                        </p>

                        <p className="SmallText"> {t("Home.today_earnings", "Today's earnings")} </p>
                    </DataBox>

                    {/* Today workers  */}
                    <DataBox className={"onLayout HideScrollbar smallerGap"}>
                        <TwoUsersIcon className={"SVG_STROKE_PRIMARY"} />

                        <p className="DataBoxValueText text_no_wrap">
                            <CountUp start={0} end={todayWorkingUsers} duration={2} />
                        </p>

                        <p className="SmallText"> {t("Home.today_working", "People working today")} </p>
                    </DataBox>

                    {/* Today visits  */}
                    <DataBox className={"onLayout HideScrollbar smallerGap"}>

                        <CalendarVisitsIcon className={"SVG_STROKE_PRIMARY"} />

                        <p className="DataBoxValueText text_no_wrap">
                            <CountUp start={0} end={(todayVisits) && todayVisits.length} duration={2} />
                        </p>

                        <p className="SmallText"> {t("Home.today_visits", "Today's visits")} </p>
                    </DataBox>

                    {/* Today latest visit time  */}
                    <DataBox className={"onLayout HideScrollbar smallerGap"}>

                        <CalendarLatestVisitIcon className={"SVG_STROKE_PRIMARY"} />

                        <p className="DataBoxValueText text_no_wrap"> {latestVisitStartTime} </p>

                        <p className="SmallText"> {t("Home.latest_visit", "Latest visit")} </p>
                    </DataBox>

                </WrapperGrid4>





                {/* Incoming Visits / Latest Activity  */}
                <WrapperGrid2 className={"onTablet smallerGap"}>


                    {/* Incoming visits (Upcoming 2 visits) */}
                    <DataBox className={"onLayout HideScrollbar"} style={{ gap: "1rem" }}>

                        <p className="SmallText text-bold"> {t("Home.incoming_visits", "Incoming visits assigned to you")} </p>

                        {/* Visit boxes (2 most upcoming) */}
                        <WrapperFlexCol className={"HideScrollbar"} style={{ padding: "0", gap: "1rem", maxHeight: "200px", overflow: "scroll" }}>

                            {(upcomingVisits && upcomingVisits.length > 0) ?
                                upcomingVisits.map((visit, idx) => {
                                    return (
                                        <VisitBox key={`incoming-${idx}`} visit={visit} />
                                    );
                                })
                                :
                                <WrapperFlexCol className={'DashboardErrorContainer onDefaultWhite removeBoxShadow'} style={{ alignItems: "center", height: "100%" }}>
                                    <p style={{ textAlign: "center" }} className='DefaultText text-gray text-light'>
                                        {t("Home.no_upcoming_visits", "No upcoming visits for today")}
                                    </p>
                                </WrapperFlexCol>
                            }

                        </WrapperFlexCol>


                        <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", height: "unset" }}>
                            <LinkWithSVG navigateTo={`calendar`} text={t("Home.move_to_calendar", "Move to calendar")} SVG={<ArrowRightIcon className={"SVG_STROKE_GRAY"} />} />
                        </WrapperFlexRow>

                    </DataBox>




                    {/* Latest activity (Max 4) */}
                    <DataBox className={"onLayout HideScrollbar"} style={{ gap: "1rem" }}>

                        <p className="SmallText text-bold"> {t("Home.latest_activity", "Latest activities")} </p>

                        <WrapperFlexCol style={{ padding: "0", gap: "1rem" }}>


                            {/* Activity text  */}
                            <WrapperFlexCol className={"HideScrollbar"} style={{ padding: "0", gap: "1rem", maxHeight: "200px", overflow: "scroll" }}>

                                {latestActivities.map((activity, idx) => {

                                    return (
                                        <SingleActivity activity={activity} key={`activity-home-${idx}`} dashboardUsersAsDictionary={dashboardUsersAsDictionary} moveToPathInsideDashboard={moveToPathInsideDashboard} dashboardHash={dashboardHash} />
                                    )

                                })}

                            </WrapperFlexCol>

                        </WrapperFlexCol>

                        <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end", height: "unset" }}>
                            <LinkWithSVG navigateTo={`activities`} text={t("Home.move_to_activities", "Move to activities")} SVG={<ArrowRightIcon className={"SVG_STROKE_GRAY"} />} />
                        </WrapperFlexRow>

                    </DataBox>



                </WrapperGrid2>







                {/* Buy premium / Learn annualty */}
                <WrapperGrid2 className={"onTablet smallerGap"}>


                    {/* Buy premium  */}
                    <DataBox className={"onLayout HideScrollbar premiumBox"}>

                        <WrapperFlexRow style={{ padding: "0", gap: "1rem" }}>
                            <IconBox className={"premiumBox"} SVG={<GemIcon />} />
                            <p className="DefaultText text-bold text_premium"> {t("Home.upgrade_plan", "Upgrade your plan")} </p>
                        </WrapperFlexRow>

                        <IconBox className={"premiumArrow"} navitageToWindow={"/user-settings/change-plan"} SVG={<ArrowRightIcon />} />
                    </DataBox>




                    {/* Learn annualty  */}
                    <DataBox className={"onLayout HideScrollbar blueGradient"}>

                        <p className="DefaultText text-bold text_white text_no_wrap"> {t("Home.meet_annualty", "Discover Annualty")} </p>

                        <WrapperFlexRow style={{ padding: "0", gap: "1rem", width: "unset" }}>
                            <p className="SmallText text-bold text_white text_gradient_start_bg" style={{ padding: "0.35rem 0.5rem", borderRadius: "8px", fontFamily: "Verdana" }}> 1/8 </p>
                            <IconBox className={"blueGradientArrow"} navitageToWindow={"/learn"} SVG={<ArrowRightIcon />} />
                        </WrapperFlexRow>


                    </DataBox>


                </WrapperGrid2>






                {/* Blog / Found error section */}
                <WrapperGrid2 className={"onTablet FirstElementBigger smallerGap"}>


                    {/* Blog box  */}
                    <DataBox className={"onLayout HideScrollbar smallerGap"}>

                        <WrapperFlexRow style={{ padding: "0", justifyContent: "space-between" }}>
                            <p className="SmallText text-bold"> {t("Home.wanna_know_more", "Want to learn more about Annualty")}? </p>
                            <LinkWithSVG navigateOutsideTo={"https://annualty.com/blog"} text={t("Home.move_to_blog", "Check our blog")} SVG={<ArrowRightIcon className={"SVG_STROKE_GRAY"} />} />
                        </WrapperFlexRow>

                        <p className="SmallText"> {t("Home.latest_blogs", "Latest blogs")} </p>

                        <WrapperFlexCol style={{ padding: "0", gap: "1rem", marginTop: "1rem" }}>

                            {DUMMY_BLOG.map((blog, idx) => {

                                return (
                                    <WrapperFlexRow key={`blog-${idx}`} style={{ padding: "0", alignItems: "flex-start" }}>
                                        <PrettyDate date={blog.date} />
                                        <p className="SmallText text-bold"> {blog.title} </p>
                                    </WrapperFlexRow>
                                )
                            })}

                        </WrapperFlexCol>

                    </DataBox>



                    {/* Found error box  */}
                    <DataBox className={"onLayout HideScrollbar centerAll smallerGap"} style={{ gap: "1.5rem" }}>

                        <FoundErrorIcon className={"SVG_STROKE"} style={{ width: "36px", height: "36px" }} />

                        <p className="DefaultText text-light"> {t("Home.found_error", "Found an error")}? </p>

                        <ButtonPrimaryErrorFill text={t("Home.notify_us", "Notify us")} onClick={() => moveToPathInsideDashboard(`/dashboard/${dashboardHash}/contact-us`)} />

                    </DataBox>



                </WrapperGrid2>




            </WrapperFlexCol>


        </div>
    )
}


export default HomeDashboard