
// React
import React from 'react'

// Styles
import classes from "./LayoutHero.module.scss"

// Components
import NavbarHero from '../NavbarHero/NavbarHero'

// This is Layout only for page where we have hero and stuff, not for the dashboard


function LayoutHero({ children, centerContent = false }) {

    return (
        <div className={classes.Hero}>

            <NavbarHero />

            <div className={`${classes.Layout} ${centerContent ? classes.LayoutCenter : ""}`}>
                {children}
            </div>

        </div>
    )
}

export default LayoutHero