// React 
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Styles
import classes from "./Pages_styles/Client.module.scss"

// Apis
import { API_DeleteClient, API_GetSingleClientData, API_UpdateClient } from '../../../api/API_dashboard_clients'

// Helpers
import { phoneFormatter, formatTimeRange, phoneReformatter } from '../../../helper/funcs'
import { formatDate, formatDayMonth, getCurrentDateFormatted, getYear } from '../../../helper/dateFunctions'
import { getDayName } from '../../../helper/_calendar'
import { translateDayOfWeek } from '../../../translations/translation_helpers'
import { isNameLastnameValid } from '../../../utils/data_validators/client_validator'

// Layouts
import PopupLayout from '../Layout/PopupLayout'

// Svg / Icons
import { ReactComponent as UserDetailIcon } from "../../../static/n_icons/UserDetail.svg"
import { ReactComponent as DollarIcon } from "../../../static/n_icons/Wallet.svg"
import { ReactComponent as NotesIcon } from "../../../static/n_icons/Settings_Description.svg"
import { ReactComponent as UserPeselIcon } from "../../../static/n_icons/UserPesel.svg"
import { ReactComponent as AddressIcon } from "../../../static/n_icons/Location.svg"
import { ReactComponent as BirthdateIcon } from "../../../static/n_icons/Birthdate.svg"
import { ReactComponent as ArrowLeftIcon } from "../../../static/n_icons/ArrowLeft.svg"
import { ReactComponent as UserDeleteIcon } from "../../../static/n_icons/Delete.svg"
import { ReactComponent as SymbolIcon } from "../../../static/n_icons/Gem.svg"
import { ReactComponent as VisitAmountIcon } from "../../../static/n_icons/VisitAmount.svg"
import { ReactComponent as VisitAvgTimeIcon } from "../../../static/n_icons/VisitAvgTime.svg"
import { ReactComponent as VisitTimeIcon } from "../../../static/n_icons/VisitTime.svg"
import { ReactComponent as VisitTotalTimeIcon } from "../../../static/n_icons/VisitTotalTime.svg"
import { ReactComponent as VisitWalletIcon } from "../../../static/n_icons/VisitWallet.svg"
import { ReactComponent as PhoneIcon } from "../../../static/n_icons/Phone.svg"
import { ReactComponent as EmailIcon } from "../../../static/n_icons/Email.svg"

// Public files
import paths from "../../../data/paths.json"

// Context
import { useDashboardContext } from '../../../context/DashboardContext'
import { useUserContext } from '../../../context/UserAuth'
import { useSharedDashboardContext } from '../../../context/SharedDashboardDataContext'

// SubPages (My React components)
import DashboardLoading from '../DashboardLoading'
import DashboardDataNotFound from '../DashboardDataNotFound'


// APIs
import { API_GetHistoryVisitsByClientId, API_GetUpcomingVisitsByClientId, API_ToggleIsCheckedByOwner } from '../../../api/API_Visits'

// (My) React components
import { DataBox, DataBoxWrapper, LoadingAnimationFourDots, NormalBox, TwoActionButtonsDangerWrapper, WrapperFlexCol, WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { ButtonNeutral, ButtonNeutralToDanger, ButtonNeutralToLightPrimary, ButtonPrimaryFill } from '../../../components/Elements/Buttons'
import { DangerousWarrningContainer, ErrorContainer, SuccessContainer } from '../../../components/Elements/FormMessageBoxes'
import { ButtonDelete, ButtonSave, } from '../../../components/Elements/FormButtons'
import { LabelLeftIconTwoBoldsTexts, LabelLeftSingleIcon } from '../../../components/Elements/FormLabels'
import { CurrencyInput, SingleInput, TextFieldInput } from '../../../components/Elements/FormInputs'
import { DeleteVisitPopup } from './Calendar'
import { PriceListTag } from './PriceList'
import { ClientBox } from './Clients'



function Client({ client_id }) {

    const path_to_avatars = paths.path_to_avatar_images;

    const { t } = useTranslation();
    const { currentUser } = useUserContext()
    const { dashboardUsersAsDictionary } = useDashboardContext()
    const { setSharedClients, setSharedVisits, dashboardCurrency } = useSharedDashboardContext()


    const token = localStorage.getItem("access_token")
    const { dashboardHash, moveToPathInsideDashboard, updateDashboardUnsavedChanges, currentDashboard } = useDashboardContext()

    const [client, setClient] = useState({})
    const [clientToReset, setClientToReset] = useState({})

    // === UPCOMING VISITS ===
    const [upcomingVisits, setUpcomingVisits] = useState([])

    // On init, cursor is null. When we fetch all data, he also ends up null 
    // It stores value if any cursor was recieved from backend
    const [upcomingVisitsCursor, setUpcomingVisitsCursor] = useState(null)
    const [upcomingVisitsLoading, setUpcomingVisitsLoading] = useState(false)



    // === HISTORY VISITS ===
    const [historyVisits, setHistoryVisits] = useState([])
    const [historyVisitsCursor, setHistoryVisitsCursor] = useState(null)

    const [historyVisitsLoading, setHistoryVisitsLoading] = useState(false)


    // This holds either 'upcoming' or 'history'. We pass it to delete visit function 
    // So we can properly decide from which visit box we delete data
    const [whichVisit, setWhichVisit] = useState(null)

    const oneWeekAgo = new Date(); // This is our one week behind date for Newest users
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const creationDate = new Date(client.client_creation_date);
    const isNew = creationDate >= oneWeekAgo;

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);

    const [showChangesNotSavedPopup, setShowChangesNotSavedPopup] = useState(false)
    const [showDeleteClientPopup, setShowDeleteClientPopup] = useState(false)

    const [showDeleteVisitPopup, setShowDeleteVisitPopup] = useState(false)
    const [visitToDelete, setVisitToDelete] = useState({})

    const [isClientLoading, setIsClientLoading] = useState(true);
    const [isClientFetched, setIsClientFetched] = useState(false);

    const [anyChangesFound, setAnyChangesFound] = useState(false)

    // Client Start 
    useEffect(() => {
        console.log("\n\n[@Start for Client] with id: ", client_id)
        loadSingleClient()

        // Load on init 
        if (upcomingVisitsCursor === null) {
            loadUpcomingVisits()
        }
        if (historyVisitsCursor === null) {
            loadHistoryVisits()
        }

        // eslint-disable-next-line
    }, [])

    // This will check if user did any changes to client
    useEffect(() => {

        // We have to check if client exists or is {} empty. 
        // We want this to only run when client is loaded (because it will wrongly trigger our sidebar locking system)
        if (Object.keys(client).length) {
            const keys = Object.keys(clientToReset);

            const differences = keys.filter(key => {
                // Ignore 'isFavourite' key
                // if (key === 'isFavourite') {
                //     return false;
                // }
                return client[key] !== clientToReset[key];
            });

            if (differences.length > 0 && !anyChangesFound) {
                setAnyChangesFound(true)
                updateDashboardUnsavedChanges(true)
            }
            else if (differences.length === 0 && anyChangesFound) {
                setAnyChangesFound(false);
                updateDashboardUnsavedChanges(false);
            }
        }

        // eslint-disable-next-line
    }, [client])

    // Hide success message after 3 seconds 
    useEffect(() => {

        if (successVisible) {
            const errorsTimeout = setTimeout(() => {
                setSuccessVisible(false);

            }, 3000);

            return () => clearTimeout(errorsTimeout);
        }
    }, [successVisible]);

    // This function is called from Calendar.js (outside)
    // When we are here, this means we properly deleted visit and not it is deleted by state 
    function onVisitDeleteSuccess(whichVisits) {

        // whichVisits can be either "upcoming" or "history"

        // Assuming visitToDelete has an 'id' property
        const visitIdToDelete = visitToDelete.id;

        // Find the index of the visitToDelete in the visits array
        let visitIndexToDelete;

        if (whichVisits === "upcoming") {
            visitIndexToDelete = upcomingVisits.findIndex(visit => visit.id === visitIdToDelete);

            if (visitIndexToDelete !== -1) {
                // Create a copy of visits
                const updatedVisits = [...upcomingVisits];

                // Remove the visitToDelete from the array
                updatedVisits.splice(visitIndexToDelete, 1);

                // Update the state with the new array
                setUpcomingVisits(updatedVisits);
            }
        }
        else if (whichVisits === "history") {
            visitIndexToDelete = historyVisits.findIndex(visit => visit.id === visitIdToDelete);

            if (visitIndexToDelete !== -1) {
                // Create a copy of visits
                const updatedVisits = [...historyVisits];

                // Remove the visitToDelete from the array
                updatedVisits.splice(visitIndexToDelete, 1);

                // Update the state with the new array
                setHistoryVisits(updatedVisits);
            }
        }
    }

    async function loadUpcomingVisits() {
        if (token) {
            setUpcomingVisitsLoading(true)
            const response = await API_GetUpcomingVisitsByClientId(token, currentDashboard.id, client_id, upcomingVisitsCursor)

            if (response.isOk && response.items) {
                const visits = response.items;

                if (response.next) {
                    // Get cursor from response.next
                    const urlParams = new URLSearchParams(response.next.split('?')[1]);
                    const cursorValue = urlParams.get('cursor');
                    setUpcomingVisitsCursor(cursorValue);
                }
                else {
                    setUpcomingVisitsCursor(null)
                }

                if (visits) {
                    setUpcomingVisits(prevVisits => [...prevVisits, ...visits]);
                }


            }
            else {
                console.log("[@Client > loadUpcomingVisits] Something is wrong!")
                console.log(response)
            }


            setUpcomingVisitsLoading(false)
        }
    }

    async function loadNextUpcomingVisits() {
        // We can fetch next visits only if upcomingVisitsCursor exists (not null)
        if (upcomingVisitsCursor) {
            loadUpcomingVisits()
        }
    }

    async function loadHistoryVisits() {
        if (token) {
            setHistoryVisitsLoading(true)
            const response = await API_GetHistoryVisitsByClientId(token, currentDashboard.id, client_id, historyVisitsCursor)

            if (response.isOk && response.items) {
                const visits = response.items;

                if (response.next) {
                    // Get cursor from response.next
                    const urlParams = new URLSearchParams(response.next.split('?')[1]);
                    const cursorValue = urlParams.get('cursor');
                    setHistoryVisitsCursor(cursorValue);
                }
                else {
                    setHistoryVisitsCursor(null)
                }

                if (visits) {
                    setHistoryVisits(prevVisits => [...prevVisits, ...visits]);
                }


            }
            else {
                console.log("[@Client > loadUpcomingVisits] Something is wrong!")
                console.log(response)
            }


            setHistoryVisitsLoading(false)
        }
    }

    async function loadNextHistoryVisits() {
        // We can fetch next visits only if upcomingVisitsCursor exists (not null)
        if (historyVisitsCursor) {
            loadHistoryVisits()
        }
    }

    async function toggleVisitIsConfirmedByUser(visit, _whichVisit) {
        // _whichVisit has the same role as whichVisit but we are not using it from state, we just pass it from element 

        if (token && currentUser && currentUser.id) {

            const response = await API_ToggleIsCheckedByOwner(token, visit.id)

            if (response.isOk) {
                // Toggle success
                let updated_item = { ...visit }
                updated_item.isCheckedByOwner = !updated_item.isCheckedByOwner

                // Here we decide which state to update
                if (_whichVisit === "upcoming") {
                    setUpcomingVisits(prev =>
                        prev.map(item =>
                            item.id === updated_item.id ? updated_item : item
                        )
                    );
                }
                else if (_whichVisit === "history") {
                    setHistoryVisits(prev =>
                        prev.map(item =>
                            item.id === updated_item.id ? updated_item : item
                        )
                    );
                }

                // IMPORTANT, we also update sharedVisits because user might toggle visit that is currently fetched and in memory in calendar 
                setSharedVisits(prev =>
                    prev.map(item =>
                        item.id === updated_item.id ? updated_item : item
                    )
                );

            }
            else {
                // Toggle unsuccessful 
                console.log(response.error_message)

            }
        }
    }



    // Loading single client (/dashboard/client/:id)
    async function loadSingleClient() {
        if (token) {
            const response = await API_GetSingleClientData(token, client_id)

            if (response.isOk && response.items) {
                const client = response.items;

                setClient(client)
                setClientToReset(client)

                setIsClientFetched(true)
                setIsClientLoading(false)
            }
            else {
                setIsClientFetched(false)
                setIsClientLoading(false)
            }
        }

    }


    function handleCloseDeletePopup() {
        setShowDeleteClientPopup(false)
    }

    // actuall client update 
    // returns true / false 
    async function clientUpdate() {
        let errors = [];
        setIsSuccess(false);

        if (!client.name || !client.lastname) {
            errors.push(t("validationErrors.enter_all_required_fields", "Enter values to all required fields!"))
        }


        const nameLastnameValidation = isNameLastnameValid(client.name, client.lastname, t)

        if (nameLastnameValidation.length > 0) errors = errors.concat(nameLastnameValidation)

        if (errors.length === 0) {
            // Here we are deleting all the path from icon, so we only save the name of it!
            let updatedClient = { ...client }

            // Here we format phone to XXX-XXX-XXX format 
            if (client.phone) {
                updatedClient.phone = phoneFormatter(updatedClient.phone)
            }

            if (token) {
                const response = await API_UpdateClient(token, client_id, updatedClient)

                // If we are here, we did correct client update
                if (response && response.isOk) {
                    // Clearing state and all 
                    setErrors([])
                    setIsSuccess(true);
                    setSuccessVisible(true)

                    // Update Client and ClientToReset but also change png path
                    // Because we save path differently to server and to front end
                    updatedClient.icon = client.icon
                    setClientToReset(updatedClient)
                    setClient(updatedClient)
                    setAnyChangesFound(false)
                    updateDashboardUnsavedChanges(false)

                    setSharedClients(prevClients => {
                        return prevClients.map(client => {
                            if (client.id === client_id) {
                                return updatedClient;
                            }
                            return client;
                        });
                    });
                }
                // If we have some server issues 
                else if (response && response.isOk === false) {
                    console.log(response)
                    if (response.error_message) {
                        errors.push(response.error_message)
                    }

                    // Here we take some messages from django and manualy handle them for users for better understatning of error 
                    // Because throwing error "400 - Bad Request" is not the best option here
                    if (response.response_json && response.response_json.phone) {
                        let res = response.response_json.phone[0]

                        if (res.includes("(cv_pn01)")) {
                            errors.push(t("validationErrors.invalid_phone_format", "Invalid phone format. Valid phone formats are: 123 123 123, 123-123-123 or 123123123") + " (cv_pn01)")
                        }
                    }
                    if (response.response_json && response.response_json.latestVisitPrice) {
                        errors.push(t("validationErrors.invalid_latest_price", "Make sure default visit price is in format correct format."))
                    }
                    if (response.response_json && response.response_json.description) {
                        errors.push(t("validationErrors.invalid_description", "Description can only contain numbers, letters and symbols !@#$%&") + " (cv_cd01)")
                    }
                    if (response.response_json && response.response_json.email) {
                        errors.push(t("validationErrors.email_invalid_format", "Email has wrong format"))
                    }
                    if (response.response_json && response.response_json.pesel) {
                        errors.push(t("validationErrors.invalid_pesel", "Pesel has wrong format"))
                    }


                    setIsSuccess(false);
                    setSuccessVisible(false)
                }
            }

        }

        setErrors(errors)

        if (errors.length > 0) {
            return false
        }
        else {
            return true
        }
    }


    // When we did some changes to user and we want to reset them 
    function resetClientChanges() {
        if (Object.keys(clientToReset).length > 0) {
            setClient(clientToReset)
            setErrors([])
            setIsSuccess(false)
        }
    }

    // When we click back to clients, we want to check if user did any changes 
    // We want to display alert that there are unsaved changes 
    function goBackToClientsCheck() {

        // if they are different, we want to add popup with confirmation
        if (anyChangesFound) {
            setShowChangesNotSavedPopup(true)
        }
        // if not, we move back to our clients (with filter applied)
        else {
            moveToPathInsideDashboard(`/dashboard/${dashboardHash}/clients`)
        }
    }

    // This is confirmation data from our popup 
    async function handleGoBackActionNumber(action_number) {
        console.log("[@Client > handleGoBackActionNumber] Back action number: ", action_number)

        // Save changes and go
        if (action_number === 0) {
            const clientUpdateSuccess = await clientUpdate()

            // If we got any errors, we tell user that we have errors and we dont move him 
            if (!clientUpdateSuccess) {
                setShowChangesNotSavedPopup(false)
            }
            else {
                // If we dont have any errors 
                moveToPathInsideDashboard(`/dashboard/${dashboardHash}/clients`)
            }
        }
        // Discard changes and go
        else if (action_number === 1) {
            moveToPathInsideDashboard(`/dashboard/${dashboardHash}/clients`)
        }
        else {
            setShowChangesNotSavedPopup(false)
        }
    }

    let pathToClientOwnerAvatar = (dashboardUsersAsDictionary[client.client_owner]) ? path_to_avatars + dashboardUsersAsDictionary[client.client_owner].avatar : ""


    return (
        <div className='container is-max-desktop'>


            {(showChangesNotSavedPopup) && <ChangesNotSavedPopup updateDashboardUnsavedChanges={updateDashboardUnsavedChanges} handleGoBackActionNumber={handleGoBackActionNumber} setShowChangesNotSavedPopup={setShowChangesNotSavedPopup} client={client} />}
            {(showDeleteClientPopup) && <DeleteClientPopUp updateDashboardUnsavedChanges={updateDashboardUnsavedChanges} dashboardHash={dashboardHash} setClient={setClient} closePopup={handleCloseDeletePopup} token={token} client={client} />}


            {(client && Object.keys(client).length > 0 && isClientFetched && isClientLoading === false) ?

                <>

                    {(showDeleteVisitPopup) && <DeleteVisitPopup outside_deletionSuccess={onVisitDeleteSuccess} whichVisit={whichVisit} token={token} visitToDelete={visitToDelete} setVisitToDelete={setVisitToDelete} setShowDeleteVisitPopup={setShowDeleteVisitPopup} />}

                    {/* Upper title section  */}

                    <WrapperFlexRow className={"WrapperTitle FlexWrapOnTablet"}>

                        <WrapperFlexCol style={{ padding: "0", gap: "0" }}>

                            <h2 className="SectionTitleText"> {t("Client.client_page", "Client's page")} </h2>

                        </WrapperFlexCol>

                        <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end" }}>
                            <ButtonPrimaryFill disabled={!anyChangesFound} onClick={clientUpdate} text={t("Common.button.saveChanges", "Save changes")} />
                            <ButtonNeutralToDanger disabled={!anyChangesFound} onClick={resetClientChanges} text={t("Common.button.resetChanges", "Reset changes")} />
                        </WrapperFlexRow>

                    </WrapperFlexRow>



                    {/* Title Section  */}
                    <WrapperFlexRow style={{ marginBottom: "1rem", padding: "0", justifyContent: "space-between" }}>

                        <ButtonNeutral
                            onClick={goBackToClientsCheck}
                            text={<><ArrowLeftIcon className={"SVG_STROKE"} /> {t("Client.back_to_clients", "Back to Clients")}</>}
                            label_stroke={true}
                        />

                        <WrapperFlexRow style={{ padding: "0", justifyContent: "flex-end" }}>
                            {(client.phone) &&
                                <a href={`tel:${phoneReformatter(client.phone)}`}>
                                    <ButtonNeutralToLightPrimary noMargin={true} className={"SVG_STROKE"} style={{ minWidth: "unset" }} text={<PhoneIcon />} />
                                </a>
                            }
                            <ButtonNeutralToDanger onClick={() => { setShowDeleteClientPopup(true) }} text={<UserDeleteIcon />} />
                        </WrapperFlexRow>

                    </WrapperFlexRow>



                    {/* Error message */}
                    {(errors.length > 0 && !isSuccess) && (
                        <ErrorContainer errors={errors} />
                    )}

                    {(errors.length === 0 && successVisible && isSuccess) &&
                        <SuccessContainer message={t("Client.update_success_message", "Client updated successfully!")} />
                    }




                    {/* Here we display client with options to update him  */}
                    <WrapperFlexRow className={`${classes.ClientWrapper} FlexWrapOnBigTablet`} style={{ padding: "0", alignItems: "flex-start", marginTop: "1rem", gap: "1rem" }}>
                        <WrapperFlexCol style={{ padding: "0", gap: "1rem" }}>
                            <ClientBox dashboardCurrency={dashboardCurrency} hideActions={true} t={t} isNew={isNew} client={client} />



                            {/* Here we display Client Update box  */}
                            {(client && Object.keys(client).length > 0) &&
                                <>
                                    {/* Update client box */}
                                    <DataBox className={"onLayout"} style={{ flex: "1", gap: "0.25rem", minWidth: "unset" }}>


                                        {/* Client name and last name  */}
                                        <LabelLeftIconTwoBoldsTexts style={{ marginTop: "0" }} LeftIcon={UserDetailIcon} LabelText={t("Common.update", "Update client's")} LabelBold1={t("Clients.label.name", "name")} LabelBold2={t("Clients.label.lastname", "last name")} />

                                        <WrapperFlexRow style={{ padding: "0", gap: "0.5rem" }}>
                                            <SingleInput
                                                style={{ flex: "1" }}
                                                value={client.name}
                                                onChange={(e) => setClient({ ...client, name: e.target.value })}
                                                label="given-name"
                                                placeholder={t("Clients.placeholder.name", "Name")}
                                            />
                                            <SingleInput
                                                style={{ flex: "1" }}
                                                value={client.lastname}
                                                onChange={(e) => setClient({ ...client, lastname: e.target.value })}
                                                label="family-name"
                                                placeholder={t("Clients.placeholder.lastname", "Last name")}
                                            />

                                        </WrapperFlexRow>


                                        {/* Client phone and visit price  */}
                                        <LabelLeftIconTwoBoldsTexts LeftIcon={DollarIcon} LabelText={t("Common.update", "Update client's")} LabelBold1={t("Clients.label.phone", "phone number")} LabelBold2={t("Clients.label.visit_price", "default visit price")} />
                                        <WrapperFlexRow style={{ padding: "0", gap: "0.5rem" }}>
                                            <SingleInput
                                                style={{ flex: "1" }}
                                                value={client.phone}
                                                onChange={(e) => setClient({ ...client, phone: e.target.value })}
                                                label="phone"
                                                placeholder="123-123-123"
                                            />
                                            <CurrencyInput
                                                value={(client.latestVisitPrice)}
                                                onChange={(e) => setClient({ ...client, latestVisitPrice: e.target.value })}
                                                label={t("Clients.placeholder.visit_price", "Default visit price")}
                                                style={{ flex: "1" }}
                                            />
                                        </WrapperFlexRow>


                                        {/* Client Description  */}
                                        <LabelLeftSingleIcon LeftIcon={NotesIcon} LabelText={t("Common.update", "Update client's")} LabelBold={t("Clients.label.description", "description")} LabelAfterBold={t("Clients.clienta", "")} />
                                        <TextFieldInput
                                            label={t("Clients.client_description", "Description")}
                                            rows={4}
                                            value={client.description}
                                            onChange={(e) => setClient({ ...client, description: e.target.value })}
                                        />


                                        {/* Client Email */}
                                        <LabelLeftSingleIcon LeftIcon={EmailIcon} LabelText={t("Common.update", "Update client's")} LabelBold={"e-mail"} />
                                        <SingleInput
                                            style={{ flex: "1" }}
                                            value={client.email}
                                            onChange={(e) => setClient({ ...client, email: e.target.value })}
                                            label="email"
                                            placeholder={t("Signin.email_placeholder", "Enter email")}
                                        />


                                        {/* Client Symbol */}
                                        <LabelLeftSingleIcon LeftIcon={SymbolIcon} LabelText={t("Common.update", "Update client's")} LabelBold={"symbol"} />
                                        <p className='SmallText text-gray'>
                                            {t("Client.assist_text", "Designed to help you in adding your unique logic to the app")}
                                        </p>

                                        <SingleInput
                                            style={{ flex: "1" }}
                                            value={client.symbol}
                                            onChange={(e) => setClient({ ...client, symbol: e.target.value })}
                                            label="Symbol"
                                            placeholder={t("Client.customizable_identifier_placeholder", "Client's customizable identifier")}
                                        />


                                        {/* Client Pesel number  */}
                                        <LabelLeftSingleIcon LeftIcon={UserPeselIcon} LabelText={t("Common.update", "Update client's")} LabelBold={t("Client.label_pesel", "PESEL number")} />
                                        <SingleInput
                                            style={{ flex: "1" }}
                                            value={client.pesel}
                                            onChange={(e) => setClient({ ...client, pesel: e.target.value })}
                                            label="Pesel number"
                                            placeholder="Pesel"
                                        />


                                        {/* Client Address  */}
                                        <LabelLeftSingleIcon LeftIcon={AddressIcon} LabelText={t("Common.update", "Update client's")} LabelBold={t("Client.address", "address")} />
                                        <SingleInput
                                            style={{ flex: "1" }}
                                            value={client.address}
                                            onChange={(e) => setClient({ ...client, address: e.target.value })}
                                            label="address"
                                            placeholder={t("Client.placeholder.address", "address")}
                                        />

                                        {/* Client Birthdate  */}
                                        <LabelLeftSingleIcon LeftIcon={BirthdateIcon} LabelText={t("Common.update", "Update client's")} LabelBold={t("Client.birthdate", "birthdate")} />
                                        <SingleInput
                                            style={{ width: "100%" }}
                                            value={(client.birthdate) ? client.birthdate : getCurrentDateFormatted()}
                                            onChange={(e) => setClient({ ...client, birthdate: e.target.value })}
                                            label="Birthdate"
                                            type="date"
                                            disabled={false}
                                            max={getCurrentDateFormatted()}
                                            min={"1900-01-01"}
                                        />


                                        {(dashboardUsersAsDictionary[client.client_owner]) &&
                                            <WrapperFlexRow style={{ padding: "0", marginTop: "1rem", gap: "0.25rem" }}>

                                                <p className='SmallText'> {t("Client.added_by", "Added by")} </p>

                                                <img src={pathToClientOwnerAvatar} alt="user icon" style={{ maxWidth: "16px" }} />
                                                <p className='SmallText'> {dashboardUsersAsDictionary[client.client_owner].username} </p>
                                                <p className='SmallText'> {t("Client.on", "on")} {formatDate(client.client_creation_date)}</p>
                                            </WrapperFlexRow>
                                        }

                                    </DataBox>

                                </>
                            }

                        </WrapperFlexCol>



                        <WrapperFlexCol style={{ width: "100%", minHeight: "150px", alignItems: "center", padding: "0", gap: "1rem" }}>

                            {/* // Display Upcoming visits  */}
                            <RenderUpcomingVisits path_to_avatars={path_to_avatars} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} upcomingVisitsCursor={upcomingVisitsCursor} upcomingVisitsLoading={upcomingVisitsLoading} loadNextUpcomingVisits={loadNextUpcomingVisits} setWhichVisit={setWhichVisit} dashboardUsersAsDictionary={dashboardUsersAsDictionary} setShowDeleteVisitPopup={setShowDeleteVisitPopup} setVisitToDelete={setVisitToDelete} visits={upcomingVisits} />


                            {/* // Display historic visits  */}
                            <RenderVisitHistory path_to_avatars={path_to_avatars} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} historyVisitsCursor={historyVisitsCursor} loadNextHistoryVisits={loadNextHistoryVisits} historyVisitsLoading={historyVisitsLoading} setWhichVisit={setWhichVisit} dashboardUsersAsDictionary={dashboardUsersAsDictionary} setShowDeleteVisitPopup={setShowDeleteVisitPopup} setVisitToDelete={setVisitToDelete} visits={historyVisits} />

                            {/* Analyze visits  */}
                            <RenderClientAnalysis path_to_avatars={path_to_avatars} visits={historyVisits} dashboardUsersAsDictionary={dashboardUsersAsDictionary} />


                        </WrapperFlexCol>



                    </WrapperFlexRow>

                </ >

                :
                (isClientLoading === true && isClientFetched === false) ?
                    <DashboardLoading />
                    :

                    // Do Checking if clients exists or is loading 
                    <DashboardDataNotFound additionalText={t("Client.not_exists", "This client does not exist")} goBackTo={"/clients"} />

            }
        </div>
    )
}

function RenderUpcomingVisits({ path_to_avatars, toggleVisitIsConfirmedByUser, upcomingVisitsLoading, upcomingVisitsCursor, loadNextUpcomingVisits, setVisitToDelete, setWhichVisit, setShowDeleteVisitPopup, visits, dashboardUsersAsDictionary }) {


    const { t } = useTranslation();

    const { priceListById, dashboardCurrency } = useSharedDashboardContext()
    const { moveToPathInsideDashboard } = useDashboardContext()


    function handleVisitToDelete(visit) {
        setVisitToDelete(visit)
        setShowDeleteVisitPopup(true)
        setWhichVisit("upcoming")
    }


    return (
        <DataBoxWrapper className={"HideScrollbar"} style={{ width: "100%", padding: "0", minHeight: "unset" }}>
            <WrapperFlexRow style={{ alignItems: "center", padding: "1.5rem 1.5rem 0.5rem 1.5rem", justifyContent: "space-between" }}>
                <p className='SmallText text-gray text_light_bold'> {t("Client.visit_upcoming", "Upcoming visits")}</p>
            </WrapperFlexRow>

            <hr className='myHr' />

            <WrapperFlexCol className={`${classes.ClientVisits}`}>

                {(visits.length > 0 || upcomingVisitsCursor !== null) ?
                    <>
                        {visits.map((visit, idx) => {
                            let pathToUserAvatar = (dashboardUsersAsDictionary[visit.visit_assigned_worker_id]) ? path_to_avatars + dashboardUsersAsDictionary[visit.visit_assigned_worker_id].avatar : ""
                            let visitOwnerUsername = (dashboardUsersAsDictionary[visit.visit_assigned_worker_id]) ? dashboardUsersAsDictionary[visit.visit_assigned_worker_id].username : ""
                            let visit_time = formatTimeRange(visit.start_hour, visit.start_minute, visit.length)
                            let priceListObject = (visit.visit_assigned_to_price_list_id !== null && priceListById[visit.visit_assigned_to_price_list_id]) ? priceListById[visit.visit_assigned_to_price_list_id] : null


                            return (
                                <SingleVisit dashboardCurrency={dashboardCurrency} visit={visit} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} t={t} idx={idx} key={`visit-upcoming-${visit.id}-${idx}`} priceListObject={priceListObject} visit_time={visit_time} visitOwnerUsername={visitOwnerUsername} pathToUserAvatar={pathToUserAvatar} handleVisitToDelete={handleVisitToDelete} priceListById={priceListById} />
                            )
                        })}

                        {(upcomingVisitsCursor !== null)
                            &&
                            <p className={"LoadMoreText"} onClick={loadNextUpcomingVisits}> {(upcomingVisitsLoading === false) ? t("Client.load_more", "Load more") : t("Client.loading", "Loading..")} </p>
                        }
                    </>
                    :
                    (upcomingVisitsLoading === false && visits.length === 0) ?


                        <WrapperFlexCol className={'DashboardErrorContainer removeBoxShadow'} style={{ alignItems: "center", height: "100%", minHeight: "150px", padding: "1rem" }}>
                            <p style={{ textAlign: "center" }} className='DefaultText text-gray text-light'>
                                {t("Client.no_visits_found", "Here, we will display all upcoming visits for this client")}
                            </p>
                            <p style={{ textAlign: "center" }} className='SmallText text-gray text-light'>
                                {t("Client.try_adding_one_visit", "You can add them in calendar")}
                            </p>
                            <button className="ButtonPrimaryFill" onClick={() => { moveToPathInsideDashboard(`calendar`) }}>
                                {t("Client.move_to_calendar", "Move to calendar")}
                            </button>
                        </WrapperFlexCol>


                        :

                        (upcomingVisitsLoading === true && visits.length === 0) &&

                        <WrapperFlexCol className={'DashboardErrorContainer removeBoxShadow'} style={{ alignItems: "center", minHeight: "150px", height: "100%", padding: "1rem" }}>
                            <p className='DefaultText text-gray text-light' style={{ textAlign: "center" }}> {t("loadingMessages.client_visit", "Hold tight! We are loading visits..")} </p>
                            <LoadingAnimationFourDots />
                        </WrapperFlexCol>

                }
            </WrapperFlexCol>
        </DataBoxWrapper>
    )
}

function RenderVisitHistory({ path_to_avatars, toggleVisitIsConfirmedByUser, historyVisitsLoading, historyVisitsCursor, loadNextHistoryVisits, setVisitToDelete, setWhichVisit, setShowDeleteVisitPopup, visits, dashboardUsersAsDictionary }) {

    const { t } = useTranslation();
    const { priceListById, dashboardCurrency } = useSharedDashboardContext()


    function handleVisitToDelete(visit) {
        setVisitToDelete(visit)
        setShowDeleteVisitPopup(true)
        setWhichVisit("history")
    }


    return (
        <DataBoxWrapper className={"HideScrollbar"} style={{ width: "100%", padding: "0", minHeight: "unset" }}>


            <WrapperFlexRow style={{ alignItems: "center", padding: "1.5rem 1.5rem 0.5rem 1.5rem", justifyContent: "space-between" }}>
                <p className='SmallText text-gray text_light_bold'> {t("Client.visit_history", "History visits")}</p>
            </WrapperFlexRow>

            <hr className='myHr' />

            <WrapperFlexCol className={`${classes.ClientVisits}`}>


                {(visits.length > 0 || historyVisitsCursor !== null) ?
                    <>
                        {visits.map((visit, idx) => {
                            let pathToUserAvatar = (dashboardUsersAsDictionary[visit.visit_assigned_worker_id]) ? path_to_avatars + dashboardUsersAsDictionary[visit.visit_assigned_worker_id].avatar : ""
                            let visitOwnerUsername = (dashboardUsersAsDictionary[visit.visit_assigned_worker_id]) ? dashboardUsersAsDictionary[visit.visit_assigned_worker_id].username : ""
                            let visit_time = formatTimeRange(visit.start_hour, visit.start_minute, visit.length)
                            let priceListObject = (visit.visit_assigned_to_price_list_id !== null && priceListById[visit.visit_assigned_to_price_list_id]) ? priceListById[visit.visit_assigned_to_price_list_id] : null


                            return (
                                <SingleVisit dashboardCurrency={dashboardCurrency} visit={visit} toggleVisitIsConfirmedByUser={toggleVisitIsConfirmedByUser} t={t} idx={idx} key={`visit-upcoming-${visit.id}-${idx}`} priceListObject={priceListObject} visit_time={visit_time} visitOwnerUsername={visitOwnerUsername} pathToUserAvatar={pathToUserAvatar} handleVisitToDelete={handleVisitToDelete} priceListById={priceListById} />
                            )
                        })}

                        {(historyVisitsCursor !== null)
                            &&
                            <p className={"LoadMoreText"} onClick={loadNextHistoryVisits}> {(historyVisitsLoading === false) ? t("Client.load_more", "Load more") : t("Client.loading", "Loading..")} </p>

                        }
                    </>

                    :

                    (historyVisitsLoading === false && visits.length === 0) ?
                        <WrapperFlexCol className={'DashboardErrorContainer removeBoxShadow'} style={{ alignItems: "center", height: "100%", minHeight: "150px", padding: "1rem" }}>
                            <p style={{ textAlign: "center" }} className='SmallText text-gray text-light'>
                                {t("Client.no_history_visits_found", "Nothing to show yet.")}
                            </p>
                        </WrapperFlexCol>

                        :

                        (historyVisitsLoading === true && visits.length === 0) &&

                        <WrapperFlexCol className={'DashboardErrorContainer removeBoxShadow'} style={{ alignItems: "center", minHeight: "150px", height: "100%", padding: "1rem" }}>
                            {/* <DBErrorIcon className={classes.SadIcon} /> */}
                            <p className='DefaultText text-gray text-light' style={{ textAlign: "center" }}> {t("loadingMessages.client_visit", "Hold tight! We are loading visits..")} </p>
                            <LoadingAnimationFourDots />
                        </WrapperFlexCol>

                }
            </WrapperFlexCol>
        </DataBoxWrapper>
    )
}

function SingleVisit({ dashboardCurrency, visit, toggleVisitIsConfirmedByUser, handleVisitToDelete, priceListById, pathToUserAvatar, visitOwnerUsername, visit_time, priceListObject, t, idx }) {

    return (


        <div className={classes.ClientVisitsWrapper}>

            <p className="SmallText"> {formatDayMonth(visit.visit_date)}
                &nbsp;
                <span className='SmallerText text-gray'>
                    ({translateDayOfWeek(getDayName(visit.visit_date).toLowerCase(), t)})
                </span>
                <span> {visit_time} </span>
                &nbsp;
                <span className='text-gray text_light_bold' style={{ float: "right" }}>
                    {getYear(visit.visit_date)}
                </span>
            </p>



            <NormalBox className={`${classes.ClientVisit}`} style={{ minWidth: "unset" }}>


                {/* Visit checkbox + time  */}
                <WrapperFlexRow style={{ padding: "0", gap: "0.25rem", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

                    <div className={classes.VisitToggleBox}>
                        <input id={`visit-${visit.id}-${idx}`} type="checkbox" checked={visit.isCheckedByOwner} onChange={() => { toggleVisitIsConfirmedByUser(visit, "history") }} />
                    </div>

                    {/* User avatar  */}
                    {pathToUserAvatar && <img className={classes.ClientVisitAvatar} alt={`user avatar`} src={pathToUserAvatar} />}

                    <p className="SmallText text-capitalize"> {visitOwnerUsername} </p>

                    <WrapperFlexRow style={{ width: "100%", padding: "0 0.25rem", gap: "0.25rem", justifyContent: "flex-end" }} >
                        <p className="SmallText"> </p>
                        {/* Assign tag  */}
                        {(priceListObject) ?
                            <PriceListTag bg={priceListObject.color} text={priceListObject.tag} size={"small"} />

                            :
                            <div style={{ backgroundColor: `${visit.visit_accent_color}` }} className={classes.ClientVisitColorBox}></div>
                        }


                    </WrapperFlexRow>


                </WrapperFlexRow>


                {/* Visit price  */}
                <p className='SmallText'>
                    {t("Client.visit_price", "Visit price")}: <span className='text-bold'>{(visit && (visit.price || visit.price === 0)) ? visit.price : "-"} {(dashboardCurrency.Symbol) || ""} </span>
                </p>


                {/* Visit description  */}
                <p className='SmallText' style={{ maxWidth: "300px", overflow: "hidden" }}>
                    {t("Client.description", "Description")}: <span className='text-bold'> {(visit && (visit.description)) ? visit.description : "Brak"} </span>
                </p>


            </NormalBox>


            {/* Delete visit  */}
            <WrapperFlexRow style={{ width: "100%", padding: "0 0.25rem", gap: "0.25rem", justifyContent: "flex-end", marginTop: "-0.4rem" }} >
                <p className="MicroText text-gray as_link-danger" onClick={() => handleVisitToDelete(visit)}> {t("Calendar.delete_visit", "Delete visit")} </p>
            </WrapperFlexRow>


        </div>
    )
}

function RenderClientAnalysis({ path_to_avatars, visits, dashboardUsersAsDictionary }) {

    const { t } = useTranslation();
    const { dashboardCurrency } = useSharedDashboardContext()

    // Visits analysys
    const totalVisitTime = visits.reduce((total, visit) => total + visit.length, 0);
    const totalVisitTimeInHours = parseFloat(totalVisitTime / 60).toFixed(2);
    const averageVisitTime = visits.length > 0 ? (parseInt(totalVisitTime / visits.length)) : 0;

    const totalVisitPrice = visits.reduce((total, visit) => total + parseFloat(visit.price), 0);
    const averageVisitPrice = visits.length > 0 ? parseFloat((totalVisitPrice / visits.length)).toFixed(2) : 0;


    // Create a dictionary to hold the count of visits for each worker
    const visitCountByWorker = visits.reduce((accumulator, visit) => {
        const workerId = visit.visit_assigned_worker_id;

        // Check if workerId is already a key in the dictionary
        if (workerId in accumulator) {
            // If yes, increment the count
            accumulator[workerId]++;
        } else {
            // If no, create a new key with count 1
            accumulator[workerId] = 1;
        }

        return accumulator;
    }, {});


    // This is an array with sorted visitCountByWorker keys so we can use it below in analysis to display progress bar with sorted users (by their visit amount)
    const sortedUserKeys = Object.keys(visitCountByWorker).sort((a, b) => visitCountByWorker[b] - visitCountByWorker[a]);

    return (
        <WrapperFlexCol className={`BoxBg ${classes.AnalyzeContainer}`} style={{ width: "100%", minHeight: "150px", padding: "1.5rem" }}>
            <p className='SmallText text-gray text_light_bold'> {t("Client.analysis", "Analysis")} </p>

            <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap", gap: "1rem" }}>


                <div className={classes.AnalyzeBox}>
                    <WrapperFlexRow style={{ padding: "0", alignItems: "center" }}>
                        <VisitAmountIcon className={"SVG_STROKE"} />
                        <p className='SmallText text-gray'> {t("Client.visit_ammount", "Amount of visits")} </p>
                    </WrapperFlexRow>
                    <p className='text_no_wrap SmallText '> <span className='BigText text-bold'> {visits.length}</span> </p>

                </div>

                <div className={classes.AnalyzeBox}>
                    <WrapperFlexRow style={{ padding: "0", alignItems: "center" }}>
                        <VisitTimeIcon className={"SVG_STROKE"} />
                        <p className='SmallText text-gray'> {t("Client.avg_visit_time", "Average time of visit")} </p>
                    </WrapperFlexRow>
                    <p className='text_no_wrap SmallText'> <span className='BigText text-bold'> {averageVisitTime} </span>min </p>
                </div>


                <div className={classes.AnalyzeBox}>
                    <WrapperFlexRow style={{ padding: "0", alignItems: "center" }}>
                        <VisitTotalTimeIcon className={"SVG_STROKE"} />
                        <p className='SmallText text-gray'> {t("Client.total_visits_time", "Total visits time")} </p>
                    </WrapperFlexRow>
                    <p className='text_no_wrap SmallText'> <span className='BigText text-bold'> {totalVisitTimeInHours} </span>h </p>
                </div>


                <div className={classes.AnalyzeBox}>
                    <WrapperFlexRow style={{ padding: "0", alignItems: "center" }}>
                        <VisitAvgTimeIcon className={"SVG_STROKE"} />
                        <p className='SmallText text-gray'> {t("Client.avg_price", "Average visit price")}  </p>
                    </WrapperFlexRow>
                    <p className='text_no_wrap SmallText'> <span className='BigText text-bold'> {averageVisitPrice} </span> {(dashboardCurrency.Symbol) || ""} </p>
                </div>


                <div className={classes.AnalyzeBox}>
                    <WrapperFlexRow style={{ padding: "0", alignItems: "center" }}>
                        <VisitWalletIcon className={"SVG_STROKE"} />
                        <p className='SmallText text-gray'> {t("Client.total_income", "Total income")}  </p>
                    </WrapperFlexRow>
                    <p className='text_no_wrap SmallText'> <span className='BigText text-bold'> {totalVisitPrice} </span> {(dashboardCurrency.Symbol) || ""} </p>
                </div>


            </WrapperFlexRow>

            {(sortedUserKeys.length > 0) &&
                <p style={{ marginTop: "1rem" }} className='SmallText text-gray text_light_bold'>{t("Client.most_popular_user_in_visit", "The most frequent performer for this visit")}</p>
            }

            <WrapperFlexRow style={{ padding: "0", flexWrap: "wrap" }}>


                <div className={classes.ProgressBarWrapper}>
                    {sortedUserKeys.map((key) => {

                        let pathToUserAvatar = (dashboardUsersAsDictionary[key]) ? path_to_avatars + dashboardUsersAsDictionary[key].avatar : ""
                        let visitOwnerUsername = (dashboardUsersAsDictionary[key]) ? dashboardUsersAsDictionary[key].username : ""
                        let progressBarWidth = `${parseInt(100 * (visitCountByWorker[key] / visits.length))}%`


                        return (
                            <WrapperFlexRow key={`idx-${key}`} style={{ padding: "0" }}>

                                {pathToUserAvatar && <img alt={`user avatar`} src={pathToUserAvatar} />}

                                {pathToUserAvatar &&
                                    <p className='SmallText text-bold' style={{ width: "80px", maxWidth: "80px", overflow: "hidden" }}>
                                        {visitOwnerUsername}
                                    </p>
                                }

                                <div className={classes.ProgressBar}>
                                    <div className={classes.ProgressBarFill} style={{ width: progressBarWidth }}></div>
                                </div>

                                <p className='SmallText text-bold'> {visitCountByWorker[key]} </p>

                                <p className='MicroText text-gray'> {progressBarWidth} </p>
                            </WrapperFlexRow>

                        )

                    })}

                </div>

            </WrapperFlexRow>
        </WrapperFlexCol>
    )
}

function ChangesNotSavedPopup({ setShowChangesNotSavedPopup, handleGoBackActionNumber, updateDashboardUnsavedChanges }) {

    const { t } = useTranslation();

    function handleClosePopup() {
        setShowChangesNotSavedPopup(false)
    }

    // Here we handle what user clicked
    // 1 - Discard changes and go 
    // 0 - save changes and go 
    function handleConfirmation(action_number) {
        handleGoBackActionNumber(action_number)
        updateDashboardUnsavedChanges(false)
    }


    return (
        <PopupLayout closePopup={handleClosePopup} popupTitle={t("Client.changes_not_saved", "Changes not saved")}>

            <WrapperFlexCol style={{ padding: "0" }} >

                <DangerousWarrningContainer message={t("Client.changes_not_saved_warning", "You made some changes that haven't been saved!")} />


                {/* Actions like save and cancel  */}
                <WrapperFlexRow style={{ padding: 0, justifyContent: "flex-end", flexWrap: "wrap" }}>

                    <ButtonSave label={t("Common.cancel", "Cancel")} onClick={() => { handleClosePopup() }} />

                    <ButtonDelete
                        label={t("Client.discard_changes_and_go", "Discard changes and go")}
                        onClick={() => { handleConfirmation(1) }}
                    />

                    <ButtonSave
                        type="submit"
                        label={t("Client.save_changes_and_go", "Save changes and go")}
                        onClick={() => { handleConfirmation(0) }}
                    />



                </WrapperFlexRow>
            </WrapperFlexCol>
        </PopupLayout>
    )
}

function DeleteClientPopUp({ setClient, closePopup, token, client, updateDashboardUnsavedChanges }) {

    const [errors, setErrors] = useState([])
    const [isSuccess, setIsSuccess] = useState(false);

    const { t } = useTranslation();

    // If we give confirmation we can actually delete client
    // When this is triggered, that means user wants to delete clien

    async function handleConfirmation() {
        let errors = [];

        if (client.id && token) {
            const response = await API_DeleteClient(token, client.id)

            if (response && response.isOk) {
                // Client deleted successfuly 
                setIsSuccess(true)
                setClient({})
                updateDashboardUnsavedChanges(false)
            }
            else if (response && response.isOk === false && response.error_message) {
                // Error while deleting client
                errors.push(response.error_message)
            }
        }

        if (!isSuccess) {
            setErrors(errors);
        }

    }

    return (
        <PopupLayout closePopup={closePopup} popupTitle={t("Common.delete_client", "Delete client")}>
            <WrapperFlexCol style={{ padding: "0" }} as_form={true} on_submit={handleConfirmation}>

                {(isSuccess === false && errors.length === 0) &&
                    <DangerousWarrningContainer message={t("Clients.irreversible_process_message", "This process is irreversible, all data connected to that client will be deleted!")} />
                }

                {(errors.length > 0 && !isSuccess) && (
                    <ErrorContainer errors={errors} />
                )}

                {(errors.length === 0 && isSuccess) &&
                    <SuccessContainer message={t("Clients.client_deleted_successfully", "Client deleted successfully!")} />
                }

                {(!isSuccess) &&
                    <p className="DefaultText">
                        {t("Clients.confirm_delete_client", "Are you sure you want to delete client:")} <b>{client.name} {client.lastname}</b>
                    </p>
                }

                {/* Actions like save and cancel  */}
                <TwoActionButtonsDangerWrapper
                    onCancel={closePopup}
                    onSave={handleConfirmation}
                    cancelLabel={isSuccess ? t("Common.go_back", "Go back") : t("Common.cancel", "Cancel")}
                    deleteLabel={t("Common.delete", "Delete")}
                    isSuccess={isSuccess}
                />


            </WrapperFlexCol>
        </PopupLayout>
    )
}



export default Client