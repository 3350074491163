


// # Clients # 

import { CURRENT_USING_URL, check_status_code, server_off_message } from "./API_common";



// 04.12.2023 Updated with new standard of returning data
export async function API_CreateNewClientByDashboard(token, client) {
    const URL = `${CURRENT_USING_URL}/clients/create-client-to-dashboard/`;

    console.log("\n[API_CreateNewClientByDashboard]\nTrying to add new client.. \nCalling: ", URL)
    console.log("[API_CreateNewClientByDashboard]\nNew client data is:\n", client)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(client),
        });


        if (response.ok) {
            console.log("\n[API_CreateNewClientByDashboard]\nNew client added!")
            const response_json = await response.json();
            console.log(response_json);
            return { isOk: true, response: response, new_item: response_json }
        }
        else {
            const response_json = await response.json();

            console.log("\n[API_CreateNewClientByDashboard]\nSomething went wrong with the response: ", response)
            console.log("\n[API_CreateNewClientByDashboard]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }

        }

    } catch (error) {
        console.log("\n[API_CreateNewClientByDashboard]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


// 04.12.2023 Updated with new standard of returning data
export async function API_GetClientsByDashboard(token, dashboard_id) {
    const URL = `${CURRENT_USING_URL}/clients/get-clients-by-dashboard/${String(dashboard_id)}`;

    console.log("\n[API_GetClientsByDashboard]\nTrying to get clients by dashboard.. \nCalling: ", URL)

    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });


        if (response.ok) {
            console.log("\n[API_GetClientsByDashboard]\nClients found!")

            const response_json = await response.json();

            console.log("\n[API_GetClientsByDashboard]\nFetched clients array: \n", response_json)

            return { isOk: true, response: response, items: response_json }
        }
        else {
            const response_json = await response.json();
            console.log("\n[API_GetClientsByDashboard]\nSomething went wrong with the response: ", response)
            console.log("\n[API_GetClientsByDashboard]\nResponse json: \n", response_json)

            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    } catch (error) {
        console.log("\n[API_GetClientsByDashboard]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


// 04.12.2023 Updated with new standard of returning data
export async function API_ToggleFavouriteClient(token, client_id) {
    const URL = `${CURRENT_USING_URL}/clients/toggle-favourite/${String(client_id)}/`;

    console.log("\n[API_ToggleFavouriteClient]\nTrying to toggle client isFav by id.. \nCalling: ", URL)
    console.log("[API_ToggleFavouriteClient]\nClient id is:", client_id)

    try {

        const response = await fetch(URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        if (response.ok) {
            console.log("\n[API_ToggleFavouriteClient]\nClient toggled correctly!")
            return { isOk: true, response: response }
        }
        else {
            console.log("\n[API_ToggleFavouriteClient]\nClient not found!")
            return { isOk: false, response: response }
        }


    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_ToggleFavouriteClient]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


// 04.12.2023 Updated with new standard of returning data
export async function API_GetSingleClientData(token, client_id) {
    const URL = `${CURRENT_USING_URL}/client/${String(client_id)}`;

    console.log("\n[API_GetSingleClientData]\nTrying to get single client by id.. \nCalling: ", URL)
    console.log("[API_GetSingleClientData]\nClient id is:", client_id)


    try {

        const response = await fetch(URL, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        // If we fetched client correctly 
        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_GetSingleClientData]\nClient fetched correctly!")
            console.log("[API_GetSingleClientData] Fetched client:", response_json)
            return { isOk: true, response: response, items: response_json }
        }
        else {
            console.log("\n[API_GetSingleClientData]\nClient not found!")
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }

    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_GetSingleClientData]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}


// 04.12.2023 Updated with new standard of returning data
export async function API_DeleteClient(token, client_id) {
    const URL = `${CURRENT_USING_URL}/client/delete/${String(client_id)}`;


    console.log("\n[API_DeleteClient]\nTrying to delete client by id.. \nCalling: ", URL)
    console.log("[API_DeleteClient]\nClient id is:", client_id)

    try {

        const response = await fetch(URL, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            }
        });

        if (response.ok) {
            // successfully deleted
            console.log("\n[API_DeleteClient]\nClient deleted correctly!")
            return { isOk: true, response: response }
        }
        else {
            // something went wrong 
            console.log("\n[API_DeleteClient]\nCound not delete client!\nResponse:", response)
            return { isOk: false, response: response, error_message: check_status_code(response.status) }
        }


    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_DeleteClient]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }

    }
}


// 04.12.2023 Updated with new standard of returning data
export async function API_UpdateClient(token, client_id, updatedClient) {
    const URL = `${CURRENT_USING_URL}/client/update/${String(client_id)}`;
    // console.log("Token: ", token)

    console.log("\n[API_UpdateClient]\nTrying to update client.. \nCalling: ", URL)
    console.log("[API_UpdateClient]\nClient id is:", client_id)

    try {

        const response = await fetch(URL, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Include the Bearer token here
            },
            body: JSON.stringify(updatedClient),
        });

        // If we fetched client correctly 
        if (response.ok) {
            const response_json = await response.json();
            console.log("\n[API_UpdateClient]\nClient updated successfully!", response_json)
            return { isOk: true, response: response }
        }
        else {
            const response_json = await response.json();

            console.log("\n[API_UpdateClient]\nSomething went wrong with the response: ", response)
            console.log("\n[API_UpdateClient]\nResponse json: \n", response_json)
            return { isOk: false, response: response, error_message: check_status_code(response.status), response_json: response_json }
        }

    }
    catch (error) {
        // If we are here, means server might be offline
        console.log("\n[API_UpdateClient]\nError fetching data:\n ", error)
        return { isOk: false, error_message: server_off_message }
    }
}
