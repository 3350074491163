// React
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Styles
import classes from "./Pages_styles/DashboardNotFound.module.scss"

// Icons / SVG
import errorImage from "../../../static/404image.png"

// Context
import { useDashboardContext } from '../../../context/DashboardContext'

// (My) React elements
import { WrapperFlexRow } from '../../../components/Elements/Wrappers'
import { ButtonNeutralToDanger, ButtonPrimaryFill } from '../../../components/Elements/Buttons'


function DashboardNotFound({ additionalInformation }) {
    // IF we are here that means that we are logged in, hash is correct
    // but we are on wrong url or missing url 

    const { t } = useTranslation();

    const { dashboardHash, setCurrentPath } = useDashboardContext()
    const navigate = useNavigate();

    const location = useLocation()
    const locationSplit = location.pathname.split('/')
    const notFoundPage = locationSplit[locationSplit.length - 1]

    function handleGoToHome() {
        navigate(`/dashboard/${dashboardHash}/home`)
        setCurrentPath(`/dashboard/${dashboardHash}/home`)

    }

    return (
        <div className={classes.DashboardNotFound + " container"}>
            <img className={classes.ErrorImage} src={errorImage} alt="Error graphic" />
            <h2 className="SectionTitleText">{t("DashboardNotFound.page_not_found_title", "Oops!")}</h2>
            <h3 className='DefaultText'>{t("DashboardNotFound.page_not_found_subtitle", "Page not found!")}</h3>
            <p className='SmallText'>{t("DashboardNotFound.page_not_found_message", "We could not find any page with name")} <b>{notFoundPage}</b>.</p>


            {/* Some additional text that we can pass to notFoundPage  */}
            {additionalInformation &&
                <div className={classes.DashboardNotFoundAdditionalText}>
                    <h3 className='DefaultText'> {additionalInformation} </h3>
                </div>
            }

            <WrapperFlexRow style={{ alingItems: "center", justifyContent: "center" }}>
                <ButtonPrimaryFill text={t("Common.go_back_to_dashboard_home", "Go back to Dashboards")} onClick={handleGoToHome} />

                <ButtonNeutralToDanger text={t("Common.report_a_bug", "Report a bug")} />

            </WrapperFlexRow>
        </div>
    )
}

export default DashboardNotFound